import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import QuoteType from '../../../types/QuoteType';
import {calculationTabs} from '../../../config/constants';
import {getTotalEffect} from '../../../utils/getTotalEffect';
import {toCurrency} from '../../../utils/toCurrency';

import {
  calculationSectionState,
  calculationTabState,
  currentCalculationTab,
} from '../../../state/calculation/calculation';

import UIWarningIcon from '../../UICommon/UIHoverPopup';

type CalculationProgressBarProps = {
  quote: QuoteType,
  customer: any,
};

const CalculationProgressBar: FC<CalculationProgressBarProps> = ({
  quote,
  customer,
}) => {
  const {t} = useTranslation();

  const tabsState = useRecoilValue(calculationTabState);
  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const tabIndex = calculationTabs.find((x) => x.accessor === tabsState)?.id || 1;
  const sections = useRecoilValue(calculationSectionState);

  const totalEffect = getTotalEffect(sections);

  const isTabUpdated = (tab: string) => {
    switch (tab) {
      case 'sections': return quote.is_sections_updated;
      case 'material': return quote.is_materials_updated;
      case 'montage': return quote.is_installation_updated;
      case 'quote': return (quote.total_cost || 0) > 0;
      default: return true;
    }
  };

  const getTabHeader = (tab: string) => {
    if (isTabUpdated(tab)) {
      switch (tab) {
        case 'sections': return `${totalEffect.toFixed(2)} kW`;
        case 'contact': return `${customer.first_name} ${customer.last_name}`;
        case 'material': return toCurrency(quote.material_price, 0);
        // case 'montage': return toCurrency(quote.installation_cost, 0);
        case 'quote': return toCurrency(quote.total_cost, 0);
      }
    }
  };

  return (
    <div className='w-full bg-white rounded-xl grid grid-cols-7 gap-1 mb-6'>
      {calculationTabs.map((el, index: number) => (
        <div
          key={el.id}
          onClick={() => setCurrentTab(el.accessor)}
          className='flex flex-col items-center justify-start tab-item relative
           select-none cursor-pointer transition-all duration-100 ease-in hover:shadow-md'>
          <div
            className={`w-full ${tabIndex >= el.id ? 'bg-blue-primary' : 'bg-slate-300'} ` +
            `h-2 ${index === 0 && 'rounded-tl-xl' } mb-2 ` +
            `${index === calculationTabs.length - 1 && 'rounded-tr-xl' }`}></div>
          <div className={`text-center h-16 flex flex-col items-center w-full justify-between ` +
            `${tabIndex < el.id && 'text-slate-400'}`}>
            <div className='text-md font-semibold'>
              {t(`calculation.pages.${el.accessor}.name`)}
            </div>
            {getTabHeader(el.accessor) &&
             <div className='text-xs opacity-90 mb-2'>
               {getTabHeader(el.accessor)}
             </div>
            }
            {tabIndex > el.id && !isTabUpdated(el.accessor) &&
              <UIWarningIcon
                text={t('calculation.notUpdated')}
                isError={el.isRequired}
              />
            }
          </div>
        </div>
      ))}
    </div>
  );
};

export default CalculationProgressBar;
