import {useMutation} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/quoteInstallation';

import {quoteInstallationState} from '../../state/calculation/calculation';

const useCreateQuoteInstallation = () => {
  const setInstallation = useSetRecoilState(quoteInstallationState);
  return useMutation(async (data: any) => {
    const res = await actions.createQuoteInstallation(data);
    setInstallation(res.data);
    return res.data;
  }, {retry: false});
};

export default useCreateQuoteInstallation;
