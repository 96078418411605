import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue} from 'recoil';
import {montageInputs} from '../../../config/montageForm';
import {isFloatInput} from '../../../utils/isFloatInput';
import {getPositiveNumber} from '../../../utils/getPositiveNumber';

import useCalculateQuoteWorkCost from '../../../hooks/quotes/useCalculateQuoteWorkCost';
import useUpdateQuoteInstallation from '../../../hooks/installation/useUpdateQuoteInstallation';

import {
  quoteInstallationState,
  calculationQuoteState,
} from '../../../state/calculation/calculation';

import UICheckbox from '../../UICommon/UICheckbox';
import UIInput from '../../UICommon/UIInput';
import UILargeButton from '../../UICommon/UILargeButton';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';
import InstallationInputType from '../../../types/InstallationInputType';
import {ImLoop2} from 'react-icons/im';

type CalculationMontageFormProps = {
  fetchQuote: Function,
};

const CalculationMontageForm: FC<CalculationMontageFormProps> = ({
  fetchQuote,
}) => {
  const {t} = useTranslation();

  const currentQuote = useRecoilValue(calculationQuoteState);
  const [quoteInstallation, setQuoteInstallation] = useRecoilState(quoteInstallationState);

  const {
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    mutate: mutateUpdate,
  } = useUpdateQuoteInstallation();

  const {
    isSuccess: isMutateCalculateSuccess,
    isLoading: isWorkCostLoading,
    mutate: mutateCalculateQuote,
  } = useCalculateQuoteWorkCost();

  useEffect(() => {
    setQuoteInstallation({...quoteInstallation, quote: currentQuote.id});
  }, []);

  const onChange = (event: any, el: any) => {
    const {name, value} = event.target;
    if (el) {
      if (el.type === 'float' && isFloatInput(value)) {
        setQuoteInstallation({...quoteInstallation, [name]: value});
      } else if (el.type === 'number') {
        setQuoteInstallation({...quoteInstallation, [name]: getPositiveNumber(value)});
      }
    } else {
      const tmpFormValues: any = {...quoteInstallation};
      tmpFormValues[name] = !tmpFormValues[name];
      setQuoteInstallation(tmpFormValues);
    }
  };

  const calculate = () => {
    mutateUpdate(quoteInstallation);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      mutateCalculateQuote();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isMutateCalculateSuccess) {
      fetchQuote();
    }
  }, [isMutateCalculateSuccess]);

  const isSmallLoading = isUpdateLoading || isWorkCostLoading;

  return (
    <div className='w-full h-full flex flex-wrap justify-between'>
      {isSmallLoading && <UILoadingIndicatorSmall/>}
      <>
        <div className='grid gap-4 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6'>
          {montageInputs
              .filter((el: InstallationInputType) => el.type === 'number' || el.type === 'float')
              .map((el: InstallationInputType, idx: number) => {
                const name = el.name as keyof typeof quoteInstallation;
                return (
                  <div key={idx} className='w-fit'>
                    <UIInput
                      label={t(`calculation.pages.montage.form.${el.name}`)!}
                      name={el.name}
                      value={quoteInstallation[name]}
                      onChange={(e: any) => onChange(e, el)}
                      suffix={el.suffix}
                    />
                  </div>
                );
              })}
        </div>
        <div className='w-full flex flex-wrap my-4'>
          {montageInputs
              .filter((el: InstallationInputType) => el.type === 'checkbox')
              .map((el: InstallationInputType, idx: number) => {
                const key = el.name as keyof typeof quoteInstallation;
                return (
                  <div key={idx} className='mr-4'>
                    <UICheckbox
                      label={el.label}
                      name={el.name}
                      onChange={onChange}
                      checked={quoteInstallation[key] === true && true}
                    />
                  </div>
                );
              })}
        </div>
      </>
      <div className='w-full'
      >
        <UILargeButton
          label={t('calculation.pages.montage.calculateButton')}
          icon={<ImLoop2/>}
          onClick={calculate}
        />
      </div>
    </div>
  );
};

export default CalculationMontageForm;
