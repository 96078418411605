import _ from 'lodash';
import {atom, selector} from 'recoil';
import SortingType from '../../types/SortingType';
import {singleCustomer} from '../customers/customers';

export const quotesArray = atom({
  key: 'quotesArray',
  default: [],
});

export const customerQuotesSortState = atom<SortingType>({
  key: 'customerQuotesSortState',
  default: {
    field: '',
    type: '',
  },
});

export const customerQuotesSetSort = selector({
  key: 'customerQuotesSetSort',
  get: ({get}) => get(customerQuotesSortState),
  set: ({get, set, reset}, key: any) => {
    const sort: SortingType = get(customerQuotesSortState);
    if (sort.field === key) {
      if (sort.type === '') {
        set(customerQuotesSortState, {
          field: key,
          type: 'asc',
        });
      }

      if (sort.type === 'asc') {
        set(customerQuotesSortState, {
          field: key,
          type: 'desc',
        });
      }

      if (sort.type === 'desc') {
        reset(customerQuotesSortState);
      }
    } else {
      set(customerQuotesSortState, {
        field: key,
        type: 'asc',
      });
    }
  },
});

export const quotesNotTemplates = selector({
  key: 'quotesNotTemplates',
  get: ({get}) => {
    const quotes = get(quotesArray);
    return _.filter(quotes, ['is_template', false]);
  },
});

export const customerQuotes = selector({
  key: 'customerQuotes',
  get: ({get}) => {
    const quotes: any = get(quotesArray);
    const customer: any = get(singleCustomer);
    if (customer && customer.id !== null) {
      return _.filter(quotes, (el: any) => {
        return el.customer === customer.id;
      });
    }
  },
});

export const customerQuotesSorted = selector({
  key: 'customerQuotesSorted',
  get: ({get}) => {
    const quotes = get(customerQuotes);
    const sort: any = get(customerQuotesSortState);
    if (quotes == null) {
      return [];
    }

    if (!sort) {
      return quotes;
    }

    return _.orderBy(quotes, [sort.field], [sort.type]);
  },
});


export const quoteAttachments = atom({
  key: 'quoteAttachments',
  default: [],
});
