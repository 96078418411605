import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {toCurrency} from '../../utils/toCurrency';


type UITableCustomCostsProps = {
    costs: any;
}

export const UITableCustomCosts: FC<UITableCustomCostsProps> = ({
  costs,
}) => {
  const {t} = useTranslation();
  return (
    <div className="w-full mb-4 px-5">
      <div className='mx-4'>
        <table className='border-collapse w-full pl-2 table-fixed'>
          <thead>
            <tr className='font-bold text-sm text-right'>
              <td className='text-left'>
                {t('calculation.pages.quote.otherCosts.headers.description')}
              </td>
              <td>
                {t('calculation.pages.quote.otherCosts.headers.sum')}
              </td>
            </tr>
          </thead>
          <tbody>
            {costs?.map((cost: any, idx: number) => {
              return (
                <tr
                  key={idx}
                  className='border-b border-gray-100 text-right hover:bg-gray-100'
                >
                  <td className='text-blue-700 text-left'>{cost.description}</td>
                  <td>{toCurrency(cost.cost)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
