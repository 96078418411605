import {useRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/customers';
import {calculationQuoteState} from '../../state/calculation/calculation';

const useCreateCustomer = (data: any) => {
  const [quoteValues, setQuoteValues] = useRecoilState(calculationQuoteState);
  return useMutation(async () => {
    const tmpCustomerValues = {...data};

    /*

    if (
      !tmpCustomerValues.address.city &&
      !tmpCustomerValues.address.county &&
      !tmpCustomerValues.address.street &&
      !tmpCustomerValues.address.street_number &&
      !tmpCustomerValues.address.postal_code
    ) {
      delete tmpCustomerValues.address;
    }
    Object.keys(tmpCustomerValues).forEach((key: any) => {
      if (tmpCustomerValues[key] === '') {
        delete tmpCustomerValues[key];
      }
    });
     */

    const createdCustomer = await actions.addCustomer(tmpCustomerValues);
    const tmpQuoteValues = {...quoteValues};
    tmpQuoteValues.customer = createdCustomer.id;
    setQuoteValues(tmpQuoteValues);
    return createdCustomer;
  }, {retry: false});
};

export default useCreateCustomer;
