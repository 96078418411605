import {FC, useEffect} from 'react';
import {FaTimes} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import UISearch from '../../UICommon/UISearch';
import UIToggle from '../../UICommon/UIToggle';
import ProductsTable from './ProductsTable';
import {
  batteriesColumns,
  chargingBoxesColumns,
  genericProductsColumns,
  invertersColumns,
} from '../../../config/constants';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {currentProducts, productsSearchState, productsSetSort, productsSorted} from '../../../state/products/products';

type ProductModalType = {
  title: string,
  selectProduct: Function,
  onClose: Function,
  setRecommended: Function,
  isRecommended: boolean,
  data: any,
  type: string,
  isLoading: boolean,
};

const ProductModal: FC<ProductModalType> = ({
  title,
  selectProduct,
  onClose,
  isRecommended,
  setRecommended,
  data,
  type,
  isLoading,
}) => {
  const {t} = useTranslation();
  const hasRecommendations = ['battery', 'inverter'].includes(type);

  const displayData = useRecoilValue(productsSorted);
  const setDisplayData = useSetRecoilState(currentProducts);
  const [sorting, setSorting] = useRecoilState(productsSetSort);
  const [search, setSearch] = useRecoilState(productsSearchState);

  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  const getHeaders = () => {
    switch (type) {
      case 'inverter': return invertersColumns;
      case 'chargingbox': return chargingBoxesColumns;
      case 'genericproduct': return genericProductsColumns;
      case 'battery': return batteriesColumns;
    };
  };

  return (
    <div
      className='fixed inset-0 bg-gray-600 bg-opacity-50 flex z-20
       items-center justify-center h-full w-full'
    >
      <div
        className='bg-white w-full h-[70vh] rounded-2xl drop-shadow-lg p-5 z-30 m-20'
      >
        <div className='flex w-full justify-between px-10 py-4 items-center'
        >
          <div
            className='text-lg font-semibold'
          >
            {title}
          </div>
          <div>
            <UISearch
              value={search}
              setSearch={setSearch}
            />
          </div>
          <div>
            {hasRecommendations &&
                <UIToggle
                  title={t('calculation.pages.products.inverter.modal.recommended')}
                  isToggled={isRecommended}
                  setToggled={setRecommended}
                />}
          </div>
          <div>
            <FaTimes
              className='w-8 h-8 cursor-pointer hover:opacity-80'
              onClick={() => onClose()}
            />
          </div>
        </div>
        {<ProductsTable
          type={type}
          data={displayData}
          onSelectProduct={selectProduct}
          columns={getHeaders()}
          sorting={sorting}
          setSorting={setSorting}
          isLoading={isLoading}
        />}
      </div>
    </div>
  );
};

export default ProductModal;
