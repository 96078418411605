import _ from 'lodash';
import {atom, selector} from 'recoil';
import CurrentUserType from '../../types/CurrentUserType';
import SortingType from '../../types/SortingType';
import {currentUser} from '../user/currentUser';
import {customersArray} from './customers';

export const userCustomersSortState = atom<SortingType>({
  key: 'userCustomersSortState',
  default: {
    field: '',
    type: '',
  },
});

export const userCustomers = selector({
  key: 'userCustomers',
  get: ({get}) => {
    const customers = get(customersArray);
    const user = get<CurrentUserType>(currentUser);
    if (user != null && user.id) {
      return _.filter(customers, ['user.id', user.id]);
    }

    return [];
  },
});

export const userCustomersSetSort = selector({
  key: 'userCustomersSetSort',
  get: ({get}) => get(userCustomersSortState),
  set: ({get, set, reset}, key: any) => {
    const sort: SortingType = get(userCustomersSortState);
    if (sort.field === key) {
      if (sort.type === '') {
        set(userCustomersSortState, {
          field: key,
          type: 'asc',
        });
      }

      if (sort.type === 'asc') {
        set(userCustomersSortState, {
          field: key,
          type: 'desc',
        });
      }

      if (sort.type === 'desc') {
        reset(userCustomersSortState);
      }
    } else {
      set(userCustomersSortState, {
        field: key,
        type: 'asc',
      });
    }
  },
});

export const userCustomersSorted = selector({
  key: 'userCustomersSorted',
  get: ({get}) => {
    const customers = get(userCustomers);
    const sort: any = get(userCustomersSortState);
    if (customers == null) {
      return [];
    }

    if (!sort) {
      return customers;
    }

    return _.orderBy(customers, [sort.field], [sort.type]);
  },
});
