import React, {FormEvent, useState} from 'react';
import {Link} from 'react-router-dom';
import actions from '../api/resetPassword';
import {isValidEmail} from '../utils/isValidEmail';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UIImage from '../components/UICommon/UIImage';
import UISubmit from '../components/UICommon/UISubmit';

import img from '../assets/logo.svg';
import UIInput from '../components/UICommon/UIInput';

const ForgotPasswordPage: React.FC = () => {
  const initialValues = {success: false, error: false, errorMessage: ''};
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({email: ''});
  const [status, setStatus] = useState(initialValues);

  const onChange = (event: any) => {
    const {name, value} = event.target;
    setFormValues({...formValues, [name]: value});
  };

  const submit = (event: FormEvent) => {
    event.preventDefault();
    const state = initialValues;

    if (!formValues.email) {
      state.errorMessage = ('Ange din e-postadress');
    } else if (!isValidEmail(formValues.email)) {
      state.errorMessage = ('E-postadressen är ogiltig');
    }

    if (!state.errorMessage) {
      setLoading(true);
      actions.requestPasswordReset(formValues.email!).then(() => {
        state.success = true;
      }).catch((error) => {
        state.error = true;
      }).finally(() => {
        setLoading(false);
        setStatus(state);
      });
    } else {
      setStatus(state);
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-[445px] px-4">
          <div className={`relative flex flex-col min-w-0 break-words w-full ` +
            `mb-6 shadow-lg rounded-lg bg-slate-200 border-0`}>
            <div className="flex-auto px-4 lg:px-10 py-10">
              <UIImage url={img} style='w-auto mx-auto mb-5'/>
              {loading && <UILoadingIndicator/>}
              {status.error && <UIErrorIndicator/>}
              <div className='py-2 text-center'>
                <h1 className='text-xl font-semibold'>Återställ ditt lösenord.</h1>
              </div>
              { status.success ? (
                  <div className='text-green-700 text-center font-semibold'>
                    Kolla din e-post och klicka på aktiveringslänken för att ändra ditt lösenord.</div>
              ) : (
              <form className='flex flex-col h-full' onSubmit={(e) => submit(e)}>
                <h2 className='text-sm mb-4 text-center'>
                  Fyll i din e-postadress så skickar vi en återställningslänk.</h2>
                <div className='pb-8'>
                  <UIInput
                    type="email"
                    label="Email"
                    name='email'
                    onChange={onChange}
                    placeholder='E-postadress'
                    formErrorMessage={status.errorMessage}
                    variant='white'/>
                </div>
                <UISubmit
                  label="Skicka länk"
                  onClick={submit}
                />
              </form>
              )}
            </div>
          </div>
          <div className="flex flex-wrap mt-6 relative">
            <div className="w-1/2">
              <Link
                to="/"
                className="text-slate-200 hover:underline"
              >
                <small>Gå tillbaka</small>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
