import {FC} from 'react';

type UICheckboxProps = {
  label: string;
  name: string;
  onChange: Function;
  checked?: boolean;
  disabled?: boolean;
  tooltip?: string;
};

const UICheckbox: FC<UICheckboxProps> = ({
  label,
  name,
  onChange,
  checked,
  disabled,
  tooltip,
}) => {
  return (
    <div
      title={tooltip}
    >
      <label className={`inline-flex items-center ${disabled && 'hover:cursor-not-allowed'} cursor-pointer`}>
        <input
          disabled={disabled}
          name={name}
          type='checkbox'
          className='form-checkbox border-0 rounded text-slate-700 w-5 h-5 ease-linear transition-all duration-150'
          onChange={(e) => onChange(e)}
          checked={checked && checked}
        />
        <span className='ml-2 md:text-xs text-sm font-semibold text-slate-600 select-none'>
          {label}
        </span>
      </label>
    </div>
  );
};

export default UICheckbox;
