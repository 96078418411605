import {atom, selector} from 'recoil';
import SortingType from '../../types/SortingType';
import _ from 'lodash';

export const currentProducts= atom({
  key: 'currentProducts',
  default: [],
});

export const selectedProducts = atom({
  key: 'selectedProducts',
  default: [],
});

export const selectedProductsFilteredByType = atom({
  key: 'selectedProductsFilteredByType',
  default: [],
});

export const productsSortState = atom({
  key: 'productsSortState',
  default: {
    field: '',
    type: '',
  },
});

export const productsSearchState = atom({
  key: 'productsSearchState',
  default: '',
});

export const productsSetSort = selector({
  key: 'productsSetSort',
  get: ({get}) => get(productsSortState),
  set: ({get, set}, key: any) => {
    const sort: SortingType = get(productsSortState);
    if (sort.field === key) {
      set(productsSortState, {
        field: key,
        type: sort.type === 'desc' ? 'asc' : 'desc',
      });
    } else {
      set(productsSortState, {
        field: key,
        type: 'asc',
      });
    }
  },
});

export const productsSorted = selector({
  key: 'productsSorted',
  get: ({get}) => {
    const products = get(currentProducts);
    const sort: any = get(productsSortState);
    const searchTerm = get(productsSearchState);

    if (products == null) {
      return [];
    }

    if (!sort) {
      return products;
    }

    const filtered = products.filter((item: any) => {
      return JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase());
    });

    return _.orderBy(filtered, [sort.field], [sort.type]);
  },
});
