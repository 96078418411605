import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import UIButton from '../UICommon/UIButton';
import UIDeleteButton from '../UICommon/UIDeleteButton';

type ConfirmModalProps = {
  title: string,
  onCancel: Function,
  onConfirm: Function,
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  onCancel,
  onConfirm,
}) => {
  const {t} = useTranslation();

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-50 items-center justify-center h-full w-full'>
      <div className='flex bg-white w-fit rounded-2xl h-fit max-h-1/3
      p-10 items-center flex-col justify-between'>
        <div className='text-xl font-semibold p-6 mb-10'>
          {title}
        </div>
        <div className='flex w-full justify-center space-x-8'>
          <UIDeleteButton
            label={t('generic.buttons.cancel')!}
            onClick={onCancel}
          />
          <UIButton
            label={t('generic.buttons.confirm')}
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
