import {FC, useState} from 'react';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';

type UIDropdownSelectProps = {
  label: string;
  value?: string;
  children?: JSX.Element | JSX.Element[];
  readonly?: boolean;
  required?: boolean;
};

const UIDropdownSelect: FC<UIDropdownSelectProps> = ({
  label,
  value,
  children,
  readonly,
  required,
}) => {
  const [toggled, setToggled] = useState(false);
  const toggleDropdown = () => {
    if (!readonly) {
      setToggled(!toggled);
    }
  };


  return (
    <div className="relative w-full mb-3">
      <div className='flex items-center'>
        {required && <span className='text-red-500 mr-2'>*</span>}
        <label
          className='block text-[#696969] text-xs font-bold mb-2 uppercase'
          dangerouslySetInnerHTML={{__html: label}}
        />
      </div>
      <button
        className={
          `border border-[#F0F1F7] px-3 py-3 text-[#6B6B6B] ` +
          `bg-[#FCFDFE] rounded-lg text-sm ${readonly ? '' : 'focus:outline-none focus:ring'} w-full ` +
          `ease-linear transition-all duration-150 flex justify-between`
        }
        type='button'
        onClick={toggleDropdown}
      >
        <span className='text-sm capitalize' dangerouslySetInnerHTML={{__html: value ? value : label}} />
        {!readonly && <div>
          {toggled ? <FiChevronUp className='h-5 w-5 ml-2'/> :
            <FiChevronDown className='h-5 w-5 ml-2'/>
          }
        </div>}
      </button>
      <div className={
        `bg-white flex flex-col z-50 items-start top-18 ` +
        `rounded-md mt-1 absolute border border-[#E9ECEF] ` +
        `${!toggled && 'hidden'} left-0 right-0 bg-[#FCFDFE]`
      }
      onClick={toggleDropdown}
      >
        {children}
      </div>
    </div>
  );
};

export default UIDropdownSelect;
