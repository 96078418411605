import {FC} from 'react';

type CalculationStatisticsFormInputProps = {
  name: string;
  label: string;
  placeholder: string;
  value?: any;
  helper: string;
  onChange: Function;
};

const CalculationStatisticsFormInput: FC<CalculationStatisticsFormInputProps> = ({
  name,
  label,
  placeholder,
  value,
  helper,
  onChange,
}) => {
  return (
    <div className='flex w-full mb-4 items-center'>
      <label
        className='block text-sm font-bold text-[#1E1E1E] w-44'
        htmlFor={name}
      >
        {label}
      </label>
      <div className='flex w-32 items-center'>
        <input
          name={name}
          className='text-[#6B6B6B] border border-[#F0F1F7] rounded-l-lg bg-[#FCFDFE] placeholder-slate-300 text-sm
            px-3 py-3 focus:outline-none focus:ring ease-linear transition-all duration-150 w-full cost-input'
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
        />
        <div
          className='h-full px-2 py-3 w-12 bg-[#E8E9F0] flex items-center
           justify-center text-sm border border-[#F0F1F7] rounded-r-lg font-semibold'
        >
          {helper}
        </div>
      </div>
    </div>
  );
};

export default CalculationStatisticsFormInput;
