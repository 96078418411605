import {FC} from 'react';
import UIButton from './UIButton';

type UISmallCardProps = {
  title: string;
  content: string;
  buttonLabel: string;
  onClick: Function;
  icon: JSX.Element;
};

const UISmallCard: FC<UISmallCardProps> = ({
  title,
  content,
  buttonLabel,
  onClick,
  icon,
}) => {
  return (
    <div
      className='w-full mb-6 bg-slate-200 rounded-[10px] flex items-center py-4 px-4 h-24 justify-between'
    >
      <div className='flex'>
        <div className='flex justify-center items-center mr-5'>
          <div className='bg-white w-11 h-11 rounded-lg flex items-center justify-center'>
            {icon}
          </div>
        </div>
        <div>
          <h2 className='text-base font-semibold'>{title}</h2>
          <div className='text-xs lg:text-sm text-gray-500'>{content}</div>
        </div>
      </div>
      <div className="text-center">
        <UIButton
          label={buttonLabel}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default UISmallCard;
