import {FC} from 'react';
import {FiPlusCircle} from 'react-icons/fi';

type UIAddButtonProps = {
  label: string,
  onClick: Function,
};

const UIAddButton: FC<UIAddButtonProps> = ({
  label,
  onClick,
}) => {
  return <div>
    <div
      className='w-56 bg-white border shadow rounded-2xl bg-clip-border py-3 px-6
           flex justify-between items-center mb-2 cursor-pointer border-gray-200
            transition-all duration-75 ease-in hover:shadow-lg'
      onClick={() => onClick()}
    >
      <div className='text-sm opacity-40'>
        {label}
      </div>
      <FiPlusCircle
        className='bg-[#FFF5F5] w-6 h-6 rounded-full'
      />
    </div>
  </div>;
};


export default UIAddButton;
