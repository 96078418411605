import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Customers
 */

const URL = 'customers/';

const fetchCustomers = async () => {
  return client.get(URL);
};

const fetchSingleCustomer = async (id: string) => {
  return client.get(URL + id + '/');
};

const addCustomer = async (data: any) => {
  const res = await client.post(URL, data);
  return res.data;
};

const updateCustomer = async (data: any, id: number) => {
  const res = await client.put(URL + id + '/', data);
  return res.data;
};

const actions = {
  fetchCustomers,
  fetchSingleCustomer,
  addCustomer,
  updateCustomer,
};

export default actions;
