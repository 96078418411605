import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Mounts
 */

const URL = 'mounts/';

const fetchMounts = async () => {
  return client.get(URL);
};

const actions = {
  fetchMounts,
};

export default actions;
