import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {toCurrency} from '../../../utils/toCurrency';
import UICard from '../../UICommon/UICard';
import getSum from '../../../utils/getSum';

type CalculationOfferDeductionsProps = {
  deductions: any;
  possibleDeduction: any;
};

const CalculationOfferDeductions: FC<CalculationOfferDeductionsProps> = ({
  deductions,
  possibleDeduction,
}) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(false);
  const totalGreenDeduction = getSum(deductions.map((d: any) => d.cost));

  return (
    <div className="w-full">
      <UICard style='w-full mb-2'>
        <div className={`w-full flex justify-between items-center h-6 select-none p-7 ${toggled && 'mb-4'}`}
          onClick={() => setToggled(!toggled)}>
          <h2 className='font-semibold text-sm text-[#1E1E1E]'>
            {t('calculation.pages.quote.greenDeduction.title')}
          </h2>
          <div className='flex items-center'>
            <span className='mr-4 text-sm font-semibold'>
              {toCurrency(possibleDeduction, 0)}
            </span>
            {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> : <FiChevronUp className='h-6 w-6 ml-2'/>}
          </div>
        </div>
        <div className={`w-full p-4 ${!toggled && 'hidden'}`}>
          <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-semibold py-2 px-4'>
            <div className='w-1/2 px-2 font-bold'>
              {t('calculation.pages.quote.greenDeduction.description')}
            </div>
            <div className='w-1/2 px-2 font-bold text-right'>
              {t('calculation.pages.quote.greenDeduction.sum')}
            </div>
          </div>
          {deductions.map((el: any) => (
            <div
              key={el.id}
              className='flex justify-between text-sm py-1 border-b border-[#EBEBF3] px-4 hover:bg-gray-100'
            >
              <div className='w-1/4 px-2'>
                {el.description}
              </div>
              <div className='w-1/4 px-2 text-right flex items-center justify-end'>
                {toCurrency(el.cost)}
              </div>
            </div>
          ))}
        </div>
      </UICard>
      {toggled && (
        <div className='w-1/2 ml-auto'>
          <UICard style='max-h-[60%] relative h-full overflow-y-scroll mb-8 py-2'>
            <div className='flex justify-between text-sm font-semibold py-2 px-4 hover:bg-gray-100 border-b'>
              <div className='w-1/2 px-2'>
                {t('calculation.pages.quote.greenDeduction.sum')}
              </div>
              <div className='w-1/2 px-2 text-right'>
                {toCurrency(totalGreenDeduction, 0)}
              </div>
            </div>
            <div className='flex justify-between text-sm font-semibold py-2 px-4 hover:bg-gray-100'>
              <div className='w-1/2 px-2 font-bold'>
                {t('calculation.pages.quote.greenDeduction.possible')}
              </div>
              <div className='w-1/2 px-2 font-bold text-right'>
                {toCurrency(possibleDeduction, 0)}
              </div>
            </div>
          </UICard>
        </div>
      )}
    </div>
  );
};

export default CalculationOfferDeductions;
