import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {toCurrency} from '../../../utils/toCurrency';
import {useRecoilValue} from 'recoil';
import {calculationQuoteState} from '../../../state/calculation/calculation';

import UICard from '../../UICommon/UICard';
import CalculationOfferSummaryCard from './CalculationOfferSummaryCard';
import UISummaryTable from '../../UICommon/UISummaryTable';

type CalculationOfferCostsProps = {
  costs: any;
  price: number;
  tax: number;
  isPrivatePerson: boolean;
};

const CalculationOfferCosts: FC<CalculationOfferCostsProps> = ({
  costs,
  price,
  tax,
  isPrivatePerson,
}) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(false);
  const categories: any = {};
  const workCostRows: any = {};
  const quote = useRecoilValue(calculationQuoteState);
  const additions = quote.additions;


  costs.forEach((cost: any) => {
    if (cost.category in categories) {
      categories[cost.category] += cost.cost;
    } else {
      categories[cost.category] = cost.cost;
    }
  });

  Object.keys(categories).map((cat: any) => {
    const label = t(`calculation.pages.montage.table.categories.${cat}`);
    const cost = categories[cat];

    if (cat === 'mounting') {
      workCostRows[`${label} ${t('calculation.financial.incAddition')
          .replace('{addition}', additions.mounting)}`] =
       toCurrency(cost * (1 + 0.01 * additions.mounting), 0);
    } else if (cat === 'electrician') {
      workCostRows[`${label} ${t('calculation.financial.incAddition')
          .replace('{addition}', additions.electrician)}`] =
       toCurrency(cost * (1 + 0.01 * additions.electrician), 0);
    }
  });

  return (
    <div className="w-full space-y-2">
      <UICard style='w-full'>
        <div className={`w-full flex justify-between items-center h-6 select-none p-7
        ${toggled && 'mb-4'}`} onClick={() => setToggled(!toggled)}>
          <h2 className='font-semibold text-sm text-[#1E1E1E]'>
            {t('calculation.pages.quote.project.title')}
          </h2>
          <div className='flex items-center'>
            <span className='mr-4 text-sm font-semibold'>
              {`${t('calculation.financial.sumExTAX')} ${toCurrency(price, 0)}`}
            </span>
            {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> :
             <FiChevronUp className='h-6 w-6 ml-2'/>}
          </div>
        </div>

        <div className={`w-full px-4 pb-2 ${!toggled ? 'hidden' : ''}`}>
          <div
            className='flex flex-col space-y-2'
          >
            {Object.keys(categories)
                .sort((a, b) => categories[a] > categories[b] ? -1 : 1)
                .map((cat: any, idx: number) => (
                  <div
                    key={idx}
                  >
                    <div
                      className='flex justify-between font-semibold text-g px-4'
                    >
                      <div
                        className='capitalize'
                      >
                        {t(`calculation.pages.montage.table.categories.${cat}`)}
                      </div>
                    </div>
                    {costs?.filter((c: any) => c.category === cat)
                        .map((el: any) => (
                          <div
                            key={el.id}
                            className='flex justify-between text-sm py-1 border-b border-[#EBEBF3]
                             px-4 hover:bg-gray-100'
                          >
                            <div className='w-1/4 px-2'>
                              {el.description}
                            </div>
                            <div className='w-1/4 px-2 text-right flex items-center justify-end'>
                              {toCurrency(el.cost)}
                            </div>
                          </div>
                        ))}
                    <div className='text-right mt-2 mr-6 text-sm'>
                      <div className='font-bold'>
                        {`${t('calculation.pages.quote.project.sum')}
                     ${t(`calculation.pages.montage.table.categories.${cat}`)}`}
                      </div>
                      {toCurrency(categories[cat])}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </UICard>
      {toggled && Object.keys(workCostRows).length > 0 &&
        <UISummaryTable
          rows={workCostRows}
        />}
      {toggled && (
        <CalculationOfferSummaryCard
          price={price}
          tax={tax ? tax : 0}
          isPrivatePerson={isPrivatePerson}
        />
      )}
    </div>
  );
};

export default CalculationOfferCosts;
