import {useRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/quotes';
import {calculationQuoteState} from '../../state/calculation/calculation';
import QuoteType from '../../types/QuoteType';

const useUpdateQuote = (data: any) => {
  const [quote, setQuote] = useRecoilState<QuoteType>(calculationQuoteState);
  return useMutation(async () => {
    if (quote.id !== null) {
      const resData = await actions.updateQuote(data, quote.id);
      setQuote(resData);
      return resData;
    }
  }, {retry: false});
};

export default useUpdateQuote;
