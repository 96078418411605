import React, {FC} from 'react';

type UILoadingIndicatorSmallProps = {
  speed?: string
}

const UILoadingIndicatorSmall: FC<UILoadingIndicatorSmallProps> = ({
  speed = 'medium',
}) => {
  const spinSpeed =
    (speed === 'medium' ? 'mediumSpin' : speed === 'fast' ? 'fastSpin' : 'slowSpin');
  return (
    <div className="p-6 flex flex-col w-full h-full mx-auto my-auto items-center justify-center text-navy-primary
     absolute left-0 top-0 z-20 text-center ">
      <div
        className={`loader ${spinSpeed}`}
      >
      </div>
    </div>
  );
};

export default UILoadingIndicatorSmall;
