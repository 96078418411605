import client from './client';
import {createFormData} from '../utils/createFormData';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Quotes
 */

const URL = 'quotes/';
const URL_SECTIONS = 'sections/quote/';
const URL_CALCULATE_WORK_COST = 'calculate/work-cost/';
const URL_CALCULATE_MATERIAL_COST = 'calculate/material-cost/';
const URL_CALCULATE_MOUNTS = 'calculate/mount/';
const URL_CALCULATE_GREEN_TAX = 'calculate/green-tax/';
const URL_REMOVE_GREEN_TAX = 'calculate/clear-green-tax/';
const URL_CONSUMPTION = 'monthly_consumption/';
const URL_CONSUMPTION_QUOTE = 'monthly_consumption/quote/';
const URL_QUOTE_TEMPLATE = 'template/';
const URL_ATTACHMENTS = 'attachments/';

const fetchQuotes = async () => {
  return client.get(URL);
};

const fetchSingleQuote = async (id: number) => {
  return client.get(URL + id + '/');
};

const fetchQuoteSections = async (id: number) => {
  return client.get(URL_SECTIONS + id + '/');
};

const fetchQuoteMonthlyConsumption = async (id: number) => {
  return client.get(URL_CONSUMPTION_QUOTE + id + '/');
};

const addQuote = async (data: any) => {
  const res = await client.post(URL, data);
  return res.data;
};

const updateQuote = async (data: any, id: number) => {
  const res = await client.put(URL + id + '/', data);
  return res.data;
};

const calculateQuoteWorkCost = async (id: number) => {
  return client.post(URL_CALCULATE_WORK_COST + id + '/');
};

const calculateQuoteMaterialCost = async (id: number) => {
  return client.post(URL_CALCULATE_MATERIAL_COST + id + '/');
};

const calculateQuoteMounts = async (id: number) => {
  return client.post(URL_CALCULATE_MOUNTS + id + '/');
};

const addMonthlyConsumption = async (data: any) => {
  return client.post(URL_CONSUMPTION, data);
};

const updateMonthlyConsumption = async (data: any, id: number) => {
  return client.put(URL_CONSUMPTION + id + '/', data);
};

const calculateQuoteGreenTax = async (id: number) => {
  return client.post(URL_CALCULATE_GREEN_TAX + id + '/');
};

const removeQuoteGreenTax = async (id: number) => {
  return client.post(URL_REMOVE_GREEN_TAX + id + '/');
};

const createQuoteFromTemplate = async (customer: number, template: number, name: string) => {
  return client.post(`${URL_QUOTE_TEMPLATE}${template}/${customer}/`, {name: name});
};

const addAttachment = async (data: any) => {
  const res = await client.post(URL_ATTACHMENTS, createFormData(data));
  return res.data;
};

const fetchAttachments = async (quoteId: number) => {
  const res = await client.get(URL_ATTACHMENTS + quoteId);
  return res.data;
};

const deleteAttachment = async (id: number) => {
  const res = await client.delete(URL_ATTACHMENTS + id);
  return res.data;
};


const actions = {
  fetchQuotes,
  fetchSingleQuote,
  fetchQuoteSections,
  fetchQuoteMonthlyConsumption,
  addQuote,
  updateQuote,
  calculateQuoteWorkCost,
  calculateQuoteMaterialCost,
  calculateQuoteMounts,
  addMonthlyConsumption,
  updateMonthlyConsumption,
  calculateQuoteGreenTax,
  removeQuoteGreenTax,
  createQuoteFromTemplate,
  addAttachment,
  fetchAttachments,
  deleteAttachment,
};

export default actions;
