import {useRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/sections';
import {calculationQuoteState, calculationSectionState} from '../../state/calculation/calculation';
import SectionType from '../../types/SectionType';
import QuoteType from '../../types/QuoteType';

const useDeleteSection = (id: number) => {
  const [quote, setQuote] = useRecoilState<QuoteType>(calculationQuoteState);
  const [sections, setSections] = useRecoilState(calculationSectionState);
  return useMutation(async () => {
    await actions.deleteSection(id);

    const tmpQuote = {...quote};
    tmpQuote.is_materials_updated = false;
    tmpQuote.is_installation_updated = false;
    tmpQuote.is_statistics_updated = false;
    setQuote(tmpQuote);

    const tmpSections = [...sections];
    const idx = tmpSections.findIndex((el: SectionType) => el.id === id);
    if (idx !== -1) {
      tmpSections.splice(idx, 1);
    }
    setSections(tmpSections);
  }, {retry: false});
};

export default useDeleteSection;
