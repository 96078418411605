import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/inverters';
import {invertersArray} from '../../state/products/inverters';

const useFetchInverters = () => {
  const setInverters = useSetRecoilState(invertersArray);
  return useMutation('fetchInverters', async (quoteId: string) => {
    const res = await actions.fetchInverters(quoteId);
    setInverters(res.data);
    return res.data;
  });
};

export default useFetchInverters;
