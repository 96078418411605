import {useMutation} from 'react-query';
import actions from '../../api/quoteCosts';

const useUpdateQuoteCost = () => {
  return useMutation(async (data: any) => {
    if (data.id !== null) {
      const quoteInstallation = await actions.updateQuoteCost(data, data.id);
      return quoteInstallation;
    }
  }, {retry: false});
};

export default useUpdateQuoteCost;
