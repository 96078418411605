import {useMutation} from 'react-query';
import actions from '../../api/quotes';
import {useSetRecoilState} from 'recoil';
import {calculationQuoteState} from '../../state/calculation/calculation';

const useCreateQuoteFromTemplate = () => {
  const setQuote = useSetRecoilState(calculationQuoteState);
  return useMutation(
      async (data: any) => {
        const res = await actions.createQuoteFromTemplate(data.customer, data.template, data.name);
        setQuote(res.data);
        return res;
      },
      {retry: false},
  );
};

export default useCreateQuoteFromTemplate;
