import React, {FormEvent, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import useFetchToken from '../hooks/auth/useFetchToken';
import {authService} from '../api/authService';
import {isValidEmail} from '../utils/isValidEmail';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UIImage from '../components/UICommon/UIImage';
import LoginPageForm from '../components/LoginPage/LoginPageForm';

import img from '../assets/logo.svg';

const LoginPage: React.FC = () => {
  const [formValues, setFormValues] = useState({
    email: authService.getUserEmailFromLocalStorage(),
    password: authService.getUserPasswordFromLocalStorage(),
    rememberMe: false,
  });
  const [formErrorMessages, setFormErrorMessages] = useState({email: '', password: ''});
  const {error, isLoading, isError, mutate} = useFetchToken(
    formValues.email as string,
    formValues.password as string,
    formValues.rememberMe as boolean,
  );
  const is401 = error instanceof AxiosError && error?.response?.status === 401;

  useEffect(() => {
    const userEmail = authService.getUserEmailFromLocalStorage();
    const userPass = authService.getUserPasswordFromLocalStorage();
    if (userEmail && userPass) {
      mutate();
    }
  }, []);

  const onKeyPress = (event: any) => {
    const key = event.key.toLowerCase();

    if (key === 'enter') {
      submit();
    }
  };

  const onChange = (event: any) => {
    const {name, value} = event.target;
    if (name === 'rememberMe') {
      setFormValues({...formValues, [name]: event.target.checked});
    } else {
      setFormValues({...formValues, [name]: value});
    }
  };

  const validate = () => {
    const errors = {email: '', password: ''};
    if (!formValues.email) {
      errors.email = 'Ange din e-postadress';
    } else if (!isValidEmail(formValues.email)) {
      errors.email = 'E-postadressen är ogiltig';
    }
    if (!formValues.password) {
      errors.password = 'Ange ditt lösenord';
    }
    setFormErrorMessages(errors);
    return errors.email.length == 0 && errors.password.length == 0;
  };

  const submit = (event?: FormEvent) => {
    event && event.preventDefault();
    if (validate()) {
      mutate();
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className='relative flex flex-col min-w-0 break-words w-1/4
           mb-4 shadow-lg rounded-lg bg-slate-200 border-0 w-full'>
          <div className="flex-auto p-5">
            <UIImage url={img} style='w-auto mx-auto mb-5'/>
            {isError && <UIErrorIndicator
              message={is401 ?
                    'Din e-postadress eller lösenord är fel kontrollera uppgifterna.' :
                    'Ett oväntat fel inträffade.'
              } />
            }
            {isLoading && <UILoadingIndicator/>}
            <LoginPageForm
              formErrorMessages={formErrorMessages}
              onChange={onChange}
              onKeyPress={onKeyPress}
              submit={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
