import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FaBatteryFull,
  FaChargingStation,
  FaSolarPanel,
  FaWaveSquare,
} from 'react-icons/fa';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import getQuoteProducts from '../../../utils/getQuoteProducts';

import useFetchBatteries from '../../../hooks/products/useFetchBatteries';
import useFetchChargingBoxes from '../../../hooks/products/useFetchChargingBoxes';
import useFetchGenericProducts from '../../../hooks/products/useFetchGenericProducts';
import useFetchInverters from '../../../hooks/products/useFetchInverters';
import useFetchMounts from '../../../hooks/products/useFetchMounts';
import useMutateCreateQuoteProduct from '../../../hooks/quoteProducts/useMutateCreateQuoteProduct';
import useMutateUpdateQuoteProduct from '../../../hooks/quoteProducts/useMutateUpdateQuoteProduct';
import useMutateDeleteQuoteProduct from '../../../hooks/quoteProducts/useMutateDeleteQuoteProduct';
import useMutateFetchQuote from '../../../hooks/quotes/useMutateFetchQuote';

import {calculationQuoteState, currentCalculationTab} from '../../../state/calculation/calculation';
import {selectedProducts} from '../../../state/products/products';
import {productTypes} from '../../../config/constants';
import {invertersArray} from '../../../state/products/inverters';
import {batteriesArray} from '../../../state/products/batteries';
import {chargingBoxesArray} from '../../../state/products/chargingBoxes';
import {mountsArray} from '../../../state/products/mounts';
import {genericProductsArray} from '../../../state/products/genericProducts';

import UICard from '../../UICommon/UICard';
import UIErrorIndicator from '../../UICommon/UIErrorIndicator';
import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import CalculationProductBox from './CalculationProductBox';
import CalculationProductsTable from './CalculationProductsTable';
import UIPaginationButtons from '../../UICommon/UIPaginationButtons';
import ProductModal from './ProductModal';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';

const CalculationProductsTab: FC = () => {
  const {t} = useTranslation();
  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const [productsSelected, setProductsSelected] = useRecoilState(selectedProducts) as any;
  const quote = useRecoilValue(calculationQuoteState);
  const [productListOpen, setProductListOpen] = useState('');


  const inverters = useRecoilValue(invertersArray);
  const batteries = useRecoilValue(batteriesArray);
  const chargingBoxes = useRecoilValue(chargingBoxesArray);
  const mounts = useRecoilValue(mountsArray);
  const genericProducts = useRecoilValue(genericProductsArray);

  const [isRecommended, setRecommended] = useState(false);

  const {
    isLoading: isFetchQuoteLoading,
    isError: isFetchQuoteError,
    isSuccess: isFetchQuoteSuccess,
    mutate: mutateFetchQuote,
  } = useMutateFetchQuote();
  const {
    isLoading: isInvertersLoading,
    isError: isInvertersError,
    mutate: mutateFetchInverters,
  } = useFetchInverters();

  const {
    isLoading: isBatteriesLoading,
    isError: isBatteriesError,
    mutate: mutateFetchBatteries,
  } = useFetchBatteries();

  const {
    isLoading: isMountsLoading,
    isError: isMountsError,
    mutate: mutateFetchMounts,
  } = useFetchMounts();

  const {
    isLoading: isChargingBoxesLoading,
    isError: isChargingBoxesError,
    mutate: mutateFetchChargingBoxes,
  } = useFetchChargingBoxes();

  const {
    isLoading: isGenericProductsLoading,
    isError: isGenericProductsError,
    mutate: mutateFetchGenericProducts,
  } = useFetchGenericProducts();

  const {
    isLoading: isCreateProductLoading,
    isError: isCreatProductError,
    mutate: mutateCreateProduct,
    isSuccess: isCreateProductSuccess,
  } = useMutateCreateQuoteProduct();

  const {
    isLoading: isUpdateProductLoading,
    isError: isUpdateProductError,
    mutate: mutateUpdateProduct,
    isSuccess: isUpdateProductSuccess,
  } = useMutateUpdateQuoteProduct();

  const {
    isLoading: isDeleteProductLoading,
    isError: isDeleteProductError,
    mutate: mutateDeleteProduct,
    isSuccess: isDeleteProductSuccess,
  } = useMutateDeleteQuoteProduct();


  useEffect(() => {
    if (quote.id) {
      mutateFetchQuote(quote.id.toString());
    }
  }, []);

  useEffect(() => {
    if (isFetchQuoteSuccess) {
      setProductsSelected(getQuoteProducts(quote));
    }
  }, [isFetchQuoteSuccess]);

  useEffect(() => {
    if (quote.id) {
      mutateFetchInverters(quote.id.toString());
      mutateFetchBatteries(quote.id.toString());
      mutateFetchMounts();
      mutateFetchChargingBoxes();
      mutateFetchGenericProducts();
    }
  }, []);

  useEffect(() => {
    if (quote.id) {
      mutateFetchQuote(quote.id.toString());
    }
  }, [isUpdateProductSuccess, isCreateProductSuccess, isDeleteProductSuccess]);

  const onAlterProduct = (action: string, product: any) => {
    if (action === 'create') {
      mutateCreateProduct(product);
    } else if (action === 'update') {
      mutateUpdateProduct(product);
    } else if (action === 'delete') {
      mutateDeleteProduct(product.id);
    }
  };

  const getProductIcon = (type: string) => {
    switch (type) {
      case 'inverter': return <FaWaveSquare className='w-5 h-5 text-white' />;
      case 'battery': return <FaBatteryFull className='w-5 h-5 text-white' />;
      case 'chargingbox': return <FaChargingStation className='w-5 h-5 text-white' />;
      case 'genericproduct': return <FaSolarPanel className='w-5 h-5 text-white' />;
      default: return <>{type}</>;
    }
  };

  const getProductData = (type: string) => {
    switch (type) {
      case 'inverter':
        return isRecommended ? inverters.filter((inv: any) => inv.is_recommended) : inverters;
      case 'battery':
        return isRecommended ? batteries.filter((bat: any) => bat.is_recommended) : batteries;
      case 'chargingbox': return chargingBoxes;
      case 'genericproduct': return genericProducts;
      case 'mount': return mounts;
    }
  };

  const onSelectProduct = (product: any) => {
    const existingProduct = productsSelected
        .find((p: any) => p.object_id === product.object_id && p.product_type == product.product_type);
    if (existingProduct) {
      onAlterProduct('delete', existingProduct);
    } else {
      onAlterProduct('create', product);
    }
  };

  const goToNextPage = () => {
    setCurrentTab('material');
  };

  const goToPreviousPage = () => {
    setCurrentTab('sections');
  };

  const isSmallLoading = (
    isFetchQuoteLoading ||
    isCreateProductLoading ||
    isUpdateProductLoading ||
    isCreateProductLoading ||
    isDeleteProductLoading
  );

  const isLoading = (
    isInvertersLoading ||
    isBatteriesLoading ||
    isMountsLoading ||
    isChargingBoxesLoading ||
    isGenericProductsLoading
  );
  const isError = (
    isFetchQuoteError ||
    isCreatProductError ||
    isUpdateProductError ||
    isDeleteProductError ||
    isInvertersError ||
    isBatteriesError ||
    isMountsError ||
    isChargingBoxesError ||
    isGenericProductsError
  );

  if (isLoading) {
    return <UILoadingIndicator/>;
  }

  if (isError) {
    return <UIErrorIndicator/>;
  }

  return (
    <div className='w-full h-full'>
      <h2 className='font-bold text-lg mb-4'>
        {t('calculation.pages.products.title')}
      </h2>
      <UICard style='max-h-full relative h-[95%] overflow-y-scroll'>
        {isSmallLoading ? <UILoadingIndicatorSmall/> : <></>}
        <div className='w-full flex flex-wrap justify-between p-8'>
          {productTypes.map((type, idx) => {
            return (
              <>
                <CalculationProductBox
                  key={idx}
                  label={t(`calculation.pages.products.${type}.title`)}
                  accessor={type}
                  handleModal={() => setProductListOpen(type)}
                  icon={getProductIcon(type)}
                />
                {productListOpen === type &&
                <ProductModal
                  title={t(`calculation.pages.products.${type}.title`)}
                  onClose={() => setProductListOpen('')}
                  data={getProductData(type)}
                  isRecommended={isRecommended}
                  setRecommended={setRecommended}
                  type={type}
                  selectProduct={onSelectProduct}
                  isLoading={isSmallLoading}
                />}
              </>
            );
          })}
        </div>
        {productsSelected?.length > 0 ? (
          <CalculationProductsTable
            label={t('calculation.pages.products.table.title')}
            data={productsSelected}
            headers={['name', 'cost', 'type', 'amount']}
            onAlterProduct={onAlterProduct}
          />
        ) : (
          <div className='h-full'></div>
        )}
        <UIPaginationButtons
          nextPage={goToNextPage}
          prevPage={goToPreviousPage}
        />
      </UICard>
    </div>
  );
};

export default CalculationProductsTab;
