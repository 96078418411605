import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {toCurrency} from '../../../utils/toCurrency';
import {translateProduct} from '../../../utils/translateProduct';

type CalculationProductsTableProps = {
  label: string;
  data: any;
  headers: any;
  onAlterProduct: Function;
};

const CalculationProductsTable: FC<CalculationProductsTableProps> = ({
  label,
  data,
  headers,
  onAlterProduct,
}) => {
  const {t} = useTranslation();

  const updateProduct = (product: any, newQuantity: number) => {
    if (newQuantity === 0) {
      onAlterProduct('delete', product);
    } else {
      onAlterProduct('update', {...product, quantity: newQuantity});
    }
  };

  return (
    <div className="w-full mb-6 px-8 h-full">
      <h2 className='text-sm font-semibold mb-1'>{label}:</h2>
      <div className="w-full flex flex-col justify-center">
        <div className='flex justify-between odd:bg-slate-00 text-xs font-semibold py-2 bg-slate-200 rounded-t-lg'>
          {headers && headers.map((header: any, index: number) => (
            <div
              key={index}
              className={`w-1/5 px-2 font-bold ${index !== 0 && 'text-center'}`}
            >
              {t(`calculation.pages.products.table.headers.${header}`)}
            </div>
          ))}
        </div>
        {data.length > 0 && data.map((product: any) => (
          <div key={product.id} className='flex justify-between odd:bg-slate-100 text-sm py-1 last:rounded-b-lg'>
            <div className='w-1/5 px-2 whitespace-nowrap'>
              {product.name}
            </div>
            <div className='w-1/5 px-2 text-center'>
              {toCurrency(product.content_object.price.cost)}
            </div>
            <div className='w-1/5 px-2 text-center capitalize'>
              {translateProduct(product.product_type)}
            </div>
            <div className='w-1/5 px-2 text-center'>
              <div className="flex w-2/3 h-full mx-auto items-center justify-center border border-[#DFE0EB] rounded-lg">
                <div
                  className='cursor-pointer px-1'
                  onClick={() => updateProduct(product, product.quantity - 1)}
                >
                  <FaMinus/>
                </div>
                <div
                  className='cursor-pointer mx-1 border-l border-r border-[#DFE0EB] px-2 font-semibold'
                >
                  {product.quantity}
                </div>
                <div
                  className='cursor-pointer px-1'
                  onClick={() => updateProduct(product, product.quantity + 1)}
                >
                  <FaPlus/>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalculationProductsTable;
