import {useMutation} from 'react-query';
import actions from '../../api/batteries';
import {useSetRecoilState} from 'recoil';
import {batteriesArray} from '../../state/products/batteries';

const useFetchBatteries = () => {
  const setBatteries = useSetRecoilState(batteriesArray);
  return useMutation('fetchBatteries', async (quoteId: string) => {
    const res = await actions.fetchBatteries(quoteId);
    setBatteries(res.data);
    return res.data;
  });
};

export default useFetchBatteries;
