import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Inverters
 */

const URL = 'charging_boxes/';

const fetchChargingBoxes = async () => {
  return client.get(URL);
};

const actions = {
  fetchChargingBoxes,
};

export default actions;
