import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {useRecoilValue} from 'recoil';
import {calculationQuoteState} from '../../../state/calculation/calculation';
import {ReactSVG} from 'react-svg';
import UICard from '../../UICommon/UICard';
import {toCurrency} from '../../../utils/toCurrency';

type CalculationStatisticsInstallationGraphProps = {
  url: string;
  installmentData: any;
};

const CalculationStatisticsInstallationGraph: FC<CalculationStatisticsInstallationGraphProps> = ({
  url,
  installmentData,
}) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(false);
  const quote = useRecoilValue(calculationQuoteState);

  return (
    <UICard style='w-full h-full mb-6'>
      <div className="w-full flex justify-between items-center select-none p-4" onClick={() => setToggled(!toggled)}>
        <h2 className={`font-semibold text-sm text-[#1E1E1E] ${toggled && 'mb-4'}`}>
          {t('calculation.pages.statistics.graphs.installment.title')}
        </h2>
        {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> : <FiChevronUp className='h-6 w-6 ml-2'/>}
      </div>
      <>
        {toggled && <div className=' p-4 flex-col px-20'>
          <div className='flex w-full justify-center'>
            {url && <ReactSVG src={url} useRequestCache={false}/>}
          </div>
          <table className="border-collapse w-full px-10 text-right">
            <thead>
              <tr>
                <th className="bg-lightgray text-left">
                  {t('calculation.pages.statistics.graphs.installment.headers.year')}
                </th>
                <th>
                  {t('calculation.pages.statistics.graphs.installment.headers.profitSoldProduction')}
                </th>
                <th>
                  {t('calculation.pages.statistics.graphs.installment.headers.savedOwnConsumption')}
                </th>
                {quote.use_support_service && <th>
                  {t('calculation.pages.statistics.graphs.installment.headers.supportService')}
                </th>}
                <th>
                  {t('calculation.pages.statistics.graphs.installment.headers.sum')}
                </th>
                <th>
                  {t('calculation.pages.statistics.graphs.installment.headers.accumulatedReturn')}
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {
                  installmentData.installment.map((data: string, index: number) => {
                    return (
                      <tr key={index} className='border-b border-gray-200 hover:bg-gray-100'>
                        <td className="font-bold text-left">{index}</td>
                        <td>{toCurrency(installmentData.profit_sold_production[index])}</td>
                        <td>{toCurrency(installmentData.saved_own_consumption[index])}</td>
                        {quote.use_support_service &&
                         <td>{toCurrency(installmentData.support_service[index])}</td>}
                        <td>{toCurrency(
                            installmentData.profit_sold_production[index] +
                            installmentData.saved_own_consumption[index] +
                           installmentData.support_service[index])}
                        </td>
                        <td>{toCurrency(installmentData.installment[index])}</td>
                      </tr>
                    );
                  })
                }
              </>
            </tbody>
          </table>
        </div>}
      </>
    </UICard>
  );
};

export default CalculationStatisticsInstallationGraph;
