import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/quotes';
import {calculationSectionState} from '../../state/calculation/calculation';

const useFetchQuoteSections = () => {
  const setSections = useSetRecoilState(calculationSectionState);
  return useMutation(async (quoteId: number) => {
    const res = await actions.fetchQuoteSections(quoteId);
    setSections(res.data);
    return res.data;
  });
};

export default useFetchQuoteSections;
