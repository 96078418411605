import {useRecoilState} from 'recoil';
import {useMutation} from 'react-query';

import actions from '../../api/customers';

import {singleCustomer} from '../../state/customers/customers';
import CustomerType from '../../types/CustomerType';

const useUpdateCustomer = () => {
  const [customer, setCustomer] = useRecoilState(singleCustomer);
  return useMutation(async (data: CustomerType) => {
    const tmpCustomerValues = {...data};

    /*
      remove adress obj if not used
    */
    if (tmpCustomerValues.address == null) {
      delete tmpCustomerValues.address;
    }

    const updatedCustomer = await actions.updateCustomer(tmpCustomerValues, customer.id);
    setCustomer(updatedCustomer);
  }, {
    retry: false,
  });
};

export default useUpdateCustomer;
