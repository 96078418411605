import React, {FC} from 'react';
import {MdOutlineHideImage} from 'react-icons/md';

type UINoImageProps = {
    style?: string;
}

const UINoImage: FC<UINoImageProps> = ({style}) => {
  return (
    <div className={`${style} flex justify-center flex-col items-center h-full text-gray-menu bg-gray-200`}>
      <MdOutlineHideImage className='w-8 h-8'/>
      Bild saknas
    </div>
  );
};

export default UINoImage;
