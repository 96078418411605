import CustomerType from '../types/CustomerType';
import QuoteType from '../types/QuoteType';

const isEligableForSupportService = (quote: QuoteType, customer: CustomerType) => {
  if (customer.type !== 'private person') {
    return false;
  }

  const batteries = quote.products?.filter((p: any) => {
    return p.product_type === 'battery';
  });

  return batteries ? batteries.length > 0 : 0;
};

export default isEligableForSupportService;

