import {FC} from 'react';

type UIDropdownSelectItemProps = {
  label: string | number;
  value: string | number;
  onClick: Function;
};

const UIDropdownSelectItem: FC<UIDropdownSelectItemProps> = ({
  label,
  value,
  onClick,
}) => {
  return (
    <div
      className={`w-full py-2 font-semibold cursor-pointer bg-[#FCFDFE] border-b ` +
      `border-[#E9ECEF] last:border-none hover:bg-slate-100 px-4 capitalize`}
      onClick={() => onClick(value)}
    >
      {label}
    </div>
  );
};

export default UIDropdownSelectItem;
