import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {currentUser} from '../../../state/user/currentUser';
import {toCurrency} from '../../../utils/toCurrency';
import UICard from '../../UICommon/UICard';

type CalculationMaterialSummaryCardProps = {
  cost: number;
  addition: number;
  price: number;
};

const CalculationMaterialSummaryCard: FC<CalculationMaterialSummaryCardProps> = ({
  cost,
  addition,
  price,
}) => {
  const {t} = useTranslation();
  const user = useRecoilValue(currentUser);
  return (
    <div className='w-1/2 ml-auto'>
      <UICard style='max-h-[60%] relative h-full overflow-y-scroll mb-8 py-2'>
        <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-semibold py-2 px-4'>
          <div className='w-1/2 px-2'>
            {t('calculation.financial.sumRetailPrice')}
          </div>
          <div className='w-1/2 px-2 text-right'>{toCurrency(cost)}</div>
        </div>
        <div className='flex justify-between text-sm py-1 border-b border-[#EBEBF3] px-4'>
          <div className='w-1/2 px-2'>
            {t('calculation.financial.materialAddition')
                .replace('{class}', user.price_role.slice(-1))}
          </div>
          <div className='w-1/2 px-2 text-right'>
            {toCurrency(addition)}
          </div>
        </div>
        <div className='flex justify-between text-sm py-1 px-4'>
          <div className='w-1/2 px-2 font-bold'>
            {t('calculation.financial.sumExTAX')}
          </div>
          <div className='w-1/2 px-2 text-right font-bold'>
            {toCurrency(price)}
          </div>
        </div>
      </UICard>
    </div>
  );
};

export default CalculationMaterialSummaryCard;
