import {useMutation} from 'react-query';
import actions from '../../api/quoteCosts';

const useAddQuoteCost = () => {
  return useMutation(async (data: any) => {
    const res = await actions.addQuoteCost(data);
    return res.data;
  }, {retry: false});
};

export default useAddQuoteCost;
