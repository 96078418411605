import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/colors';
import {colorsArray} from '../../state/products/colors';

const useFetchColors = () => {
  const setColors = useSetRecoilState(colorsArray);
  return useQuery('fetchColors', async () => {
    const colors = await actions.fetchColors();
    setColors(colors?.data);
    return colors;
  }, {refetchOnWindowFocus: false});
};

export default useFetchColors;
