import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/customers';
import {customersArray} from '../../state/customers/customers';

const useUpdateCustomerStatus = () => {
  const setCustomers = useSetRecoilState(customersArray);
  return useMutation(async (data: any) => {
    actions.updateCustomer({'status': data.status}, data.customerId).then(() => {
      actions.fetchCustomers().then((res: any) => {
        setCustomers(res?.data);
        return res?.data;
      });
    });
  });
};

export default useUpdateCustomerStatus;
