export const statisticsQuoteInputs = [
  {
    id: 1,
    label: 'Kostnad köpt kWh:',
    name: 'buy_price_kwh',
    placeholder: '3,5',
    helper: 'kr',
  },
  {
    id: 2,
    label: 'Intäkt såld kWh:',
    name: 'sell_price_kwh',
    placeholder: '3,5',
    helper: 'kr',
  },
  {
    id: 3,
    label: 'Ökning av elpris per år:',
    name: 'yearly_price_increase_kwh',
    placeholder: '5',
    helper: '%',
  },
  {
    id: 4,
    label: 'Årlig förbrukning:',
    name: 'yearly_consumption_kw',
    placeholder: '8391',
    helper: 'kW',
  },
];

export const monthlyConsumptionInputs = [
  {
    id: 1,
    label: 'Januari:',
    name: 'january',
    placeholder: '1778',
    helper: 'kWh',
  },
  {
    id: 2,
    label: 'Februari:',
    name: 'february',
    placeholder: '1650',
    helper: 'kWh',
  },
  {
    id: 3,
    label: 'Mars:',
    name: 'march',
    placeholder: '1459',
    helper: 'kWh',
  },
  {
    id: 4,
    label: 'April:',
    name: 'april',
    placeholder: '1268',
    helper: 'kWh',
  },
  {
    id: 5,
    label: 'Maj:',
    name: 'may',
    placeholder: '951',
    helper: 'kWh',
  },
  {
    id: 6,
    label: 'Juni:',
    name: 'june',
    placeholder: '761',
    helper: 'kWh',
  },
  {
    id: 7,
    label: 'Juli:',
    name: 'july',
    placeholder: '697',
    helper: 'kWh',
  },
  {
    id: 8,
    label: 'Augusti:',
    name: 'august',
    placeholder: '697',
    helper: 'kWh',
  },
  {
    id: 9,
    label: 'September:',
    name: 'september',
    placeholder: '888',
    helper: 'kWh',
  },
  {
    id: 10,
    label: 'Oktober:',
    name: 'october',
    placeholder: '1142',
    helper: 'kWh',
  },
  {
    id: 11,
    label: 'November:',
    name: 'november',
    placeholder: '1396',
    helper: 'kWh',
  },
  {
    id: 12,
    label: 'December:',
    name: 'december',
    placeholder: '1649',
    helper: 'kWh',
  },
];
