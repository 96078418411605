import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {toCurrency} from '../../../utils/toCurrency';
import UICard from '../../UICommon/UICard';

type CalculationOfferSummaryCardProps = {
  price: number;
  isPrivatePerson: boolean;
  tax?: number;
};

const CalculationOfferSummaryCard: FC<CalculationOfferSummaryCardProps> = ({
  price,
  isPrivatePerson,
  tax = 0,
}) => {
  const {t} = useTranslation();
  return (
    <div className='w-1/2 ml-auto'>
      <UICard style='max-h-[60%] relative h-full overflow-y-scroll mb-8 py-2'>
        <div className='flex justify-between border-b border-[#EBEBF3] text-sm
         font-semibold py-2 px-4 hover:bg-gray-100'>
          <div className='w-1/2 px-2'>
            {t('calculation.financial.sumExTAX')}
          </div>
          <div className='w-1/2 px-2 text-right'>
            {toCurrency(price, 0)}
          </div>
        </div>
        <>
          {isPrivatePerson && (
            <>
              <div className='flex justify-between text-sm py-1 border-b border-[#EBEBF3] px-4 hover:bg-gray-100'>
                <div className='w-1/2 px-2'>
                  {t('calculation.financial.TAX')}
                </div>
                <div className='w-1/2 px-2 text-right'>
                  {toCurrency(tax, 0)}
                </div>
              </div>
              <div className='flex justify-between text-sm py-1 px-4 hover:bg-gray-100'>
                <div className='w-1/2 px-2 font-bold'>
                  {t('calculation.financial.sumIncTAX')}
                </div>
                <div className='w-1/2 px-2 font-bold text-right'>
                  {toCurrency(price + tax, 0)}
                </div>
              </div>
            </>
          )}
        </>
      </UICard>
    </div>
  );
};

export default CalculationOfferSummaryCard;
