export function createFormData(formValues: any) {
  const objectKeys = Object.keys(formValues);
  const formData = new FormData();
  objectKeys.forEach((key) => {
    if (key === 'file' && typeof formValues[key] !== 'string') {
      formData.append(key, formValues[key], formValues[key].name);
      return;
    }
    formData.append(key, formValues[key]);
  });
  return formData;
}
