import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';

type UITablePagesProps = {
  value: number;
  children: any;
};

const UITablePages: FC<UITablePagesProps> = ({
  value,
  children,
}) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(false);
  }, [value]);

  return (
    <div className="relative flex items-center">
      <button
        className={
          `bg-white text-sm text-center items-center justify-between ` +
            `font-bold p-3 rounded hover:shadow-lg outline-none ` +
            `focus:outline-none ease-linear transition-all duration-150 flex ` +
            `border border-[#E9ECEF] w-20`
        }
        type='button'
        onClick={() => setToggled(!toggled)}
      >
        {value}
        {!toggled ? (
          <FiChevronDown className='h-6 w-6 ml-2'/>
        ) : (
          <FiChevronUp className='h-6 w-6 ml-2'/>
        )}
      </button>
      <div className='text-sm ml-2'>{t('generic.tables.pages.amountPerPage')}</div>
      <div className={
        `bg-white flex flex-col z-50 items-start top-12 ` +
        `rounded-md mt-1 absolute border border-[#E9ECEF] ` +
        `${!toggled && 'hidden'} left-0 w-20 text-center`
      }>
        {children}
      </div>
    </div>
  );
};

export default UITablePages;
