import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Batteries
 */

const URL = 'batteries/';

const fetchBatteries = async (quoteId: string) => {
  return client.get(`${URL}${quoteId}/`);
};

const actions = {
  fetchBatteries,
};

export default actions;
