import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/customers';
import {customersArray} from '../../state/customers/customers';

const useFetchCustomers = () => {
  const setCustomers = useSetRecoilState(customersArray);
  return useQuery('fetchNews', async () => {
    const customers = await actions.fetchCustomers();
    setCustomers(customers?.data);
    return customers;
  });
};

export default useFetchCustomers;
