import {FC, useEffect} from 'react';
import {FaTimes} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

import {useNavigate} from 'react-router';
import {useRecoilState, useRecoilValue} from 'recoil';
import {format} from 'date-fns';
import {sv} from 'date-fns/locale';

import {customerStatusSelect} from '../../config/customerForm';

import useUpdateCustomerStatus from '../../hooks/customers/useUpdateCustomerStatus';
import useResetCalculation from '../../hooks/quotes/useResetCalculation';
import useFetchSingleCustomer from '../../hooks/customers/useFetchSingleCustomer';


import {customersPaginated, customersSetSort} from '../../state/customers/customers';
import {customersPagesLength, customersPageState, customersRowsPerPage} from '../../state/customers/customersPages';

import UIDropdownItem from '../UICommon/UIDropdownItem';
import UITable from '../UITable/UITable';
import UITablePages from '../UITable/UITablePages';
import UITablePagination from '../UITable/UITablePagination';

type CustomersModalProps = {
  closeModal: Function;
}

const CustomersModal: FC<CustomersModalProps> = ({
  closeModal,
}) => {
  const {t} = useTranslation();
  const customers: any = useRecoilValue(customersPaginated);
  const [customersSorting, setCustomersSorting] = useRecoilState(customersSetSort);
  const [page, setPage] = useRecoilState(customersPageState);
  const [numberPages, setNumberPages] = useRecoilState(customersRowsPerPage);
  const totalPages = useRecoilValue(customersPagesLength);
  const navigate = useNavigate();

  const {
    mutate: updateCustomerStatus,
  } = useUpdateCustomerStatus();

  const {
    isSuccess: isCalculationResetSuccess,
    mutate: mutateResetCalculation,
  } = useResetCalculation();

  const {
    mutate: mutateFetchCustomer,
  } = useFetchSingleCustomer();

  const customersColumns = [
    {
      id: 'first_name',
      accessor: 'first_name',
      Header: 'Namn',
      sticky: 'top',
      Cell: ({row}: any) => (row.original.first_name + ' ' + row.original.last_name),
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Skapad den',
      sticky: 'top',
      Cell: ({value}: any) => format(new Date(value.replace(/\s+/g, 'T')), 'yyyy/MM/dd', {locale: sv}),
    },
    {
      id: 'status',
      accessor: 'status',
      Header: 'Status',
      sticky: 'top',
      Cell: ({row, value}: any) => {
        return (
          <select
            value={value}
            onChange={(e) => updateCustomerStatus({
              customerId: row.original.id,
              status: e.target.value,
            })}
            className='p-2 rounded-md bg-white border border-gray-200'>
            {customerStatusSelect.map((el: any, key: number) => (
              <option key={key} value={el.value}>{el.label}</option>
            ))}
          </select>
        );
      }},
  ];

  const createQuoteWithCustomer = (id: string) => {
    // reset calculation
    mutateResetCalculation();

    // fetch and set customer
    mutateFetchCustomer(id);
  };

  useEffect(() => {
    if (isCalculationResetSuccess) {
      navigate('/kalkyl/');
    }
  }, [isCalculationResetSuccess]);

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-30 items-center justify-center h-full max-h-screen w-full'>
      <div className='py-5 shadow rounded-2xl bg-white relative w-2/3 px-8 max-h-[80%] overflow-y-scroll'>
        <FaTimes className='w-5 h-5 absolute top-5 right-5 cursor-pointer' onClick={() => closeModal(false)}/>
        <div className="w-full mt-4 pb-8">
          <div
            className='border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10'
          >
            <div className='flex items-start justify-between mb-4'>
              <div>
                <h2 className='mb-3 font-semibold text-xl'>
                  {t('homePage.calculation.createModal.title')}
                </h2>
                <p className='text-sm'>
                  {t('homePage.calculation.createModal.description')}
                </p>
              </div>
            </div>
            <div className='flex items-start justify-between'>
              <div className='flex'>
                <UITablePages
                  value={numberPages}
                >
                  <UIDropdownItem label={10} onClick={setNumberPages} />
                  <UIDropdownItem label={20} onClick={setNumberPages} />
                  <UIDropdownItem label={30} onClick={setNumberPages} />
                  <UIDropdownItem label={40} onClick={setNumberPages} />
                </UITablePages>
              </div>
            </div>
          </div>
          <div className="w-full mt-6 pb-8">
            <UITable
              data={customers}
              columns={customersColumns}
              sorting={customersSorting}
              onClick={createQuoteWithCustomer}
              setSorting={setCustomersSorting}
            />
          </div>
          <div className="sticky bottom-0 bg-white py-4 flex justify-center">
            <UITablePagination
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersModal;
