import {useMutation} from 'react-query';
import {useResetRecoilState} from 'recoil';

import {
  calculationMonthlyConsumptionFormState,
  quoteInstallationState,
  calculationQuoteState,
  calculationSectionState,
  calculationTabState,
  chartDataState,
  currentContactTab,
  currentTask,
} from '../../state/calculation/calculation';
import {
  selectedProducts,
  selectedProductsFilteredByType,
} from '../../state/products/products';
import {quoteAttachments} from '../../state/quotes/quotes';

const useResetCalculation = () => {
  const resetCalculationTabState = useResetRecoilState(calculationTabState);
  const resetContactTabState = useResetRecoilState(currentContactTab);
  const resetQuoteState = useResetRecoilState(calculationQuoteState);
  const resetSectionsState = useResetRecoilState(calculationSectionState);
  const resetMontageState = useResetRecoilState(quoteInstallationState);
  const resetInstallationState = useResetRecoilState(quoteInstallationState);
  const resetConsumptionState = useResetRecoilState(calculationMonthlyConsumptionFormState);
  const resetCurrentTask = useResetRecoilState(currentTask);
  const resetProducts = useResetRecoilState(selectedProducts);
  const resetProductsFiltered = useResetRecoilState(selectedProductsFilteredByType);
  const resetChartDataState = useResetRecoilState(chartDataState);
  const resetAttachments = useResetRecoilState(quoteAttachments);

  return useMutation(async () => {
    resetCalculationTabState();
    resetContactTabState();
    resetQuoteState();
    resetSectionsState();
    resetMontageState();
    resetInstallationState();
    resetConsumptionState();
    resetCurrentTask();
    resetProducts();
    resetProductsFiltered();
    resetChartDataState();
    resetAttachments();
  },
  {
    retry: false,
  },
  );
};

export default useResetCalculation;
