import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {calculationContactTabs} from '../../../config/constants';
import {useRecoilValue} from 'recoil';
import {calculationQuoteState} from '../../../state/calculation/calculation';
import {singleCustomer} from '../../../state/customers/customers';

type CalculationContactTabNavigationProps = {
  currentTab: string;
  setCurrentTab: Function;
};

const CalculationContactTabNavigation: FC<CalculationContactTabNavigationProps> = ({
  currentTab,
  setCurrentTab,
}) => {
  const customer = useRecoilValue(singleCustomer);
  const quote = useRecoilValue(calculationQuoteState);
  const {t} = useTranslation();

  const showTab = (tab: string) => {
    if (customer.id && !quote.id) {
      return tab === 'quote';
    } else if (tab === 'quote') {
      return customer.id;
    } else if (!quote.id && tab == 'documents') {
      return false;
    }
    return true;
  };

  return (
    <div
      className='w-full flex mb-6 h-12 py-4 items-center border-b-2 border-slate-300 sticky
       top-0 left-0 right-0 z-30 bg-white'
    >
      {calculationContactTabs.map((el : {id: number, name: string, accessor: string}) => (
        (showTab(el.accessor) &&
         <div
           key={el.id}
           className='flex flex-col items-center justify-start px-6 cursor-pointer select-none'
           onClick={() => setCurrentTab(el.accessor)}
         >
           <div
             className={`${currentTab !== el.accessor ? 'text-slate-400' : 'text-blue-primary'}
            hover:text-blue-primary text-center text-sm font-semibold `}>
             <div>
               {t(`calculation.pages.contact.tabs.${el.accessor}.name`)}
             </div>
           </div>
         </div>
        )
      ))}
    </div>
  );
};

export default CalculationContactTabNavigation;
