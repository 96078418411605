export const toCurrency = (value: number | undefined, decimals: number = 2) => {
  if (!value || value == 0) {
    return '0 kr';
  }

  // CHAT GPT
  const roundedNumber = Math.round(value * 100) / 100;

  // Convert the number to a string with or without decimal places
  const formattedNumber = roundedNumber % 1 === 0 ? roundedNumber.toString() : roundedNumber.toFixed(decimals);

  // Use regular expressions to add spaces as thousands separators
  const parts = formattedNumber.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Combine the parts and add the Swedish currency symbol (SEK)
  const formattedCurrency = parts.join(',') + ' kr';

  return formattedCurrency;
};
