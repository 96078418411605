import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';

import CustomerType from '../../../../types/CustomerType';

import useUpdateCustomer from '../../../../hooks/customers/useUpdateCustomer';
import useCreateCustomer from '../../../../hooks/customers/useCreateCustomer';
import useClearQuoteGreenTax from '../../../../hooks/quotes/useClearQuoteGreenTax';

import UILoadingIndicator from '../../../UICommon/UILoadingIndicator';
import UIErrorIndicator from '../../../UICommon/UIErrorIndicator';
import UIPaginationButtons from '../../../UICommon/UIPaginationButtons';

type CustomerCreateFormProps = {
  customer: CustomerType;
  nextTab: Function;
  validate: Function;
  isInvalidForm: boolean;
};

const CustomerCreateForm: FC<CustomerCreateFormProps> = ({
  customer,
  nextTab,
  validate,
  isInvalidForm,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    isLoading: isCustomerAddLoading,
    isError: isCustomerAddError,
    isSuccess: isCustomerCreateSuccess,
    data: customerCreateData,
    mutate: mutateCreateCustomer,
  } = useCreateCustomer(customer);

  const {
    isLoading: isCustomerUpdateLoading,
    isError: isCustomerUpdateError,
    isSuccess: isCustomerUpdateSuccess,
    mutate: mutateUpdateCustomer,
  } = useUpdateCustomer();

  const {
    isLoading: isClearGreenTaxLoading,
    isError: isGreenTaxError,
    mutate: mutateClearGreenTax,
  } = useClearQuoteGreenTax();

  useEffect(() => {
    if (isCustomerCreateSuccess) {
      navigate('/kunder/' + customerCreateData.id);
    } else if (isCustomerUpdateSuccess) {
      nextTab();
    }
  }, [isCustomerCreateSuccess, isCustomerUpdateSuccess]);

  const handleClick = () => {
    if (!validate()) {
      return;
    }
    if (customer.id) {
      /*
        Clear green tax if not eligable anymore
      */
      if (customer.type !== 'private person') {
        mutateClearGreenTax();
      }
      mutateUpdateCustomer(customer);
    } else {
      mutateCreateCustomer();
    }
  };

  if (isCustomerAddLoading || isCustomerUpdateLoading || isClearGreenTaxLoading) {
    return <UILoadingIndicator/>;
  };
  if (isCustomerAddError || isCustomerUpdateError || isGreenTaxError) {
    return <UIErrorIndicator/>;
  };

  return (
    <>
      <div className='w-full flex flex-col justify-end pb-6'>
        <h2 className='col-span-2 text-lg font-semibold mb-2'>
          {t('calculation.pages.contact.tabs.customerOverview.title')}
        </h2>
        <div className='border shadow rounded-lg p-5 mb-6 space-y-4'>
          <div>
            <label
              className='uppercase text-[#696969] block text-xs font-bold mb-2'
            >
              {t('calculation.pages.contact.tabs.customerOverview.fields.name')}
            </label>
            <div>
              {`${customer.first_name} ${customer.last_name}`}
            </div>
          </div>
          <div>
            <label
              className='uppercase text-[#696969] block text-xs font-bold mb-2'
            >
              {t('calculation.pages.contact.tabs.customerOverview.fields.email')}
            </label>
            <div>
              {customer.email}
            </div>
          </div>
          {customer.phone_number &&
           <div>
             <label
               className='uppercase text-[#696969] block text-xs font-bold mb-2'
             >
               {t('calculation.pages.contact.tabs.customerOverview.fields.phoneNumber')}
             </label>
             <div>
               {customer.phone_number}
             </div>
           </div>
          }
          {customer.type !== 'private person' &&
           <div>
             <label
               className='uppercase text-[#696969] block text-xs font-bold mb-2'
             >
               {t('calculation.pages.contact.tabs.customerOverview.fields.companyName')}
             </label>
             <div>
               {customer.company_name}
             </div>
           </div>}
        </div>
        <UIPaginationButtons
          nextPage={handleClick}
          nextPageDisabled={isInvalidForm}
          prevPage={() => nextTab(false)}
          title={
            (customer.id ?
            t('calculation.pages.contact.tabs.customerOverview.buttons.save') :
            t('calculation.pages.contact.tabs.customerOverview.buttons.create')
            )!
          }
          nextPageTooltop={isInvalidForm ? t('generic.form.invalid')! : ''}
        />
      </div>
    </>
  );
};

export default CustomerCreateForm;
