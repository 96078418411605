import client from './client';

const URL = 'quote_installation/';

const createQuoteInstallation = async (data: any) => {
  return client.post(URL, data);
};

const updateQuoteInstallation = async (id: number, data: any) => {
  return client.put(`${URL}${id}/`, data);
};

const fetchQuoteInstallation = async (quoteId: number) => {
  return client.get(`${URL}quote/${quoteId}/`);
};

const actions = {
  fetchQuoteInstallation,
  createQuoteInstallation,
  updateQuoteInstallation,
};

export default actions;
