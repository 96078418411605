import {useMutation} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/quotes';
import {quotesArray} from '../../state/quotes/quotes';

const useUpdateQuoteStatus = () => {
  const setQuotes = useSetRecoilState(quotesArray);
  return useMutation(async (data: any) => {
    actions.updateQuote({'status': data.status}, data.quoteId).then(() => {
      actions.fetchQuotes().then((res: any) => {
        setQuotes(res.data);
      });
    });
  });
};

export default useUpdateQuoteStatus;
