import React, {FC, ReactElement} from 'react';

type UILargeButtonProps = {
  label: string;
  onClick: Function;
  width?: string;
  disabled?: boolean;
  toolTip?: string;
  icon?: ReactElement;
};

const UILargeButton: FC<UILargeButtonProps> = ({
  label,
  onClick,
  width,
  disabled,
  toolTip,
  icon,
}) => {
  return (
    <button
      title={toolTip}
      className={
        `text-white bg-blue-primary text-sm font-bold rounded shadow hover:shadow-lg active:bg-blue-800
        text-center py-3 px-16 outline-none
        ${width ? width : 'w-60'} ${disabled ? 'hover:cursor-not-allowed opacity-75' : 'focus:outline-none'}`
      }
      type='button'
      onClick={() => onClick()}
      disabled={disabled}
    >
      <div
        className='flex items-center justify-center'
      >
        {icon && <div
          className='mr-2 text-md'
        >
          {icon}
        </div>}
        {label}
      </div>
    </button>
  );
};

export default UILargeButton;
