import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {customerAddressInputs} from '../../../../config/customerForm';
import CustomerInputType from '../../../../types/CustomerInputType';
import UIInput from '../../../UICommon/UIInput';
import UIPaginationButtons from '../../../UICommon/UIPaginationButtons';

type CustomerAddressFormProps = {
   customer: any;
   formErrors: any;
   onChange: Function;
   nextTab: Function;
};

const CustomerLocationForm: FC<CustomerAddressFormProps> = (
    {
      customer,
      formErrors,
      onChange,
      nextTab,
    },
) => {
  const {t} = useTranslation();

  const onAddressChange = (name: string, value: string) => {
    const tmpAddress: any = {...customer.address};
    tmpAddress[name] = value;
    onChange('address', tmpAddress);
  };

  return (
    <>
      <div className='grid grid-cols-2 gap-x-12 gap-y-4 pb-6'>
        <h2 className='col-span-2 text-lg font-semibold mb-2'>
          {t('calculation.pages.contact.tabs.location.title')}
        </h2>
        {customerAddressInputs.map((el: CustomerInputType, idx: number) => {
          const name = el.name as keyof typeof customer;
          const address: any = {...customer.address};
          return (
            <UIInput
              key={idx}
              type={el.type}
              formErrorMessage={formErrors[el.name]}
              label={t(`calculation.pages.contact.tabs.location.form.${el.name}`)!}
              name={el.name}
              placeholder={el.placeholder}
              value={address[name] && (address[name] as string)}
              onChange={(event: any) => onAddressChange(event.target.name, event.target.value)}
            />
          );
        })}
      </div>
      <UIPaginationButtons
        nextPage={nextTab}
        prevPage={() => nextTab(false)}
      />
    </>
  );
};

export default CustomerLocationForm;
