export const sectionForm = [
  {
    id: 1,
    type: 'number',
    label: 'Bredd (m)',
    min: 1,
    max: 100,
    step: 0.1,
    name: 'width_m',
    placeholder: '17',
    width: '1/3',
  },
  {
    id: 2,
    type: 'number',
    label: 'Höjd (m)',
    min: 1,
    max: 100,
    step: 0.1,
    name: 'height_m',
    placeholder: '15',
    width: '1/3',
  },
  {
    id: 3,
    type: 'number',
    label: 'Takfot höjd (m)',
    min: 0,
    max: 500,
    step: 0.1,
    name: 'roof_foot',
    placeholder: '8',
    width: '1/3',
  },
  {
    id: 4,
    type: 'number',
    min: 0,
    max: 45,
    label: 'Lutning (°)',
    name: 'angle',
    placeholder: '20',
    width: '1/2',
  },
  {
    id: 5,
    type: 'number',
    min: 0,
    max: 360,
    label: 'Väderstreck (°)',
    name: 'rotation',
    placeholder: '275',
    width: '1/2',
  },
  {
    id: 6,
    type: 'modal',
    label: 'Panel',
    name: 'solar_panel',
    width: '1/2',
    required: true,
  },
  {
    id: 7,
    type: 'select',
    label: 'Taktyp',
    name: 'roof_type',
    width: '1/2',
    required: true,
  },
  {
    id: 8,
    label: 'Infästningssystem',
    type: 'select',
    name: 'mount_producer',
    width: 'full',
    required: true,
  },
  {
    id: 9,
    type: 'select',
    label: 'Färg på klämma',
    name: 'color',
    width: 'full',
  },
  {
    id: 10,
    type: 'number',
    min: 0,
    max: 1_000,
    label: 'Cc mellan plåtkronor (mm)',
    name: 'margin_panels_mm',
    placeholder: '400',
    width: '1/2',
  },
  {
    id: 11,
    type: 'number',
    min: 0,
    max: 1_000,
    label: 'Tjocklek takplåt (mm)',
    name: 'roof_thickness',
    placeholder: '8',
    width: '1/2',
  },
  {
    id: 12,
    type: 'select',
    label: 'Panelorientering',
    name: 'panel_orientation',
    width: '1/2',
    items: [
      {
        label: 'Vertikal',
        value: 'vertical',
      },
      {
        label: 'Horisontell',
        value: 'horizontal',
      },
    ],
  },
  {
    id: 13,
    type: 'select',
    label: 'Skenorientering',
    name: 'scene_orientation',
    width: '1/2',
    items: [
      {
        label: 'Vertikal',
        value: 'vertical',
      },
      {
        label: 'Horisontell',
        value: 'horizontal',
      },
    ],
    readonly: true,
  },
  {
    id: 14,
    type: 'number',
    min: 0,
    max: 10_000,
    label: 'Uppe (mm)',
    name: 'margin_up_mm',
    width: '1/4',
  },
  {
    id: 15,
    type: 'number',
    min: 0,
    max: 10_000,
    label: 'Höger (mm)',
    name: 'margin_right_mm',
    width: '1/4',
  },
  {
    id: 16,
    type: 'number',
    min: 0,
    max: 10_000,
    label: 'Nere (mm)',
    name: 'margin_down_mm',
    width: '1/4',
  },
  {
    id: 17,
    type: 'number',
    min: 0,
    max: 10_000,
    label: 'Vänster (mm)',
    name: 'margin_left_mm',
    width: '1/4',
  },
  {
    id: 18,
    type: 'checkbox',
    label: 'Behövs byggställning?',
    name: 'scaffolding',
    width: '1/4',
  },
];
