import {FC, ReactElement, useState} from 'react';
import {VscTriangleDown, VscTriangleRight} from 'react-icons/vsc';


type UIFoldTabProps = {
  title: string,
  subTitle?: string,
  children: ReactElement[],
};

const UIFoldTab: FC<UIFoldTabProps> = ({
  title,
  subTitle,
  children,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className=' bg-white'
    >
      <div
        className='text-sm px-4 py-1 flex
         justify-between hover:bg-gray-100 w-full'
        onClick={() => setOpen(!open)}
      >
        <div
          className='flex items-center select-none'
        >
          <div
            className='mr-1 font-semibold'
          >
            {title}
          </div>
          {children.length > 0 &&
          <div>
            {open ? <VscTriangleDown className='h-3 w-3'/> : <VscTriangleRight className='h-3 w-3'/>}
          </div>}
        </div>
        <div
          className='font-semibold'
        >
          {subTitle}
        </div>
      </div>
      {open &&
      <div>
        {children}
      </div>}
    </div>
  );
};

export default UIFoldTab;
