import {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useRecoilState} from 'recoil';
import {format} from 'date-fns';
import {sv} from 'date-fns/locale';

import {quoteStatusSelect} from '../config/quoteForm';
import {swedishStatus} from '../config/constants';

import {singleCustomer} from '../state/customers/customers';
import {
  customerQuotesSorted,
  customerQuotesSetSort,
} from '../state/quotes/quotes';

import useUpdateQuoteStatus from '../hooks/quotes/useUpdateQuoteStatus';
import useFetchSingleCustomer from '../hooks/customers/useFetchSingleCustomer';
import useFetchQuotes from '../hooks/quotes/useFetchQuotes';

import UICard from '../components/UICommon/UICard';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UINoDataIndicator from '../components/UICommon/UINoDataIndicator';
import UITable from '../components/UITable/UITable';
import UILargeButton from '../components/UICommon/UILargeButton';
import QuoteTemplateModal from '../components/Modals/QuoteTemplateModal';
import useResetCalculation from '../hooks/quotes/useResetCalculation';

const SingleCustomerPage: FC = () => {
  const {t} = useTranslation();
  const {id}: any = useParams();
  const navigate = useNavigate();

  const {
    isLoading: isCustomerLoading,
    isError: isCustomerError,
    mutate: mutateFetchCustomer,
  } = useFetchSingleCustomer();

  const {
    isLoading: isQuotesLoading,
    isError: isQuotesError,
  } = useFetchQuotes();

  const {
    isLoading: isQuoteStatusLoading,
    isError: isQuoteStatusError,
    mutate: mutateUpdateQuoteStatus,
  } = useUpdateQuoteStatus();

  const {
    mutate: mutateResetCalculation,
    isLoading: isCalculationResetLoading,
    isSuccess: isQuoteResetSuccess,
  } = useResetCalculation();

  const singleCustomerData: any = useRecoilValue(singleCustomer);
  const singleCustomerQuotes: any = useRecoilValue(customerQuotesSorted);
  const [customerQuotesSorting, setCustomerQuotesSorting] = useRecoilState(
      customerQuotesSetSort,
  );
  const [quotesModal, setQuotesModal] = useState(false);

  const createNewQuote = () => {
    mutateResetCalculation();
  };

  const editQuote = (id: number) => {
    navigate(`/kalkyl/${id}`);
  };


  useEffect(() => {
    if (id) {
      mutateFetchCustomer(id);
    }
  }, [id]);

  useEffect(() => {
    if (isQuoteResetSuccess) {
      navigate('/kalkyl/');
    }
  }, [isQuoteResetSuccess]);

  const quotesColumns = [
    {
      id: 'name',
      accessor: 'name',
      Header: 'Namn',
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Skapad den',
      Cell: ({value}: any) => format(new Date(value.replace(/\s+/g, 'T')), 'yyyy/MM/dd', {locale: sv}),
    },
    {
      id: 'status',
      accessor: 'status',
      Header: 'Status',
      Cell: ({row, value}: any) => (
        <select defaultValue={value}
          onChange={(e) => mutateUpdateQuoteStatus({quoteId: row.original.id, status: e.target.value})}
          className='p-2 rounded-md bg-white border border-gray-200'>
          {quoteStatusSelect.map((el: any, key: number) => (
            <option key={key} value={el.value}>{el.label}</option>
          ))}
        </select>
      )},
  ];

  if (isCustomerLoading || isQuoteStatusLoading || isQuotesLoading || isCalculationResetLoading) {
    return <UILoadingIndicator />;
  }

  if (isCustomerError || isQuoteStatusError || isQuotesError) {
    return <UIErrorIndicator />;
  }
  return (
    <div className="w-full h-full flex flex-col">
      {!singleCustomerData ? (
        <UINoDataIndicator />
      ) : (
        <>
          <UICard style="py-8 px-8 mb-6 h-1/6">
            <div className="flex justify-between items-center h-full">
              <div>
                <h1 className="font-semibold text-4xl text-blue-primary font-outfit capitalize">
                  {singleCustomerData.first_name} {singleCustomerData.last_name}
                </h1>
                <div className='ml-1'>
                  { singleCustomerData.type == 'company' &&
                    <div className="py-1 text-md font-semibold">
                      {singleCustomerData.company_name},{' '}
                      {singleCustomerData.organisation_number}
                    </div>
                  }
                  <div className="text-lg font-semibold capitalize">{swedishStatus(singleCustomerData.status)}</div>
                </div>
              </div>
              <div className="flex items-end mb-10">
                <div className="text-center mr-4">
                  <UILargeButton
                    label={t('customerDetailsPage.buttons.createCalculation')}
                    onClick={createNewQuote}
                  />
                </div>
                <div className="text-center">
                  <UILargeButton
                    label={t('customerDetailsPage.buttons.createCalculationFromTemplate')}
                    width="w-auto"
                    onClick={() => setQuotesModal(true)}
                  />
                </div>
              </div>
            </div>
          </UICard>
          <UICard style="overflow-y-scroll max-h-full relative pb-8 px-4 h-5/6">
            <div
              className="border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10 pt-8 pb-2"
            >
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="font-semibold text-xl">{t('customerDetailsPage.calculationList.title')}</h2>
                </div>
              </div>
            </div>
            <div className="w-full mt-6">
              <UITable
                data={singleCustomerQuotes}
                columns={quotesColumns}
                sorting={customerQuotesSorting}
                onClick={editQuote}
                setSorting={setCustomerQuotesSorting}
              />
            </div>
          </UICard>
        </>
      )}
      {quotesModal && (
        <QuoteTemplateModal
          closeModal={() => setQuotesModal(false)}
        />
      )}
    </div>
  );
};

export default SingleCustomerPage;
