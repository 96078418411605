import React, {FC, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {authService} from '../../api/authService';
import {appPages} from '../../config/constants';
import AppLayoutMenuPage from '../../types/AppLayoutMenuType';

import {calculationQuoteState} from '../../state/calculation/calculation';
import {currentUser} from '../../state/user/currentUser';

import UIImage from '../UICommon/UIImage';
import UINavToggler from '../UICommon/UINavToggler';

import img from '../../assets/logo.svg';
import {FiLogIn} from 'react-icons/fi';

const AppLayoutMenu: FC = () => {
  const {t} = useTranslation();
  const user = useRecoilValue(currentUser);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const quote = useRecoilValue(calculationQuoteState);

  const getPageURL = (page: AppLayoutMenuPage) => {
    if (page.name === 'calculation' && quote.id) {
      return `${page.url}/${quote.id}`;
    }
    return page.url;
  };

  const shouldAppearInMenu = (page: AppLayoutMenuPage) => {
    switch (page.name) {
      case 'templates': return false;
      case 'projectManagement': return ['admin', 'project_manager'].includes(user.role);
      case 'calculation': return quote.id;
      default: return true;
    }
  };

  const onNavigate = (page: any) => {
    if (page.name === 'projectManagement') {
      setIsOpen(false);
    }
    navigate(getPageURL(page));
  };

  return (
    <div
      className='relative group'
    >
      <UINavToggler
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      />
      <nav
        className={`${isOpen ? 'w-72 p-5' : 'w-0'} transition-all duration-200 ease-in-out
             overflow-hidden shadow-2xl bg-white
             h-full relative flex items-end`}>
        {<Link
          className='text-left absolute text-sm uppercase top-10 font-bold w-[240px]'
          to="/hem"
        >
          <UIImage url={img} style='w-full'/>
        </Link>}
        <div
          className='flex flex-col h-full w-full justify-between pt-36'
        >
          <div className='flex flex-col w-full space-y-2'>
            {appPages.map((page: AppLayoutMenuPage, index: number) => {
              if (shouldAppearInMenu(page)) {
                return (
                  <div
                    key={index}
                    className={`text-lg py-2 flex items-center rounded-lg
                        pl-6
                        ${location.pathname.startsWith(page.url) ? 'text-white bg-blue-600' :
                          'text-slate-700 hover:text-white hover:bg-blue-600'}`}
                    onClick={() => onNavigate(page)}
                  >
                    <div
                      className='flex justify-center items-center pr-4'>
                      {page.icon}
                    </div>
                    <div>
                      {t(`navigation.${page.name}`)}
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <button
            className='text-sm py-1 px-1 flex items-center text-red-500
                 hover:border-b border-red-500 w-fit transition-all duration-75 ease-in'
            onClick={() => authService.logout()}
          >
            <div className='flex justify-center items-center mr-2'>
              <FiLogIn className="text-md"/>
            </div>
            <div
              className='whitespace-nowrap'
            >
              {t('generic.buttons.logout')}
            </div>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default AppLayoutMenu;
