import {useMutation} from 'react-query';
import actions from '../../api/products';


const useMutateDeleteQuoteProduct = () => {
  return useMutation('useMutateDeleteQuoteProduct', async (id: number) => {
    const res = await actions.deleteProduct(id);
    return res;
  });
};

export default useMutateDeleteQuoteProduct;
