import {atom, selector} from 'recoil';
import MontageType from '../../types/MontageType';
import MonthlyConsumptionType from '../../types/MonthlyConsumptionType';
import QuoteType from '../../types/QuoteType';
import SectionType from '../../types/SectionType';

export const chartDataState = atom({
  key: 'chartDataState',
  default: {
    'production': {},
    'consumption': {},
    'installment': {},
    'accumulated_return': {},
  },
});

export const calculationTabState = atom({
  key: 'calculationTabState',
  default: 'contact',
});

export const currentContactTab = atom({
  key: 'currentContactTab',
  default: 'contact',
});

export const currentCalculationTab = selector({
  key: 'currentCalculationTab',
  get: ({get}) => {
    return get(calculationTabState);
  },
  set: ({get, set}, newValue: any) => {
    set(calculationTabState, newValue);
  },
});

export const calculationQuoteState = atom({
  key: 'calculationQuoteState',
  default: {
    id: null,
    name: '',
    customer: null,
  } as QuoteType,
});

export const calculationSectionState = atom({
  key: 'calculationSectionState',
  default: [] as SectionType[],
});

export const quoteInstallationState = atom({
  key: 'quoteInstallationState',
  default: {
    quote: null,
    hours_solar_montage: 0,
    hours_solar_electrician: 0,
    ata_hours_solar_montage: 0,
    ata_hours_solar_electrician: 0,
    shipping: 0,
    inspection_qty: 1,
    plate_qty: 0,
    crossrail: false,
    other_material: true,
    distance_facility: 0,
    work_days: 0,
    workers: 0,
    travel_time: 0,
    electrical_cabinet: true,
    smart_meter: false,
    fuse: false,
    checkwatt: false,
    cable_length: 0,
  } as MontageType,
});

export const calculationMonthlyConsumptionFormState = atom({
  key: 'calculationMonthlyConsumptionFormState',
  default: {
    id: null,
    quote: null,
    january: 1500,
    february: 1500,
    march: 1500,
    april: 1500,
    may: 1500,
    june: 1500,
    july: 1500,
    august: 1500,
    september: 1500,
    october: 1500,
    november: 1500,
    december: 1500,
  } as MonthlyConsumptionType,
});

export const currentTask = atom({
  key: 'currentTask',
  default: {
    id: null,
    isLoading: false,
    isSuccess: false,
    data: {
      isProposal: true,
      sendAsEmail: false,
      url: null,
    },
    error: null,
  },
});
