import {FC} from 'react';

type UIToggleProps = {
  title: string
  isToggled: boolean,
  setToggled: Function,
};

const UIToggle: FC<UIToggleProps> = ({
  title,
  isToggled,
  setToggled,
}) => {
  return (
    <div
      className='flex items-center space-x-2'
    >
      <span className="ml-3 text-sm font-medium text-gray-900">
        {title}
      </span>
      <label className="relative inline-flex items-center cursor-pointer mx-4">
        <input
          type="checkbox"
          checked={isToggled}
          className="sr-only peer"
          onChange={() => setToggled(!isToggled)}
        />
        <div className={`w-11 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300
              rounded-full peer h-6 after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600
              peer-checked:after:translate-x-full peer-checked:after:border-white
              after:content-[''] after:absolute after:top-[2px] after:left-[2px]
              after:bg-white after:border-gray-300 after:border after:rounded-full`}
        />
      </label>
    </div>
  );
};

export default UIToggle;
