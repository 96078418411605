import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FaTimes} from 'react-icons/fa';
import {useRecoilState} from 'recoil';
import {solarPanelsColumns} from '../../config/constants';
import {solarPanelsSetSort} from '../../state/products/solarPanels';
import UITable from '../UITable/UITable';

type SolarPanelsModalProps = {
  solarPanels: any;
  setSolarPanel: Function;
  closeModal: Function;
}

const SolarPanelsModal: FC<SolarPanelsModalProps> = ({
  solarPanels,
  setSolarPanel,
  closeModal,
}) => {
  const {t} = useTranslation();
  const [solarPanelsSorting, setSolarPanelsSorting] = useRecoilState(solarPanelsSetSort);

  const onClick = (id: number) => {
    closeModal(false);
    setSolarPanel(id);
  };

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-50 items-center justify-center h-full max-h-screen w-full'>
      <div className='py-5 shadow rounded-2xl bg-white relative w-2/3 px-8 max-h-[80%] overflow-y-scroll'>
        <FaTimes className='w-5 h-5 absolute top-5 right-5 cursor-pointer' onClick={() => closeModal(false)}/>
        <div className='text-lg font-semibold mb-2 px-4'>
          {t('calculation.pages.sections.singleSection.solarPanelsModal.title')}
        </div>
        <UITable
          data={solarPanels}
          columns={solarPanelsColumns}
          sorting={solarPanelsSorting}
          setSorting={setSolarPanelsSorting}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default SolarPanelsModal;
