import {atom, selector} from 'recoil';
import {quotesTemplatesSorted} from './quotesTemplates';

export const quoteTemplatesPageState = atom({
  key: 'quoteTemplatesPageState',
  default: 1,
});

export const quoteTemplatesRowsPerPage = atom({
  key: 'quoteTemplatesRowsPerPage',
  default: 10,
});

export const quoteTemplatesPagesLength = selector({
  key: 'quoteTemplatesPagesLength',
  get: ({get}) => {
    const quotes = get(quotesTemplatesSorted);
    const rowsPerPage = get(quoteTemplatesRowsPerPage);
    if (quotes == null) {
      return 0;
    }

    return Math.ceil(quotes.length / rowsPerPage);
  },
});
