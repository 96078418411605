import {useMutation} from 'react-query';
import actions from '../../api/projectManagement';
import {useSetRecoilState} from 'recoil';
import {projectColumnDefinitions} from '../../state/projectManagement/projectManagement';


const useMutateFetchProjectColumnDefinitions = () => {
  const setHeaders = useSetRecoilState(projectColumnDefinitions);
  return useMutation('useMutateFetchProjectColumnDefinitions', async () => {
    const res = await actions.fetchColumnDefinitions();
    setHeaders(res.data);
    return res.data;
  });
};

export default useMutateFetchProjectColumnDefinitions;
