import CustomerInputType from '../types/CustomerInputType';
import CustomerTypeFieldType from '../types/CustomerTypeFieldType';

export const customerPropertySelect: CustomerTypeFieldType[] = [
  {
    id: 1,
    label: '16A',
    value: '16A',
  },
  {
    id: 2,
    label: '20A',
    value: '20A',
  },
  {
    id: 3,
    label: '25A',
    value: '25A',
  },
  {
    id: 4,
    label: '35A',
    value: '35A',
  },
  {
    id: 5,
    label: '50A',
    value: '50A',
  },
  {
    id: 6,
    label: '63A',
    value: '63A',
  },
  {
    id: 7,
    label: '80A',
    value: '80A',
  },
  {
    id: 8,
    label: '100A',
    value: '100A',
  },
  {
    id: 9,
    label: '> 100A',
    value: '> 100A',
  },
];

export const customerTypeSelect: CustomerTypeFieldType[] = [
  {
    id: 1,
    label: 'Privatperson',
    value: 'private person',
  },
  {
    id: 2,
    label: 'Företag',
    value: 'company',
  },
  {
    id: 3,
    label: 'Bostadsrättsförening',
    value: 'housing cooperative',
  },
];

export const customerStatusSelect: CustomerTypeFieldType[] = [
  {
    id: 1,
    label: 'Lead',
    value: 'lead',
  },
  {
    id: 2,
    label: 'Offert bearbetning',
    value: 'ongoing',
  },
  {
    id: 3,
    label: 'Offert skickad',
    value: 'sent',
  },
  {
    id: 4,
    label: 'Installation pågår',
    value: 'installation',
  },
  {
    id: 5,
    label: 'Projekt avslutat',
    value: 'completed',
  },
  {
    id: 5,
    label: 'Tappad/Förlorad',
    value: 'lost',
  },
];

export const customerBaseInputs: CustomerInputType[] = [
  {
    id: 1,
    type: 'text',
    label: 'Förnamn',
    name: 'first_name',
    required: true,
  },
  {
    id: 2,
    type: 'text',
    label: 'Efternamn',
    name: 'last_name',
    required: true,
  },
  {
    id: 3,
    type: 'email',
    label: 'E-postadress',
    name: 'email',
    required: true,
  },
  {
    id: 4,
    type: 'email',
    label: 'Upprepa e-postadress',
    name: 'email_repeat',
    required: true,
  },
  {
    id: 5,
    type: 'tel',
    label: 'Telefon',
    name: 'phone_number',
    placeholder: '0702935824',
  },
];

export const customerCompanyInputs: CustomerInputType[] = [
  {
    id: 1,
    type: 'text',
    label: 'Företag',
    name: 'company_name',
    placeholder: 'Svensson AB',
    required: true,
  },
  {
    id: 2,
    type: 'text',
    label: 'Orginisationsnummer',
    name: 'organisation_number',
    placeholder: '123123213',
    required: true,
  },
];

export const customerAddressInputs: CustomerInputType[] = [
  {
    id: 1,
    type: 'text',
    label: 'Stad',
    name: 'city',
    placeholder: 'Halmstad',
  },
  {
    id: 2,
    type: 'text',
    label: 'Län',
    name: 'county',
    placeholder: 'Halland',
  },
  {
    id: 3,
    type: 'text',
    label: 'Gata',
    name: 'street',
    placeholder: 'Kristian IV',
  },
  {
    id: 4,
    type: 'text',
    label: 'Gatunummer',
    name: 'street_number',
    placeholder: '4',
  },
  {
    id: 5,
    type: 'text',
    label: 'Postnummer',
    name: 'postal_code',
    placeholder: '30250',
  },
];
