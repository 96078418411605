import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import CustomerInputType from '../../../../types/CustomerInputType';
import {mapCustomerType} from '../../../../utils/mapCustomerType';
import {customerBaseInputs, customerCompanyInputs, customerTypeSelect} from '../../../../config/customerForm';
import CustomerTypeFieldType from '../../../../types/CustomerTypeFieldType';

import UIInput from '../../../UICommon/UIInput';
import UIDropdownSelect from '../../../UICommon/UIDropdownSelect';
import UIDropdownSelectItem from '../../../UICommon/UIDropdownSelectItem';
import UIPaginationButtons from '../../../UICommon/UIPaginationButtons';

type CustomerBasicInformationProps = {
    formValues: any;
    onChange: Function;
    formErrors: any;
    nextTab: Function;
    isExisting: boolean;
};

const CustomerContactForm: FC<CustomerBasicInformationProps> = (
    {
      formValues,
      onChange,
      formErrors,
      nextTab,
      isExisting,
    },
) => {
  const {t} = useTranslation();
  return (
    <>
      <div className='grid grid-cols-2 gap-x-12 gap-y-4 pb-6'>
        <h2 className='col-span-2 text-lg font-semibold mb-2'>
          {t('calculation.pages.contact.tabs.contact.contactTitle')}
        </h2>
        {customerBaseInputs.map((el: CustomerInputType) => {
          const name = el.name as keyof typeof formValues;
          if (isExisting && name === 'email_repeat') {
            return;
          }
          return (
            <UIInput
              key={el.id}
              type={el.type}
              label={t(`calculation.pages.contact.tabs.contact.form.${el.name}`)!}
              name={el.name}
              placeholder={el.placeholder}
              value={formValues[name] && (formValues[name] as string)}
              formErrorMessage={formErrors[name]}
              onChange={(event: any) => onChange(event.target.name, event.target.value)}
              required={el.required}
            />
          );
        })}
        <div>
          <UIDropdownSelect
            label={t('calculation.pages.contact.tabs.contact.form.typeSelect.title')}
            value={formValues.type ? mapCustomerType(formValues.type) : 'Typ'}
          >
            {customerTypeSelect.map((el: CustomerTypeFieldType) => (
              <UIDropdownSelectItem
                key={el.id}
                label={t(`calculation.pages.contact.tabs.contact.form.typeSelect.options.${el.value}`)!}
                value={el.value}
                onClick={() => onChange('type', el.value)}
              />
            ))}
          </UIDropdownSelect>
        </div>
      </div>
      {formValues.type !== 'private person' && (
        <div className='grid grid-cols-2 gap-x-12 gap-y-4 pb-6'>
          <h2 className='col-span-2 text-lg font-semibold mb-2'>
            {t('calculation.pages.contact.tabs.contact.companyTitle')}
          </h2>
          {customerCompanyInputs.map((el: CustomerInputType) => {
            const name = el.name as keyof typeof formValues;
            return (
              <UIInput
                key={el.id}
                type={el.type}
                label={t(`calculation.pages.contact.tabs.contact.form.${el.name}`)!}
                name={el.name}
                placeholder={el.placeholder}
                value={formValues[name] && (formValues[name] as string)}
                formErrorMessage={formErrors[name]}
                onChange={(event: any) => onChange(event.target.name, event.target.value)}
                required={el.required}
              />
            );
          })}
        </div>
      )}
      <UIPaginationButtons
        nextPage={nextTab}
      />
    </>
  );
};

export default CustomerContactForm;
