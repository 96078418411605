import {FC} from 'react';
import UINoImage from './UINoImage';

import addDefaultSrc from '../../assets/placeholder.png';

type UIImageProps = {
  url: string;
  style?: string;
}

const UIImage: FC<UIImageProps> = ({url, style}) => {
  if (url == null) {
    return (
      <UINoImage style={style}/>
    );
  }
  return (
    <img
      src={url}
      className={`${style} w-full h-full object-cover`}
      onError={(e) => {
        (e.target as HTMLImageElement).onerror = null;
        (e.target as HTMLImageElement).src = addDefaultSrc;
      }}/>
  );
};

export default UIImage;
