import {useRecoilState} from 'recoil';
import {currentTask} from '../../state/calculation/calculation';
import {useMutation} from 'react-query';
import actions from '../../api/tasks';


const useGeneratePDF = () => {
  const [task, setTask] = useRecoilState(currentTask);
  return useMutation(async (quoteId: number) => {
    const data = await actions.generatePDF(quoteId, task);
    setTask(data);
    return data;
  });
};

export default useGeneratePDF;
