import {atom} from 'recoil';
export const projectManagementArray = atom({
  key: 'projectManagementArray',
  default: [],
});

export const projectColumnDefinitions = atom({
  key: 'projectColumnDefinitions',
  default: [],
});

export const projectHistory = atom({
  key: 'projectHistory',
  default: [],
});

export const currentProjectHistory= atom({
  key: 'currentProjectHistory',
  default: undefined,
});
