import {useMutation} from 'react-query';
import actions from '../../api/products';


const useMutateUpdateQuoteProduct = () => {
  return useMutation('useMutateUpdateQuoteProduct', async (data: any) => {
    const res = await actions.updateProduct(data.id, data);
    return res;
  });
};

export default useMutateUpdateQuoteProduct;

