import {FC} from 'react';
import {FaArrowLeft, FaBars} from 'react-icons/fa';

type UINavTogglerProps = {
  onClick: Function;
  isOpen: boolean;
};

const UINavToggler: FC<UINavTogglerProps> = ({
  onClick,
  isOpen,
}) => {
  return (
    <div
      className={`absolute z-20 ${isOpen ? 'right-0' : ''} cursor-pointer opacity-30 hover:opacity-100
      transition-all duration-100 ease-in-out
       text-2xl p-2`}
      onClick={() => onClick()}
    >


      {isOpen ?
        <FaArrowLeft
          className='hidden group-hover:block'
        /> :
        <FaBars className="text-xl text-black" />
      }
    </div>
  );
};

export default UINavToggler;
