import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {toCurrency} from '../../../utils/toCurrency';
import UICard from '../../UICommon/UICard';

type CalculationOfferTotalSummaryProps = {
  sum: number;
  deductions: number;
  totalCost: number;
  taxes: number;
  isPrivatePerson: boolean;
};

const CalculationOfferTotalSummary: FC<CalculationOfferTotalSummaryProps> = ({
  sum,
  deductions,
  totalCost,
  taxes,
  isPrivatePerson,
}) => {
  const {t} = useTranslation();
  return (
    <div className="w-full">
      <div className='w-full border-t border-[#c3c3c3] my-10'></div>
      <div className='w-1/2 ml-auto'>
        <UICard style='max-h-[60%] relative h-full overflow-y-scroll mb-8 py-2'>
          { isPrivatePerson ?
              <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-semibold
               py-2 px-4 hover:bg-gray-100'>
                <div className='w-1/2 px-2'>
                  {t('calculation.financial.totalSumExTAX')}
                </div>
                <div className='w-1/2 px-2 text-right'>
                  {toCurrency(sum, 0)}
                </div>
              </div> :
              <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-bold
               py-2 px-4 hover:bg-gray-100'>
                <div className='w-1/2 px-2'>
                  {t('calculation.financial.totalSum')}
                </div>
                <div className='w-1/2 px-2 text-right'>
                  {toCurrency(sum, 0)}
                </div>
              </div>
          }
          <>
            { isPrivatePerson && (
              <>
                <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-semibold
                 py-2 px-4 hover:bg-gray-100'>
                  <div className={`w-1/2 px-2`}>
                    {t('calculation.financial.TAX')}
                  </div>
                  <div className={`w-1/2 px-2 text-right`}>
                    {toCurrency(taxes, 0)}
                  </div>
                </div>
                <div className='flex justify-between text-sm py-1 px-4 hover:bg-gray-100'>
                  <div className='w-1/2 px-2 font-semibold'>
                    {t('calculation.financial.sumIncTAX')}
                  </div>
                  <div className='w-1/2 px-2 text-right font-semibold'>
                    {toCurrency(sum + taxes, 0)}
                  </div>
                </div>
                <>
                  {deductions > 0 && (
                    <>
                      <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-semibold
                       py-2 px-4 hover:bg-gray-100'>
                        <div className='w-1/2 px-2'>
                          {t('calculation.financial.greenDeduction')}
                        </div>
                        <div className='w-1/2 px-2 text-right'>
                          -{toCurrency(deductions, 0)}
                        </div>
                      </div>
                      <div className='flex justify-between text-sm py-1
                       px-4 font-bold hover:bg-gray-100'>
                        <div className='w-1/2 px-2'>
                          {t('calculation.financial.sumIncTAXAfterDeduction')}
                        </div>
                        <div className='w-1/2 px-2 text-right'>
                          {toCurrency(totalCost, 0)}
                        </div>
                      </div>
                    </>
                  )}
                </>
              </>
            )}
          </>
        </UICard>
      </div>
    </div>
  );
};

export default CalculationOfferTotalSummary;
