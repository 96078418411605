import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} MountProducers
 */

const URL = 'mounts/producers/';

const fetchMountProducers = async () => {
  return client.get(URL);
};

const actions = {
  fetchMountProducers,
};

export default actions;
