import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import useFetchQuoteCharts from '../../../hooks/quotes/useFetchQuoteCharts';
import {
  calculationQuoteState,
  chartDataState,
  currentCalculationTab,
} from '../../../state/calculation/calculation';
import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';
import CalculationStatisticsAccumulatedGraph from './CalculationStatisticsAccumulatedGraph';
import CalculationStatisticsConsumptionGraph from './CalculationStatisticsConsumptionGraph';
import CalculationStatisticsForm from './CalculationStatisticsForm';
import CalculationStatisticsInstallationGraph from './CalculationStatisticsInstallationGraph';
import CalculationStatisticsProductionGraph from './CalculationStatisticsProductionGraph';
import UIPaginationButtons from '../../UICommon/UIPaginationButtons';

const CalculationStatisticsTab: FC = () => {
  const {t} = useTranslation();
  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const [charts, setCharts] = useState(true);
  const [chartsLoading, setChartsLoading] = useState(false);
  const quote = useRecoilValue(calculationQuoteState);
  const {
    isLoading: isChartDataLoading,
    mutate: mutateFetchChartData,
  } = useFetchQuoteCharts();
  const chartData = useRecoilValue(chartDataState);

  const goToNextPage = () => {
    setCurrentTab('quote');
  };

  const goToPreviousPage = () => {
    setCurrentTab('montage');
  };

  useEffect(() => {
    mutateFetchChartData();
  }, []);

  if (isChartDataLoading) {
    return (
      <UILoadingIndicator />
    );
  }

  return (
    <div className='w-full h-[75%] overflow-y-scroll'>
      <h2 className='font-bold text-lg mb-4'>
        {t('calculation.pages.statistics.title')}
      </h2>
      <div className="w-full">
        <CalculationStatisticsForm
          setCharts={setCharts}
          setChartsLoading={setChartsLoading}
          isChartsLoading={chartsLoading}
        />
      </div>
      {chartsLoading && <UILoadingIndicatorSmall />}
      {charts && chartData && (
        <>
          {chartData.production &&
          <div className="w-full">
            <CalculationStatisticsProductionGraph
              productData={chartData.production}
              url={`https://byhmgardprodstorage.blob.core.windows.net/media/production_plot_${quote.id}.svg`}
            />
          </div>}
          {chartData.consumption &&
          <div className="w-full">
            <CalculationStatisticsConsumptionGraph
              consumptionData={chartData.consumption}
              url={`https://byhmgardprodstorage.blob.core.windows.net/media/consumption_plot_${quote.id}.svg`}
            />
          </div>}
          {chartData.accumulated_return &&
          <div className="w-full">
            <CalculationStatisticsAccumulatedGraph
              accumulatedReturnData={chartData.accumulated_return}
              url={`https://byhmgardprodstorage.blob.core.windows.net/media/accumulated_return_plot_${quote.id}.svg`}
            />
          </div>
          }
          {chartData.installment &&
          <div className="w-full">
            <CalculationStatisticsInstallationGraph
              installmentData={chartData.installment}
              url={`https://byhmgardprodstorage.blob.core.windows.net/media/installment_plot_${quote.id}.svg`}
            />
          </div>
          }
        </>
      )}
      <UIPaginationButtons
        nextPage={goToNextPage}
        prevPage={goToPreviousPage}
      />
    </div>
  );
};

export default CalculationStatisticsTab;
