import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {useRecoilValue} from 'recoil';
import {selectedProductsFilteredByType} from '../../../state/products/products';
import {toCurrency} from '../../../utils/toCurrency';
import UICard from '../../UICommon/UICard';
import CalculationOfferSummaryCard from './CalculationOfferSummaryCard';
import {UITableMaterials} from '../../UITable/UITableMaterials';
import UISummaryTable from '../../UICommon/UISummaryTable';
import {calculationQuoteState} from '../../../state/calculation/calculation';

type CalculationOfferMaterialsProps = {
  price: number;
  tax: number;
  isPrivatePerson: boolean;
  materialCosts: any;
};

const CalculationOfferMaterials: FC<CalculationOfferMaterialsProps> = ({
  price,
  tax,
  isPrivatePerson,
  materialCosts,
}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const products: any = useRecoilValue(selectedProductsFilteredByType);
  const quote = useRecoilValue(calculationQuoteState);
  const materialCostsRows: any = {};
  const additions = quote.additions;

  materialCosts.map((cost: any) => {
    if (cost.description === 'Frakt') {
      materialCostsRows[`${cost.description} ${t('calculation.financial.incAddition')
          .replace('{addition}', additions.shipping)}`] =
       toCurrency(cost.cost * (1 + 0.01 * additions.shipping));
    } else if (cost.description === 'Felmarginal') {
      materialCostsRows[`${cost.description} ${t('calculation.financial.incAddition')
          .replace('{addition}', additions.margin_error)}`] =
       toCurrency(cost.cost * (1 + 0.01 * additions.margin_error));
    } else {
      materialCostsRows[cost.description] = toCurrency(cost.cost);
    }
  });

  return (
    <div className="w-full space-y-2">
      <UICard style='w-full'>
        <div
          className={`w-full flex justify-between items-center h-6 select-none p-7 ${open && 'mb-4'}`}
          onClick={() => setOpen(!open)}>
          <h2 className='font-semibold text-sm text-[#1E1E1E]'>
            {t('calculation.pages.quote.materialList.title')}
          </h2>
          <div className='flex items-center'>
            <span className='mr-4 text-sm font-semibold'>
              {`${t('calculation.financial.sumExTAX')} ${toCurrency(price, 0)}`}
            </span>
            {open ? <FiChevronDown className='h-6 w-6 ml-2'/> :
             <FiChevronUp className='h-6 w-6 ml-2'/>}
          </div>
        </div>
        <div className={`w-full p-4 ${!open && 'hidden'}`}>
          <UITableMaterials products={products}/>
        </div>
      </UICard>
      {open && Object.keys(materialCostsRows).length > 0 &&
        <UISummaryTable
          rows={materialCostsRows}
        />}
      {open && (
        <CalculationOfferSummaryCard
          price={price}
          tax={tax || 0}
          isPrivatePerson={isPrivatePerson}
        />
      )}
    </div>
  );
};

export default CalculationOfferMaterials;
