import {useMutation} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/quoteInstallation';
import {quoteInstallationState} from '../../state/calculation/calculation';

const useUpdateQuoteInstallation = () => {
  const setInstallation = useSetRecoilState(quoteInstallationState);
  return useMutation(async (data: any) => {
    const res = await actions.updateQuoteInstallation(data.id, data);
    setInstallation(res.data);
    return res.data;
  }, {retry: false});
};

export default useUpdateQuoteInstallation;
