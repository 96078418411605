import {useMutation} from 'react-query';
import actions from '../../api/projectManagement';


const useMutateCreateProject = () => {
  return useMutation('useMutateCreateProject', async (data: any) => {
    const res = await actions.createProject(data);
    return res.data;
  });
};


export default useMutateCreateProject;
