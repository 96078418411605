import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {quotesArray} from '../../state/quotes/quotes';
import QuoteType from '../../types/QuoteType';
import useFetchQuotes from '../../hooks/quotes/useFetchQuotes';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';
import UIDeleteButton from '../UICommon/UIDeleteButton';

type SelectQuoteModalProps = {
  onClose: Function,
  onSubmit: Function,
};

const SelectQuoteModal: FC<SelectQuoteModalProps> = ({
  onSubmit,
  onClose,
}) => {
  const {t} = useTranslation();
  const quotes = useRecoilValue(quotesArray);

  const {
    isLoading: isQuotesLoading,
  } = useFetchQuotes();

  const submit = (quoteId: any) => {
    onSubmit(quoteId);
  };

  if (isQuotesLoading) {
    return <UILoadingIndicator/>;
  }

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-50 items-center justify-center h-full w-full'>
      <div className='flex bg-white w-fit rounded-2xl h-1/2 w-[500px]
      p-4 items-center flex-col justify-between'>
        <div className='text-xl font-semibold'>
          Välj kalkyl
        </div>
        <div className='flex flex-col justify-between space-y-2 w-full'>
          {quotes.map((quote: QuoteType, idx: number) => (
            <div
              key={idx}
              onClick={() => submit(quote.id)}
              className='bg-slate-200 hover:border w-full text-2xl rounded px-6'
            >
              {quote.name}
            </div>
          ))}
        </div>
        <div className='flex w-full justify-center space-x-2'>
          <UIDeleteButton
            label={t('calculation.pages.contact.tabs.documents.modal.form.buttons.cancel')!}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectQuoteModal;
