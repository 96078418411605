import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FaRegTimesCircle} from 'react-icons/fa';
import {FiChevronDown, FiChevronUp, FiPlusCircle} from 'react-icons/fi';
import {AxiosError} from 'axios';
import {useRecoilValue} from 'recoil';
import {sectionForm} from '../../../config/sectionForm';

import useFetchSolarPanels from '../../../hooks/products/useFetchSolarPanels';
import useDeleteSection from '../../../hooks/sections/useDeleteSection';
import useUpdateSection from '../../../hooks/sections/useUpdateSection';

import {colorsArray} from '../../../state/products/colors';
import {mountProducersArray} from '../../../state/products/mountProducers';
import {roofTypesArray} from '../../../state/products/roofTypes';
import {solarPanelsSorted} from '../../../state/products/solarPanels';

import SolarPanelsModal from '../../Modals/SolarPanelsModal';
import UICheckbox from '../../UICommon/UICheckbox';
import UIDropdownSelect from '../../UICommon/UIDropdownSelect';
import UIDropdownSelectItem from '../../UICommon/UIDropdownSelectItem';
import UIErrorIndicator from '../../UICommon/UIErrorIndicator';
import UIInput from '../../UICommon/UIInput';
import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';
import CalculationSectionGrid from './CalculationSectionGrid';

type CalculationSingleSectionProps = {
  section: any;
  index: number;
  sections: any;
  setSections: Function;
};

const CalculationSingleSection: FC<CalculationSingleSectionProps> = ({
  section,
  index,
  sections,
  setSections,
}) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(sections.length === index + 1);
  const [isOpened, setIsOpened] = useState(false);
  const solarPanels = useRecoilValue(solarPanelsSorted);

  const {
    isLoading: isSolarPanelsLoading,
    isError: isSolarPanelsError,
    refetch: reFetchSolarPanels,
  } = useFetchSolarPanels();

  const {
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    mutate: mutateDelete,
  } = useDeleteSection(section.id);

  const {
    isLoading: mutateUpdateLoading,
    isError: isSectionError,
    error: sectionUpdateError,
    mutate: mutateUpdate,
  } = useUpdateSection(section, index, section.id);

  const sectionStatusError = sectionUpdateError instanceof AxiosError && sectionUpdateError?.response?.data;
  const mountProducers: any = useRecoilValue(mountProducersArray);
  const roofTypes: any = useRecoilValue(roofTypesArray);
  const colors: any = useRecoilValue(colorsArray);

  const [select, setSelect] = useState<any>({
    environment: false,
    roofType: false,
    color: false,
    panelOrientation: false,
    sceneOrientation: false,
  });

  useEffect(() => {
    reFetchSolarPanels();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      mutateUpdate();
    }, 500);

    return () => clearTimeout(timer);
  }, [section.width_m, section.height_m, section.roof_foot, section.angle, section.rotation, section.solar_panel,
    section.roof_type, section.mount_producer, section.color, section.margin_panels_mm, section.roof_thickness_mm,
    section.panel_orientation, section.scene_orientation, section.margin_up_mm, section.margin_down_mm,
    section.margin_left_mm, section.margin_right_mm, section.scaffolding, section.solar_panel_amount]);

  const onChange = (event: any) => {
    const tmpSections = [...sections];
    const tmpSection = {...section};
    const {name, value, type} = event.target;
    let tmpValue = value;
    if (type === 'number') {
      const {min, max} = event.target;
      const numericValue = parseFloat(tmpValue);
      tmpValue = Math.min(Math.max(numericValue, min), max);
    }
    tmpSection[name] = tmpValue;
    tmpSections[index] = tmpSection;
    setSections(tmpSections);
  };

  const setSolarPanel = (id: number) => {
    const tmpSections = [...sections];
    const tmpSection = {...section};
    tmpSection.solar_panel = id;
    tmpSections[index] = tmpSection;
    setSections(tmpSections);
  };

  const setSelectedValue = (key: string, value: string) => {
    const tmpSections = [...sections];
    const tmpSection = {...section};
    if (key === 'panel_orientation') {
      tmpSection['scene_orientation'] = value == 'vertical' ? 'horizontal' : 'vertical';
    }
    tmpSection[key] = value;
    tmpSections[index] = tmpSection;
    setSections(tmpSections);
    const tmpSelect: any = {...select};
    tmpSelect[key] = false;
    setSelect(tmpSelect);
  };

  const handleCheckbox = () => {
    const tmpSections = [...sections];
    const tmpSection = {...section};
    tmpSection.scaffolding = !tmpSection.scaffolding;
    tmpSections[index] = tmpSection;
    setSections(tmpSections);
  };

  const filterSolarPanels = (id: number) => {
    if (solarPanels) {
      const element: any = solarPanels.find((el: any) => el.id === id);
      return element?.name;
    }
  };

  const filterForName = (name: string) => {
    switch (name) {
      case 'roof_type': return roofTypes.find((el: any) => el.id === section.roof_type).name;
      case 'mount_producer': return mountProducers.find((el: any) => el.id === section.mount_producer).name;
      case 'color': return colors.find((el: any) => el.id === section.color).name;
      case 'panel_orientation': return section.panel_orientation;
      case 'scene_orientation': return section.scene_orientation;
      default: return '';
    }
  };

  if (isDeleteLoading || isSolarPanelsLoading) {
    return <UILoadingIndicator />;
  }

  if (isDeleteError || isSolarPanelsError) {
    return <UIErrorIndicator />;
  }

  const hasPanels = section.solar_panel_amount > 0;
  const formatSectionInfo = () => {
    return t('calculation.pages.sections.singleSection.info')
        .replace('{panel}', section.solar_panel_amount)
        .replace('{effect}', section.power_kW.toFixed(2))
    ;
  };

  return (
    <div className='relative'>
      <div className='w-full flex justify-between py-3 border-b border-slate-200 items-center pl-4 pr-4 select-none'
        onClick={() => setToggled(!toggled)}
      >
        <div className='text-xs font-semibold items-center flex'>
          {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> : <FiChevronUp className='h-6 w-6 ml-2'/>}
        </div>
        <div className='text-xs font-semibold text-center'>
          <div className={`${!toggled && !hasPanels && 'text-red-500 '} font-bold text-md`}>
            {t('calculation.pages.sections.singleSection.title')
                .replace('{index}', String(index + 1))
            }
          </div>
          { hasPanels &&
            <div>
              {formatSectionInfo()}
            </div>
          }
          {mutateUpdateLoading && <UILoadingIndicatorSmall/>}
        </div>
        <div className='flex items-end'>
          <FaRegTimesCircle
            className='text-red-600 w-6 h-6 object-contain rounded-full bg-[#FFF5F5] cursor-pointer ml-2'
            onClick={() => mutateDelete()}
          />
        </div>
      </div>
      {toggled && (
        <div className='w-full flex p-6 mt-6 justify-between space-x-4'>
          <div className='w-1/3'>
            {isSectionError &&
              <UIErrorIndicator
                message={
                  sectionStatusError?.error || t('generic.form.invalid')!
                }
              />
            }
            <div className='w-full flex flex-wrap'>
              {sectionForm.map((el: any) => {
                const name = el.name as keyof typeof section;
                if (el.type == 'number') {
                  return (
                    <div key={el.id} className={`w-${el.width} px-2`}>
                      <UIInput
                        type={el.type}
                        label={t(`calculation.pages.sections.singleSection.form.${el.name}`)!}
                        name={el.name}
                        min={el.min}
                        max={el.max}
                        step={el.step || 1}
                        value={section[name] && section[name]}
                        placeholder={el.placeholder}
                        onChange={onChange}
                      />
                    </div>
                  );
                } else if (el.type === 'text') {
                  return (
                    <div key={el.id} className={`w-${el.width} px-2`}>
                      <UIInput
                        type='text'
                        label={t(`calculation.pages.sections.singleSection.form.${el.name}`)!}
                        name={el.name}
                        placeholder={el.placeholder}
                        value={section[name] && (section[name] as string)}
                        onChange={onChange}
                      />
                    </div>
                  );
                } else if (el.type === 'modal') {
                  return (
                    <div
                      key={el.id}
                      className={`relative w-${el.width} mb-3 px-2`}
                    >
                      <div className='flex items-center'>
                        {el.required && !section[name] && <span className='text-red-500 mr-2'>*</span>}
                        <label
                          className='block text-[#696969] text-xs font-bold mb-2 uppercase'
                          dangerouslySetInnerHTML={{__html: el.label}}
                        />
                      </div>
                      <button
                        className={
                          `border border-[#F0F1F7] px-3 py-3 text-[#6B6B6B] ` +
                          `bg-[#FCFDFE] rounded-lg text-sm focus:outline-none focus:ring w-full ` +
                          `ease-linear transition-all duration-150 flex justify-between`
                        }
                        type='button'
                        onClick={() => setIsOpened(!isOpened)}
                      >
                        <span
                          className='text-sm'
                          dangerouslySetInnerHTML={{__html: section.solar_panel ?
                            filterSolarPanels(section.solar_panel) : el.label}}
                        />
                        <FiPlusCircle className='h-5 w-5 ml-2' />
                      </button>
                    </div>
                  );
                } else if (el.type === 'select') {
                  const key: string = el.name;
                  return (
                    <div key={el.id} className={`w-${el.width} px-2`}>
                      <UIDropdownSelect
                        label={t(`calculation.pages.sections.singleSection.form.${el.name}`)}
                        value={section[name] ? filterForName(key) : el.label}
                        required={!section[name] && el.required}
                        readonly={el.readonly && el.readonly}
                      >
                        {(el.name === 'panel_orientation' || el.name === 'scene_orientation') &&
                          el.items.map((item: any) => (
                            <UIDropdownSelectItem
                              key={`${item.label}-${item.value}`}
                              label={
                              t(`calculation.pages.sections.singleSection.form.orientationOptions.${item.value}`)!
                              }
                              value={item.value}
                              onClick={() =>
                                setSelectedValue(el.name, item.value)
                              }
                            />
                          ))}
                        {el.name === 'color' &&
                          colors &&
                          colors.map((item: any) => (
                            <UIDropdownSelectItem
                              key={`${item.id}-${item.name}`}
                              label={item.name}
                              value={item.id}
                              onClick={() => setSelectedValue(el.name, item.id)}
                            />
                          ))}
                        {el.name === 'roof_type' &&
                          roofTypes &&
                          roofTypes.map((item: any) => (
                            <UIDropdownSelectItem
                              key={`${item.id}-${item.name}`}
                              label={item.name}
                              value={item.id}
                              onClick={() => setSelectedValue(el.name, item.id)}
                            />
                          ))}
                        {el.name === 'mount_producer' &&
                          mountProducers &&
                          mountProducers
                              .filter((item: any) =>item.roof_type.includes(section.roof_type))
                              .map((item: any) => (
                                <UIDropdownSelectItem
                                  key={`${item.id}-${item.name}`}
                                  label={item.name}
                                  value={item.id}
                                  onClick={() =>
                                    setSelectedValue(el.name, item.id)
                                  }
                                />
                              ))}
                      </UIDropdownSelect>
                    </div>
                  );
                } else if (el.type === 'checkbox') {
                  return (
                    <div
                      key={el.name}
                      className='w-full flex pb-6 mt-2 px-2'
                    >
                      <UICheckbox
                        label={t(`calculation.pages.sections.singleSection.form.${el.name}`)}
                        name={el.name}
                        onChange={handleCheckbox}
                        checked={section[el.name]}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <CalculationSectionGrid
            isVerticalOrientation={section.panel_orientation === 'vertical'}
            section={section}
            index={index}
            sections={sections}
            setSections={setSections}
          />
        </div>
      )}
      {isOpened && (
        <SolarPanelsModal
          solarPanels={solarPanels}
          setSolarPanel={setSolarPanel}
          closeModal={setIsOpened}
        />
      )}
    </div>
  );
};

export default CalculationSingleSection;
