import React, {FC} from 'react';
import UICard from './UICard';

type UISummaryTableProps= {
  rows: any;
};

const UISummaryTable: FC<UISummaryTableProps> = ({
  rows,
}) => {
  return (
    <div className='w-1/2 ml-auto'>
      <UICard style='max-h-[60%] relative h-full overflow-y-scroll py-2'>
        {Object.keys(rows).map((row: any, idx: number) => (
          <div
            key={idx}
            className={`flex justify-between ${idx > 0 ? 'border-t' : ''}
             border-[#EBEBF3] text-sm font-semibold py-1 px-4 hover:bg-gray-100`}
          >
            <div className='w-1/2 px-2'>
              {row}
            </div>
            <div className='w-1/2 px-2 text-right'>
              {rows[row]}
            </div>
          </div>
        ))}
      </UICard>
    </div>
  );
};

export default UISummaryTable;
