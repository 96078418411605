import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {customerPropertySelect} from '../../../../config/customerForm';
import CustomerTypeFieldType from '../../../../types/CustomerTypeFieldType';
import UIInput from '../../../UICommon/UIInput';
import UIDropdownSelectItem from '../../../UICommon/UIDropdownSelectItem';
import UIDropdownSelect from '../../../UICommon/UIDropdownSelect';
import UIPaginationButtons from '../../../UICommon/UIPaginationButtons';

type CustomerPropertyFormProps = {
  formValues: any;
  formErrors: any;
  onChange: Function;
  nextTab: Function;
};

const CustomerPropertyForm: FC<CustomerPropertyFormProps> = ({
  formValues,
  formErrors,
  onChange,
  nextTab,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className='grid grid-cols-2 gap-x-12 gap-y-4 pb-6'>
        <h2 className='col-span-2 text-lg font-semibold mb-2'>
          {t('calculation.pages.contact.tabs.property.title')}
        </h2>
        <UIInput
          type='text'
          label={t('calculation.pages.contact.tabs.property.form.property_designation')!}
          name='property_designation'
          placeholder='Gävle Olsbacka 11:1'
          value={
            formValues.property_designation &&
                formValues.property_designation
          }
          formErrorMessage={
            formErrors.property_designation &&
                formErrors.property_designation
          }
          onChange={(e: any) => onChange(e.target.name, e.target.value)}
        />
        <div>
          <UIDropdownSelect
            label={t('calculation.pages.contact.tabs.property.form.property_service_fuse')}
            value={
                  formValues.property_service_fuse ?
                    formValues.property_service_fuse :
                    t('calculation.pages.contact.tabs.property.form.property_service_fuse')
            }
          >
            {customerPropertySelect.map((el: CustomerTypeFieldType) => (
              <UIDropdownSelectItem
                key={el.id}
                label={el.label}
                value={el.value}
                onClick={() => onChange('property_service_fuse', el.value)}
              />
            ))}
          </UIDropdownSelect>
        </div>
      </div>
      {formValues.type === 'private person' &&
        <UIInput
          type='text'
          label={t('calculation.pages.contact.tabs.property.form.owners')!}
          name='owners'
          placeholder='1'
          value={formValues.owners && formValues.owners}
          onChange={(event: any) => onChange(event.target.name, event.target.value)}
          formErrorMessage={formErrors.owners && formErrors.owners}
        />
      }
      <UIPaginationButtons
        nextPage={nextTab}
        prevPage={() => nextTab(false)}
      />
    </>
  );
};

export default CustomerPropertyForm;
