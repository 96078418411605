import {useMutation} from 'react-query';
import actions from '../../api/quotes';


const useDeleteAttachment = () => {
  return useMutation(async (id: number) => {
    const data = await actions.deleteAttachment(id);
    return data;
  });
};

export default useDeleteAttachment;
