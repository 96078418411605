import {FC} from 'react';
import {Link} from 'react-router-dom';
import {useTable} from 'react-table';
import UITableSortingArrows from './UITableSortingArrows';

type UITableProps = {
  data: any;
  columns: any;
  sorting: any;
  setSorting: Function;
  link?: string;
  onClick?: Function;
};

const UITable: FC<UITableProps> = ({
  data,
  columns,
  sorting,
  setSorting,
  link,
  onClick,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({columns, data});
  return (
    <div className='flex-auto px-0 pt-0 pb-2'>
      <div className='p-0 overflow-x-auto'>
        <table {...getTableProps()}
          className='items-center w-full mb-0 align-top border-gray-200 text-slate-500 table-scroll'
        >
          <thead className='align-bottom'>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`cell-${index}`}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={`heading-${i}-${index}`}
                    onClick={() => setSorting(column.id)}
                    className='px-3 py-3 text-left align-middle bg-transparent
                    border-b border-gray-200 shadow-none text-xs border-b-solid
                    tracking-none whitespace-nowrap text-slate-400 opacity-70
                    cursor-pointer uppercase select-none'
                  >
                    <div className="relative inline-block">
                      {column.render('Header')}
                      <UITableSortingArrows
                        field={column.id}
                        activeField={sorting.field}
                        type={sorting.type}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, j: number) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`row-${j}`}
                  className="cursor-pointer bg-white hover:bg-slate-100"
                >
                  {row.cells.map((cell: any, k: number) => (
                    <td
                      {...cell.getCellProps()}
                      key={`cell-${k}-${j}`}
                      className='align-middle bg-transparent border-b whitespace-nowrap
                       shadow-transparent capitalize text-sm'
                    >
                      {cell.column.id === 'status' ? (
                          <>
                            {cell.render('Cell')}
                          </>
                      ) : onClick ? (
                        <p className='mb-0 p-4 block' onClick={() => onClick(row.original.id)}>
                          {cell.render('Cell')}
                        </p>
                      ) : (
                        <Link to={`/${link}/${row.original.id}`} className='mb-0 p-4 block'>
                          {cell.render('Cell')}
                        </Link>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UITable;
