import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {getPositiveNumber} from '../../../utils/getPositiveNumber';
import {toCurrency} from '../../../utils/toCurrency';

import useAddQuoteCost from '../../../hooks/quoteCosts/useAddQuoteCost';
import useUpdateQuoteCost from '../../../hooks/quoteCosts/useUpdateQuoteCost';
import useMutateDeleteQuoteCost from '../../../hooks/quoteCosts/useMutateDeleteQuoteCost';

import {calculationQuoteState} from '../../../state/calculation/calculation';

import UICard from '../../UICommon/UICard';
import CalculationMontageTableItem from './CalculationMontageTableItem';
import UIAddButton from '../../UICommon/UIAddButton';
import UIErrorIndicator from '../../UICommon/UIErrorIndicator';
import UIFoldTab from '../../UICommon/UIFoldTab';
import AddCustomCostModal from '../../Modals/AddCustomCostModal';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';


type CalculationMontageTableProps = {
  fetchQuote: Function,
};

const CalculationMontageTable: FC<CalculationMontageTableProps> = ({
  fetchQuote,
}) => {
  const {t} = useTranslation();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const quote = useRecoilValue(calculationQuoteState);
  const categories: any = {};
  const [isModal, setModal] = useState(false);
  const [isProductCost, setCurrentCost] = useState({});

  quote.costs.forEach((cost: any) => {
    if (cost.category in categories) {
      categories[cost.category] += cost.cost;
    } else {
      categories[cost.category] = cost.cost;
    }
  });

  const {
    isLoading: isCreateCostLoading,
    isSuccess: isCreateCostSuccess,
    mutate: mutateAddCost,
  } = useAddQuoteCost();

  const {
    isLoading: isDeleteCostLoading,
    isSuccess: isDeleteCostSuccess,
    mutate: mutateDeleteCost,
  } = useMutateDeleteQuoteCost();

  const {
    mutate: mutateUpdateQuoteCost,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateQuoteCost();

  useEffect(() => {
    if (quote.costs.length > 0) {
      let price = 0;
      quote.costs.forEach((el: any) => {
        price += getPositiveNumber(el.cost);
      });
      setTotalPrice(price);
    }
  }, [quote]);

  useEffect(() => {
    if (isUpdateSuccess || isDeleteCostSuccess || isCreateCostSuccess) {
      fetchQuote();
    }
  }, [isUpdateSuccess, isDeleteCostSuccess, isCreateCostSuccess]);

  const createCost = (action: string, data: any) => {
    switch (action) {
      case 'add': mutateAddCost(data); break;
      case 'delete': mutateDeleteCost(data.id); break;
      case 'edit': mutateUpdateQuoteCost(data); break;
    }
    onModalClose();
  };

  const onCostSelect = (cost: any) => {
    setCurrentCost(cost);
    setModal(true);
  };

  const onModalClose = () => {
    setCurrentCost({});
    setModal(false);
  };

  const loading = (isUpdateLoading || isCreateCostLoading || isDeleteCostLoading);

  return (
    <div className="w-full">
      {isModal &&
       <AddCustomCostModal
         onClose={() => onModalClose()}
         onSubmit={(action: string, data: any) => createCost(action, data)}
         customCost={isProductCost}
       />}
      {isUpdateError &&
       <UIErrorIndicator
         message={t('generic.form.invalid')!}
       />
      }
      {loading &&
        <UILoadingIndicatorSmall/>
      }
      <UICard style='w-full h-full my-6'>
        <div className='flex justify-between border-b border-[#EBEBF3] text-sm font-semibold py-2 px-4'>
          <div className='w-1/2 px-2'>
            {t('calculation.pages.montage.table.description')}
          </div>
          <div className='w-1/2 px-2 text-right'>
            {t('calculation.pages.montage.table.sum')}
          </div>
        </div>
        <div
          className='flex flex-col space-y-2 w-full justify-between p-2 bg-gay-50'
        >
          {Object.keys(categories)
              .sort((a, b) => categories[a] > categories[b] ? -1 : 1)
              .map((cat: any, idx: number) => (
                <UIFoldTab
                  title={t(`calculation.pages.montage.table.categories.${cat}`)}
                  subTitle={toCurrency(categories[cat])}
                  key={idx}
                >
                  {quote.costs.filter((cost: any) => cost.category === cat)
                      .map((el: any) => (
                        <CalculationMontageTableItem
                          key={el.id}
                          description={el.description}
                          cost={el.cost}
                          onClick={() => onCostSelect(el)}
                        />
                      ))}
                </UIFoldTab>
              ))}
        </div>
        <div
          className='flex justify-between text-sm py-1 border-t border-[#EBEBF3] px-4'
        >
          <div className='w-1/4 px-2 py-1 font-bold'>
            {t('calculation.financial.sumExTAX')}
          </div>
          <div className='w-1/4 px-2 py-1 text-right font-bold'>
            {toCurrency(totalPrice)}
          </div>
        </div>
      </UICard>
      <div className='w-full overflow-y-hidden'>
        <UIAddButton
          onClick={() => setModal(true)}
          label={t('calculation.pages.montage.addCostButton')}
        />
      </div>
    </div>
  );
};

export default CalculationMontageTable;
