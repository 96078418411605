import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {quoteAttachments} from '../../../../state/quotes/quotes';
import {calculationQuoteState} from '../../../../state/calculation/calculation';
import useCreateAttachment from '../../../../hooks/quotes/useCreateAttachment';
import useFetchAttachments from '../../../../hooks/quotes/useFetchAttachments';
import useDeleteAttachment from '../../../../hooks/quotes/useDeleteAttachment';
import UIPaginationButtons from '../../../UICommon/UIPaginationButtons';
import UILoadingIndicator from '../../../UICommon/UILoadingIndicator';
import UIErrorIndicator from '../../../UICommon/UIErrorIndicator';
import UIAttachmentDetails from '../UIAttachmentDetails';
import AddAttachmentModal from '../../../Modals/AddAttachmentModal';
import UIAddButton from '../../../UICommon/UIAddButton';


type CustomerAttachmentsFormProps = {
    nextTab: Function;
};

const CustomerAttachmentsForm: FC<CustomerAttachmentsFormProps> = (
    {
      nextTab,
    }) => {
  const {t} = useTranslation();
  const [addAttachments, setAddAttachments] = useState(false);
  const quote = useRecoilValue(calculationQuoteState);

  const {
    isLoading: isFetchLoading,
    isError: isFetchError,
    refetch: refetchAttachments,
  } = useFetchAttachments(quote.id!);
  const {
    mutate: mutateCreateAttachment,
    isError: isCreateError,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
  } = useCreateAttachment();
  const {
    mutate: mutateDeleteAttachment,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
  } = useDeleteAttachment();

  const attachments = useRecoilValue(quoteAttachments);
  useEffect(() => {
    refetchAttachments();
  }, [isCreateSuccess, isDeleteSuccess]);
  return (
    <>
      {isFetchLoading || isCreateLoading || isDeleteLoading &&
        <UILoadingIndicator/>
      }
      {isFetchError || isCreateError || isDeleteError &&
          <UIErrorIndicator/>
      }
      <div
        className='w-full flex flex-col h-full space-y-4'
      >
        <h2
          className='col-span-2 text-lg font-semibold mb-2'
        >
          {t('calculation.pages.contact.tabs.documents.title')}
        </h2>
        <div className='space-y-2'>
          {attachments.map((el: any, idx) => (
            <UIAttachmentDetails
              key={idx}
              attachment={el}
              onDelete={() => mutateDeleteAttachment(el.id)}
            />
          ))}
        </div>
        {addAttachments &&
            <AddAttachmentModal
              onClose={() => setAddAttachments(false)}
              onSubmit={(mutateCreateAttachment)}
            />
        }
        <div className='w-full flex mb-4'>
          <UIAddButton
            onClick={() => setAddAttachments(true)}
            label={t('calculation.pages.contact.tabs.documents.addButton')}
          />
        </div>
        <UIPaginationButtons
          nextPage={nextTab}
          prevPage={() => nextTab(false)}
        />
      </div>
    </>
  );
};

export default CustomerAttachmentsForm;
