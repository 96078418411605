import React, {FormEvent, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import actions from '../api/resetPassword';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UIImage from '../components/UICommon/UIImage';
import UISubmit from '../components/UICommon/UISubmit';

import img from '../assets/logo.svg';
import UIInput from '../components/UICommon/UIInput';

const SetNewPasswordPage: React.FC = () => {
  const initialValues = {success: false, error: false, errorMessage: '', confirmed: false};
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({password: '', confirmPassword: ''});
  const [status, setStatus] = useState(initialValues);

  const {token} = useParams<string>();

  useEffect(() => {
    if (token != null) {
      const state = {...status};
      actions.confirmPasswordReset(token).then((res: any) => {
        state.confirmed = true;
        setStatus(state);
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }, [token]);

  const onChange = (event: any) => {
    const {name, value} = event.target;
    setFormValues({...formValues, [name]: value});
  };

  const submit = (event: FormEvent) => {
    event.preventDefault();
    const state = {...status};

    if (!formValues.password) {
      state.errorMessage = ('Ange ditt lösenord');
    } else if (!formValues.confirmPassword) {
      state.errorMessage = ('Bekräfta ditt lösenord');
    } else if (formValues.password !== formValues.confirmPassword) {
      state.errorMessage = ('Lösenord matchar inte');
    }

    if (!state.errorMessage && token != null) {
      setLoading(true);
      actions.setNewPassword(token, formValues.password!).then(() => {
        state.success = true;
      }).catch((error) => {
        state.error = true;
      }).finally(() => {
        setLoading(false);
        setStatus(state);
      });
    } else {
      setStatus(state);
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-[445px] px-4">
          <div className={`relative flex flex-col min-w-0 break-words w-full ` +
            `mb-6 shadow-lg rounded-lg bg-slate-200 border-0`}>
            <div className="flex-auto px-4 lg:px-10 py-10">
              <UIImage url={img} style='w-auto mx-auto mb-5'/>
              {loading && <UILoadingIndicator/>}
              {status.error && <UIErrorIndicator/>}
              <div className='py-5 text-center'>
                <h1 className='text-xl mb-2 font-semibold'>Återställ ditt lösenord.</h1>
                <h2 className='text-sm'>Fyll i ditt nya lösenord för att återställa.</h2>
              </div>
              { status.confirmed && !status.success ? (
                <form className='flex flex-col h-full' onSubmit={(e) => submit(e)}>
                  <div className='pb-8'>
                    <UIInput
                      variant="white"
                      type="password"
                      label="Lösenord"
                      name='password'
                      onChange={onChange}
                      placeholder='Lösenord'
                      formErrorMessage={status.errorMessage}/>
                    <UIInput
                      variant="white"
                      type="password"
                      label="Bekräfta lösenord"
                      name='confirmPassword'
                      onChange={onChange}
                      placeholder='Bekräfta lösenord'
                      formErrorMessage={status.errorMessage}/>
                  </div>
                  <UISubmit
                    label="Spara nytt lösenord"
                    onClick={submit}
                  />
                </form>
              ) : status.success ? (
                <div className='text-green-700 text-center font-semibold'>
                    Tack! Vi har nu återställt ditt lösenord.</div>
              ) : (
                <div className='text-red-700 text-center font-semibold'>
                    Länken har löpt ut eller så är den fel.</div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap mt-6 relative">
            <div className="w-1/2">
              <Link
                to="/"
                className="text-slate-200"
              >
                <small>Gå tillbaka</small>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPasswordPage;
