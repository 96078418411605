import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/genericProducts';
import {genericProductsArray} from '../../state/products/genericProducts';

const useFetchGenericProducts = () => {
  const setGenericProducts = useSetRecoilState(genericProductsArray);
  return useMutation('fetchGenericProducts', async () => {
    const res = await actions.fetchGenericProducts();
    setGenericProducts(res.data);
    return res.data;
  });
};

export default useFetchGenericProducts;
