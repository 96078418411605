import React, {FC} from 'react';

type UIUnderlineButtonProps = {
  label: string;
  onClick: Function;
  disabled?: boolean;
  toolTip?: string;
};

const UIUnderlineButton: FC<UIUnderlineButtonProps> = ({
  label,
  onClick,
  disabled,
  toolTip,
}) => {
  return (
    <button
      className={
        `text-xs underline text-center py-3 px-16 outline-none
         ease-linear transition-all duration-150 w-60'
        ${disabled ? 'hover:cursor-not-allowed opacity-75' : 'focus:outline-none'}`
      }
      type='button'
      onClick={() => onClick()}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default UIUnderlineButton;
