import {FC} from 'react';
import {FaSortDown, FaSortUp} from 'react-icons/fa';

type UITableSortingArrowsProps = {
  field: string;
  activeField: string;
  type: string;
};

const UITableSortingArrows: FC<UITableSortingArrowsProps> = ({
  field,
  activeField,
  type,
}) => {
  return (
    <>
      <FaSortUp
        className={`absolute top-0 -right-4 ${type === 'desc' && field === activeField && 'text-black'}`}
      />
      <FaSortDown
        className={`absolute top-1 -right-4 ${type === 'asc' && field === activeField && 'text-black'}`}
      />
    </>
  );
};

export default UITableSortingArrows;
