import React from 'react';
import {FaRegTimesCircle} from 'react-icons/fa';

type UIAttachmentDetailsProps = {
  attachment: any,
  onDelete: Function,
};

const UIAttachmentDetails: React.FC<UIAttachmentDetailsProps> = ({
  attachment,
  onDelete,
}) => {
  return (
    <div
      className='w-full rounded-lg shadow border py-3 px-6 flex justify-between'
    >
      <div>
        <div
          className='text-xl font-semibold opacity-80'
        >
          {attachment.title}
        </div>
        <a
          className='underline text-blue-500'
          href={attachment.file} download={attachment.file}
          target='_blank'
          rel="noreferrer"
        >
          {attachment.file}
        </a>
      </div>
      <div className='flex items-center mr-4'>
        <FaRegTimesCircle
          className='text-red-600 w-6 h-6 object-contain rounded-full bg-[#FFF5F5] cursor-pointer ml-2'
          onClick={() => onDelete()}
        />
      </div>
    </div>
  );
};

export default UIAttachmentDetails;
