import _ from 'lodash';
import {atom, selector} from 'recoil';
import {customersPageState, customersRowsPerPage} from './customersPages';
import SortingType from '../../types/SortingType';
import CustomerType from '../../types/CustomerType';

export const customersArray = atom({
  key: 'customersArray',
  default: null,
});

export const singleCustomer = atom({
  key: 'singleCustomer',
  default: {
    type: 'private person',
    owners: 1,
  } as CustomerType,
});

export const customersSortState = atom({
  key: 'customersSortState',
  default: {
    field: '',
    type: '',
  },
});

export const customersFilters = atom({
  key: 'customersFilters',
  default: {
    status: null,
    user: null,
  },
});

export const customersSetSort = selector({
  key: 'customersSetSort',
  get: ({get}) => get(customersSortState),
  set: ({get, set, reset}, key: any) => {
    const sort: SortingType = get(customersSortState);
    if (sort.field === key) {
      if (sort.type === '') {
        set(customersSortState, {
          field: key,
          type: 'asc',
        });
      }

      if (sort.type === 'asc') {
        set(customersSortState, {
          field: key,
          type: 'desc',
        });
      }

      if (sort.type === 'desc') {
        reset(customersSortState);
      }
    } else {
      set(customersSortState, {
        field: key,
        type: 'asc',
      });
    }
    set(customersPageState, 1);
  },
});

export const customersFiltered = selector({
  key: 'customersFiltered',
  get: ({get}) => {
    const customers: any = get(customersArray);
    const filters: any = get(customersFilters);
    if (customers == null) {
      return [];
    }
    let tempCustomers: any = customers;
    Object.keys(filters).forEach((k) => {
      if (filters[k] != null) {
        tempCustomers = tempCustomers.filter(filters[k]);
      }
    });
    return tempCustomers;
  },
});

export const customersSorted = selector({
  key: 'customersSorted',
  get: ({get}) => {
    const customers = get(customersFiltered);
    const sort: any = get(customersSortState);
    if (customers == null) {
      return [];
    }

    if (!sort) {
      return customers;
    }

    return _.orderBy(customers, [sort.field], [sort.type]);
  },
});

export const customersPaginated = selector({
  key: 'customersPaginated',
  get: ({get}) => {
    const customers = get(customersSorted);
    const page = get(customersPageState);
    const rowsPerPage = get(customersRowsPerPage);
    if (customers == null) {
      return [];
    }

    const lastRowIdx = page * rowsPerPage;
    const firstRowIdx = lastRowIdx - rowsPerPage;
    return customers.slice(firstRowIdx, lastRowIdx);
  },
});
