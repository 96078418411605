import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Sections
 */

const URL = 'sections/';

const addNewSection = async (data: any) => {
  const res = await client.post(URL, data);
  return res.data;
};

const updateSection = async (data: any, id: number) => {
  return await client.put(URL + id + '/', data);
};

const deleteSection = async (id: number) => {
  const res = await client.delete(URL + id + '/');
  return res.data;
};

const actions = {
  addNewSection,
  updateSection,
  deleteSection,
};

export default actions;
