import _ from 'lodash';
import {atom, selector} from 'recoil';
import SortingType from '../../types/SortingType';

export const solarPanelsArray = atom({
  key: 'solarPanelsArray',
  default: null,
});

export const solarPanelsSortState = atom({
  key: 'solarPanelsSortState',
  default: {
    field: '',
    type: '',
  },
});

export const solarPanelsSetSort = selector({
  key: 'solarPanelsSetSort',
  get: ({get}) => get(solarPanelsSortState),
  set: ({get, set}, key: any) => {
    const sort: SortingType = get(solarPanelsSortState);
    if (sort.field === key) {
      if (sort.type === 'asc') {
        set(solarPanelsSortState, {
          field: key,
          type: 'desc',
        });
      } else {
        set(solarPanelsSortState, {
          field: key,
          type: 'asc',
        });
      }
    } else {
      set(solarPanelsSortState, {
        field: key,
        type: 'asc',
      });
    }
  },
});

export const solarPanelsSorted = selector({
  key: 'solarPanelsSorted',
  get: ({get}) => {
    const solarPanels = get(solarPanelsArray);
    const sort: any = get(solarPanelsSortState);
    if (solarPanels == null) {
      return [];
    }

    if (!sort) {
      return solarPanels;
    }

    return _.orderBy(solarPanels, [sort.field], [sort.type]);
  },
});
