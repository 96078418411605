import {FC} from 'react';

type UIErrorIndicatorSmallProps = {
  message: string,
};
const UIErrorIndicatorSmall: FC<UIErrorIndicatorSmallProps> = ({
  message,
}) => {
  return (<div
    className='text-red-500'
  >
    {message}
  </div>);
};

export default UIErrorIndicatorSmall;
