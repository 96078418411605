import {useMutation} from 'react-query';
import actions from '../../api/products';


const useMutateCreateQuoteProduct = () => {
  return useMutation('useMutateCreateQuoteProduct', async (data: any) => {
    const res = await actions.addNewProduct(data);
    return res;
  });
};

export default useMutateCreateQuoteProduct;
