import {useMutation} from 'react-query';
import {useRecoilState, useRecoilValue} from 'recoil';
import actions from '../../api/quotes';
import {calculationMonthlyConsumptionFormState, calculationQuoteState} from '../../state/calculation/calculation';
import {isHavingNulls} from '../../utils/isHavingNulls';

const useAddMonthlyConsumption = () => {
  const [consumption, setConsumption] = useRecoilState(calculationMonthlyConsumptionFormState);
  const quote = useRecoilValue(calculationQuoteState);
  const isNull = isHavingNulls(consumption);
  return useMutation(async () => {
    if (quote.id !== null && !isNull) {
      const data = {...consumption};
      data.quote = quote.id;
      const res = await actions.addMonthlyConsumption(data);
      data.id = res.data.id;
      setConsumption(data);
      return res;
    }
  }, {retry: false});
};

export default useAddMonthlyConsumption;
