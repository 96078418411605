import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi';

type UITablePaginationProps = {
  totalPages: number;
  page: number;
  setPage: Function;
};

const UITablePagination: FC<UITablePaginationProps> = ({
  totalPages,
  page,
  setPage,
}) => {
  const {t} = useTranslation();
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    if (isNaN(currentPage)) {
      return;
    }

    const tmpPage = currentPage;
    if (tmpPage >= 1 && tmpPage <= totalPages) {
      setPage(tmpPage);
    }
  }, [currentPage, setPage, totalPages]);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(page - 1);
    };
  };

  const nextPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(page + 1);
    };
  };

  return (
    <div className="flex items-center justify-center my-3">
      <button onClick={() => previousPage()}>
        <FiChevronLeft className="text-2xl"/>
      </button>
      <div className="mx-4 flex text-sm">
        <div>{t('generic.tables.pages.page')} </div>
        <input
          className="w-9 mx-2 text-center border rounded-md border-gray-300"
          type="number"
          value={page}
          onChange={(e) => setCurrentPage(parseInt(e.target.value))}
        />
        <div> {t('generic.tables.pages.of')} {totalPages}</div>
      </div>
      <button onClick={() => nextPage()}>
        <FiChevronRight className="text-2xl"/>
      </button>
    </div>
  );
};

export default UITablePagination;
