import {useMutation} from 'react-query';
import {useSetRecoilState} from 'recoil';
import {projectHistory} from '../../state/projectManagement/projectManagement';
import actions from '../../api/projectManagement';


const useMutateFetchProjectHistory = () => {
  const setHistory = useSetRecoilState(projectHistory);
  return useMutation('useMutateFetchProjectHistory', async (projectId: number) => {
    const res = await actions.fetchProjectHistory(projectId);
    setHistory(res.data);
    return res.data;
  });
};

export default useMutateFetchProjectHistory;

