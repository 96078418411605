import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FaTimes} from 'react-icons/fa';
import {useNavigate} from 'react-router';
import UILargeButton from '../UICommon/UILargeButton';

type OffertSaveTemplateModalProps = {
  closeModal: Function;
}

const QuoteSaveTemplateModal: FC<OffertSaveTemplateModalProps> = ({closeModal}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const redirectHomePage = () => {
    closeModal(false);
    navigate('/');
  };

  const close = () => {
    closeModal(false);
  };

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-30 items-center justify-center h-full max-h-screen w-full'>
      <div className='py-5 shadow rounded-2xl bg-white relative w-1/2 px-8 max-h-[80%] overflow-y-scroll'>
        <FaTimes className='w-5 h-5 absolute top-5 right-5 cursor-pointer'
          onClick={() => closeModal(false)}/>
        <div className='text-lg font-semibold mb-2 px-4 text-center'>
          {t('calculation.pages.quote.createTemplateModal.title')}
        </div>
        <div className="flex items-center justify-center mt-4">
          <div className="text-center mx-2">
            <UILargeButton
              label={t('calculation.pages.quote.createTemplateModal.redirectToStart')}
              width='w-auto'
              onClick={redirectHomePage}
            />
          </div>
          <div className="text-center mx-2">
            <UILargeButton
              label={t('calculation.pages.quote.createTemplateModal.close')}
              width='w-auto'
              onClick={close}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteSaveTemplateModal;
