import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Colors
 */

const URL = 'mounts/colors/';

const fetchColors = async () => {
  return client.get(URL);
};

const actions = {
  fetchColors,
};

export default actions;
