import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {ReactSVG} from 'react-svg';
import UICard from '../../UICommon/UICard';
import {months} from '../../../config/constants';

type CalculationStatisticsProductionGraphProps = {
  url: string;
  productData: any;
};

const CalculationStatisticsProductionGraph: FC<CalculationStatisticsProductionGraphProps> = (
    {
      url,
      productData,
    }) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(false);

  return (
    <UICard style='w-full h-full mb-6'>
      <div className="w-full flex justify-between items-center select-none p-4" onClick={() => setToggled(!toggled)}>
        <h2 className={`font-semibold text-sm text-[#1E1E1E] ${toggled && 'mb-4'}`}>
          {t('calculation.pages.statistics.graphs.production.title')}
        </h2>
        {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> : <FiChevronUp className='h-6 w-6 ml-2'/>}
      </div>
      <>
        {toggled && <div className=' p-4 flex-col px-20'>
          <div className='flex w-full justify-center'>
            {url && <ReactSVG src={url} useRequestCache={false}/>}
          </div>
          <table className="border-collapse w-full px-10 text-right">
            <thead>
              <tr>
                <th></th>
                <th className="bg-gray">
                  {t('calculation.pages.statistics.graphs.production.headers.soldProduction')}
                </th>
                <th className="bg-gray">
                  {t('calculation.pages.statistics.graphs.production.headers.ownConsumption')}
                </th>
                <th className="bg-gray">
                  {t('calculation.pages.statistics.graphs.production.headers.sum')}
                </th>
                <th className="bg-gray">
                  {t('calculation.pages.statistics.graphs.production.headers.topEffect')}
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {
                  months.map((month, index) => {
                    return (
                      <tr key={index} className='border-b border-gray-200 hover:bg-gray-100'>
                        <td className="font-bold text-left">
                          {t(`calculation.pages.statistics.months.${month}`)}
                        </td>
                        <td>{Math.round(productData.sold_production[index])} kWh</td>
                        <td>{Math.round(productData.own_consumption[index])} kWh</td>
                        <td>{Math.round(productData.sold_production[index] +
                            productData.own_consumption[index])} kWh
                        </td>
                        <td>{productData.top_effect[index].toFixed(1)} kW</td>
                      </tr>
                    );
                  })
                }
              </>
            </tbody>
          </table>
        </div>}
      </>
    </UICard>
  );
};

export default CalculationStatisticsProductionGraph;
