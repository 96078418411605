import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {format} from 'date-fns';
import {sv} from 'date-fns/locale';
import {FiSliders} from 'react-icons/fi';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';

import CurrentUserType from '../types/CurrentUserType';
import {homeQuotesTemplatesColumns} from '../config/constants';
import {customerStatusSelect} from '../config/customerForm';

import {currentUser} from '../state/user/currentUser';
import {userCustomersSetSort, userCustomersSorted} from '../state/customers/userCustomers';
import {quotesTemplatesSetSort, quotesTemplatesSorted} from '../state/quotes/quotesTemplates';

import useResetCalculation from '../hooks/quotes/useResetCalculation';
import useUpdateCustomerStatus from '../hooks/customers/useUpdateCustomerStatus';
import useFetchCustomers from '../hooks/customers/useFetchCustomers';
import useFetchQuotes from '../hooks/quotes/useFetchQuotes';

import UICard from '../components/UICommon/UICard';
import UISmallCard from '../components/UICommon/UISmallCard';
import UITable from '../components/UITable/UITable';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UIButton from '../components/UICommon/UIButton';
import CustomersModal from '../components/Modals/CustomersModal';
import {singleCustomer} from '../state/customers/customers';

const HomePage: FC = () => {
  const {t} = useTranslation();
  const [customersSorting, setCustomersSorting] = useRecoilState(userCustomersSetSort);
  const [quotesTemplatesSorting, setQuotesTemplatesSorting] = useRecoilState(quotesTemplatesSetSort);
  const customers = useRecoilValue(userCustomersSorted);
  const quotesTemplates = useRecoilValue(quotesTemplatesSorted);
  const user: CurrentUserType = useRecoilValue(currentUser);
  const [customersModal, setCustomersModal] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading: isCustomersLoading,
    isError: isCustomersError,
  } = useFetchCustomers();
  const {
    isLoading: isQuotesLoading,
    isError: isQuotesError,
  } = useFetchQuotes();
  const {
    mutate: updateCustomerStatus,
  } = useUpdateCustomerStatus();
  const {
    mutate: mutateResetCalculation,
    isLoading: isCalculationResetLoading,
    isSuccess: isQuoteResetSuccess,
  } = useResetCalculation();
  const resetCustomer = useResetRecoilState(singleCustomer);

  const customersColumns = [
    {
      id: 'first_name',
      accessor: 'first_name',
      Header: 'Namn',
      sticky: 'top',
      Cell: ({row}: any) => (row.original.first_name + ' ' + row.original.last_name),
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Skapad den',
      sticky: 'top',
      Cell: ({value}: any) => format(new Date(value.replace(/\s+/g, 'T')), 'yyyy/MM/dd', {locale: sv}),
    },
    {
      id: 'status',
      accessor: 'status',
      Header: 'Status',
      sticky: 'top',
      Cell: ({row, value}: any) => {
        return (
          <select
            value={value}
            onChange={(e) => updateCustomerStatus({
              customerId: row.original.id,
              status: e.target.value,
            })}
            className='p-2 rounded-md bg-white border border-gray-200'>
            {customerStatusSelect.map((el: any, key: number) => (
              <option key={key} value={el.value}>{el.label}</option>
            ))}
          </select>
        );
      }},
  ];

  const onCreateNewCustomer = () => {
    resetCustomer();
    mutateResetCalculation();
  };

  useEffect(() => {
    if (isQuoteResetSuccess) {
      navigate('/kalkyl/');
    }
  }, [isQuoteResetSuccess]);

  const pickCustomer = () => {
    setCustomersModal(true);
  };

  const editQuote = (id: number) => {
    navigate(`/kalkyl/${id}`);
  };

  if (isCustomersLoading || isQuotesLoading || isCalculationResetLoading) {
    return <UILoadingIndicator />;
  }

  if (isCustomersError || isQuotesError) {
    return <UIErrorIndicator/>;
  }


  return (
    <>
      <div className='w-full h-full flex flex-col'>
        <div className='w-full flex flex-col justify-end mb-6'>
          <div className='font-semibold text-6xl text-blue-primary font-outfit select-none'>
            {t('homePage.title').replace('{firstName}', user?.first_name)}
          </div>
          <div className='font-outfit text-3xl select-none'>
            {t('homePage.subTitle')}
          </div>
        </div>
        <UISmallCard
          title={t('homePage.calculation.title')}
          content={t('homePage.calculation.description')}
          buttonLabel={t('homePage.calculation.buttonLabel')}
          onClick={() => pickCustomer()}
          icon={<FiSliders className='text-2xl' />}
        />
        <div className='flex w-full justify-between space-x-5 h-full'>
          <UICard style='overflow-y-scroll w-full max-h-full relative pb-8 px-4 min-h-[615px]'>
            <div
              className='border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10 pt-8 pb-2'
            >
              <div className='flex items-start justify-between'>
                <div>
                  <h2 className='mb-3 font-semibold text-xl'>{t('homePage.customerList.title')}</h2>
                  <p className='text-sm'>{t('homePage.customerList.subTitle')}</p>
                </div>
                <div>
                  <Link
                    to='/kunder'
                    className='text-blue-primary uppercase underline text-sm font-bold mr-5'
                  >
                    {t('generic.buttons.viewAllElements')}
                  </Link>
                  <UIButton
                    label={t('homePage.customerList.buttonLabel')}
                    onClick={onCreateNewCustomer}
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-6">
              <UITable
                data={customers}
                columns={customersColumns}
                sorting={customersSorting}
                link='kunder'
                setSorting={setCustomersSorting}
              />
            </div>
          </UICard>
          <UICard style='overflow-y-scroll w-full max-h-full relative pb-8 px-4 min-h-[615px]'>
            <div
              className='border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10 pt-8 pb-2'
            >
              <div className='flex items-center justify-between'>
                <div>
                  <h2 className='mb-3 font-semibold text-xl'>{t('homePage.templates.list.title')}</h2>
                  <p className='text-sm'>{t('homePage.templates.list.subTitle')}</p>
                </div>
                <Link
                  to='/kalkyl/mallar'
                  className='text-blue-primary uppercase underline text-sm font-bold'
                >
                  {t('generic.buttons.viewAllElements')}
                </Link>
              </div>
            </div>
            <div className="w-full mt-6">
              <UITable
                data={quotesTemplates}
                columns={homeQuotesTemplatesColumns}
                sorting={quotesTemplatesSorting}
                onClick={editQuote}
                setSorting={setQuotesTemplatesSorting}
              />
            </div>
          </UICard>
        </div>
      </div>
      {customersModal &&
       <CustomersModal
         closeModal={() => setCustomersModal(false)}
       />
      }
    </>
  );
};

export default HomePage;
