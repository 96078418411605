import {FC} from 'react';

type UICardProps = {
  children: JSX.Element | JSX.Element[],
  style?: string;
};

const UICard: FC<UICardProps> = ({
  children,
  style,
}) => {
  return (
    <div
      className={
        `relative flex flex-col min-w-0 break-words bg-white border-0 shadow-md ` +
        `rounded-2xl bg-clip-border ${style && style}`
      }
    >
      {children}
    </div>
  );
};

export default UICard;
