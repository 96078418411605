import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/mountProducers';
import {mountProducersArray} from '../../state/products/mountProducers';

const useFetchMountProducers = () => {
  const setMountProducers = useSetRecoilState(mountProducersArray);
  return useQuery('fetchMountProducers', async () => {
    const mountProducers = await actions.fetchMountProducers();
    setMountProducers(mountProducers?.data);
    return mountProducers;
  }, {refetchOnWindowFocus: false});
};

export default useFetchMountProducers;
