import {useEffect, useMemo, useCallback, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';

import {AG_GRID_LOCALE_SV} from '../../config/constants';
import actions from '../../api/projectManagement';

import useMutateFetchProjectColumnDefinitions from
  '../../hooks/projectManagement/useMutateFetchProjectColumnDefinitions';
import useMutateUpdateProject from '../../hooks/projectManagement/useMutateUpdateProject';

import UILoadingIndicator from '../UICommon/UILoadingIndicator';
import UIProjectHistoryModal from './UIProjectHistoryModal';
import ProjectContextMenu from './ProjectContextMenu';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import useMutateFetchNewColumnData from '../../hooks/projectManagement/useMutateFetchNewColumnData';
import {format} from 'date-fns';
import {MdCloseFullscreen, MdOpenInFull} from 'react-icons/md';
import {useRecoilState} from 'recoil';
import {currentProjectHistory} from '../../state/projectManagement/projectManagement';


const ProjectTable = ({
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [columns, setColumns] = useState();
  const [lastUpdate, setLastUpdate] = useState('');
  const [isFullScreen, setFullScreen] = useState(false);
  const [historyId, setHistoryId] = useRecoilState(currentProjectHistory);

  const setFull = (value: boolean) => {
    const fullScreenWrapper = document.getElementById('fullscreen-wrapper');
    if (value) {
      fullScreenWrapper?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setFullScreen(!isFullScreen);
  };

  const {
    mutate: mutateFetchHeaders,
    isSuccess: headersSuccess,
    data: headers,
    isLoading: isHeadersLoading,
  } = useMutateFetchProjectColumnDefinitions();
  const {
    mutate: mutateUpdateProject,
    isLoading: isUpdateProjectLoading,
  } = useMutateUpdateProject();
  const {
    mutate: fetchNewColumnData,
    data: updatedColumnData,
    isSuccess: isFetchUpdatedDataSuccess,
  } = useMutateFetchNewColumnData();

  useEffect(() => {
    mutateFetchHeaders();
    setLastUpdate(getDateTime());
  }, []);


  const fetchData = async () => {
    if (lastUpdate !== '') {
      fetchNewColumnData(lastUpdate);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      fetchData();
    }, 5_000);
    fetchData();
    return () => {
      clearInterval(timer);
    };
  }, [lastUpdate]);

  const getDateTime = () => {
    return format(new Date(), 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
  };

  useEffect(() => {
    if (isFetchUpdatedDataSuccess) {
      if (updatedColumnData.length > 0) {
        updatedColumnData.map((updatedObj: any) => {
          const projectId = updatedObj.project;
          const column = updatedObj.column;
          updateColumns(projectId, column);
        });
        setLastUpdate(getDateTime());
      }
    }
  }, [isFetchUpdatedDataSuccess]);

  const updateColumns = useCallback((projectId: string, column: any) => {
    const rowNode = gridRef?.current?.api.getRowNode(projectId);
    const id = column.type.toString();
    const value = column.value;

    rowNode?.setDataValue(id, value);
  }, []);

  useEffect(() => {
    if (headersSuccess) {
      setColumns(headers);
    }
  }, [headersSuccess]);

  const defaultColumnDefinition = useMemo( ()=> ({
    sortable: true,
    filter: true,
    floatingFilter: true,
    editable: true,
    resizable: true,
    enableCellChangeFlash: true,
  }), []);

  const defaultSectionDefinition = useMemo( ()=> ({
    marryChildren: true,
  }), []);

  const onGridReady = useCallback((params: any) => {
    actions.fetchProjects()
        .then((res) => gridRef?.current?.api.setRowData(res.data));
  }, []);

  const onCellValueChanged = (params: any) => {
    const {data} = params;
    const putData = {
      id: data.id,
      data: data,
    };
    setLastUpdate(getDateTime());
    mutateUpdateProject(putData);
  };


  const gridOptions = {
    localeText: AG_GRID_LOCALE_SV,
    pagination: true,
    paginationPageSize: 250,
    loadingOverlayComponent: UILoadingIndicator,
    components: {
      contextMenu: ProjectContextMenu,
    },
    getRowId: (params: any) => params.data.id,
  };

  if (isHeadersLoading) {
    gridRef?.current?.api.showLoadingOverlay();
  }

  return (
    <div
      className='w-full h-full ag-theme-alpine'
      id='fullscreen-wrapper'
    >
      <div
        className='absolute right-1 top-1 z-50 opacity-60 hover:opacity-100
         transition-all duration-75 ease-in'
        onClick={() => setFull(!isFullScreen)}
      >
        {isFullScreen ? <MdCloseFullscreen/> : <MdOpenInFull/>}
      </div>
      {isUpdateProjectLoading && <UILoadingIndicator/>}
      {historyId &&
      <UIProjectHistoryModal
        projectId={historyId}
        onClose={() => setHistoryId(undefined)}
      />
      }
      <AgGridReact
        ref={gridRef}
        gridOptions={gridOptions}
        columnDefs={columns}
        defaultColDef={defaultColumnDefinition}
        defaultColGroupDef={defaultSectionDefinition}
        rowSelection='multiple'
        animateRows={true}
        onCellValueChanged={onCellValueChanged}
        onGridReady={onGridReady}
      />
    </div>
  );
};

export default ProjectTable;
