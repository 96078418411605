import {FC} from 'react';
import {toCurrency} from '../../../utils/toCurrency';

type CalculationMontageTableItemProps = {
  description: string;
  cost: number;
  onClick: Function;
};

const CalculationMontageTableItem: FC<CalculationMontageTableItemProps> = ({
  description,
  cost,
  onClick,
}) => {
  return (
    <div
      className='flex items-center justify-between text-sm py-2 border-b
       border-[#EBEBF3] px-4 hover:bg-gray-100'
      onClick={() => onClick()}
    >
      <div>
        {
          <label
            className='ml-2'
          >
            {description}
          </label>
        }
      </div>
      <div className='mr-2'>
        {toCurrency(cost)}
      </div>
    </div>
  );
};

export default CalculationMontageTableItem;
