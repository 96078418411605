import {useMutation} from 'react-query';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {calculationQuoteState, chartDataState} from '../../state/calculation/calculation';
import actions from '../../api/charts';
import QuoteType from '../../types/QuoteType';


const useCalculateQuoteCharts = () => {
  const [quote, setQuote] = useRecoilState<QuoteType>(calculationQuoteState);
  const setChartData = useSetRecoilState(chartDataState);
  return useMutation(async () => {
    if (quote.id !== null) {
      const res = await actions.calculateCharts(quote.id);

      const tmpQuote = {...quote};
      tmpQuote.is_statistics_updated = true;
      setQuote(tmpQuote);

      setChartData(res.data);
      return res.data;
    }
  }, {retry: false});
};

export default useCalculateQuoteCharts;
