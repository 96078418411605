import {useMutation} from 'react-query';
import {useRecoilValue} from 'recoil';
import actions from '../../api/quotes';
import {calculationMonthlyConsumptionFormState} from '../../state/calculation/calculation';

const useUpdateMonthlyConsumption = () => {
  const consumption = useRecoilValue(calculationMonthlyConsumptionFormState);
  return useMutation(async () => {
    if (consumption.id !== null) {
      const res = await actions.updateMonthlyConsumption(consumption, consumption.id);
      return res;
    }
  }, {retry: false});
};

export default useUpdateMonthlyConsumption;
