import {useMutation} from 'react-query';
import {useRecoilState} from 'recoil';
import {calculationQuoteState} from '../../state/calculation/calculation';
import actions from '../../api/quotes';
import QuoteType from '../../types/QuoteType';


const useCalculateQuoteMaterialCost = () => {
  const [quote, setQuote] = useRecoilState<QuoteType>(calculationQuoteState);
  return useMutation(async () => {
    if (quote.id !== null) {
      const res = await actions.calculateQuoteMaterialCost(quote.id);
      setQuote(res.data);
      return res;
    }
  }, {retry: false});
};

export default useCalculateQuoteMaterialCost;
