import {FC} from 'react';

type UIFileInputProps = {
  name: string;
  label: string;
  onChange: Function;
};

const UIFileInput: FC<UIFileInputProps> = ({
  name,
  label,
  onChange,
}) => {
  return (
    <div className='relative w-full mb-3'>
      <label
        className='block text-xs font-bold mb-2 uppercase text-[#696969]'
        htmlFor={name}
      >
        {label}
      </label>
      <input
        className='block w-full text-sm text-[#6B6B6B] border border-[#F0F1F7] rounded-lg file:cursor-pointer
         bg-[#FCFDFE] rounded-lg cursor-pointer focus:outline-none h-[46px] file:h-[44px] file:border-[#F0F1F7]
          file:border-none file:outline-none file:bg-blue-primary file:text-white file:text-sm file:mr-2 file:px-3'
        id={name}
        type='file'
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export default UIFileInput;
