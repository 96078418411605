import React, {FC} from 'react';

type IUNoDataIndicatorProps = {
    message?: string;
    style?: string;
}

const UINoDataIndicator : FC<IUNoDataIndicatorProps> = ({
  message = 'Ingen data hittades.',
  style,
}) => {
  return (
    <div className={`${style} text-slate-400 h-full flex justify-center items-center`}>
      {message}
    </div>
  );
};

export default UINoDataIndicator;
