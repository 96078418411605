import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import UILargeButton from './UILargeButton';
import UIUnderlineButton from './UIUnderlineButton';

type UIPaginationButtonsProps = {
  title?: string,
  nextPage: Function;
  nextPageDisabled?: boolean;
  nextPageTooltop?: string;
  subTitle?: string
  prevPage?: Function;
  prevPageDisabled?: boolean;
  prevPageTooltop?: string;
};

const UIPaginationButtons: FC<UIPaginationButtonsProps> = ({
  title: title,
  nextPage,
  nextPageDisabled,
  nextPageTooltop,
  subTitle,
  prevPage,
  prevPageDisabled,
  prevPageTooltop,
}) => {
  const {t} = useTranslation();
  return (
    <div className="w-full flex flex-col items-end pr-6">
      <div className="text-center">
        <UILargeButton
          label={title || t('generic.buttons.pagination.next')}
          onClick={nextPage}
          disabled={nextPageDisabled}
          toolTip={nextPageTooltop}
        />
      </div>
      {prevPage && <div className="text-center">
        <UIUnderlineButton
          label={subTitle || t('generic.buttons.pagination.previous')}
          onClick={prevPage}
          disabled={prevPageDisabled}
          toolTip={prevPageTooltop}
        />
      </div>
      }
    </div>
  );
};

export default UIPaginationButtons;
