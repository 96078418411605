import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/mounts';
import {mountsArray} from '../../state/products/mounts';

const useFetchMounts = () => {
  const setMounts = useSetRecoilState(mountsArray);
  return useMutation('fetchMounts', async () => {
    const res = await actions.fetchMounts();
    setMounts(res.data);
    return res.data;
  });
};

export default useFetchMounts;
