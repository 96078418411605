import {FC} from 'react';
import {useTranslation} from 'react-i18next';

type UISearchProps = {
  setSearch: Function,
  value: string,
};

const UISearch: FC<UISearchProps> = ({
  setSearch,
  value,
}) => {
  const {t} = useTranslation();
  return (
    <div className="relative w-96">
      <input
        className={
          `bg-white text-sm items-center justify-between ` +
            `font-bold py-3 px-6 rounded hover:shadow-lg outline-none ` +
            `focus:outline-none ease-linear transition-all duration-150 flex ` +
            `border border-[#E9ECEF] w-full h-full`
        }
        value={value}
        type='text'
        placeholder={t('generic.form.placeholders.search')!}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>);
};

export default UISearch;
