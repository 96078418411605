
const URL = 'quote_cost/';

import client from './client';

const addQuoteCost = async (data: any) => {
  return client.post(URL, data);
};

const updateQuoteCost = async (data: any, id: number) => {
  return client.put(URL + id + '/', data);
};

const deleteQuoteCost = async (id: number) => {
  return client.delete(`${URL}${id}/`);
};

const actions = {
  addQuoteCost,
  updateQuoteCost,
  deleteQuoteCost,
};

export default actions;
