import {FC} from 'react';
import {useRecoilState} from 'recoil';

import {currentContactTab} from '../../../state/calculation/calculation';

import UICard from '../../UICommon/UICard';
import CalculationContactTabNavigation from './CalculationContactTabNavigation';
import CalculationCustomerForm from './CalculationCustomerForm';


const CalculationContactTab: FC = () => {
  const [currentTab, setCurrentTab] = useRecoilState(currentContactTab);

  return (
    <UICard style='max-h-full relative h-full overflow-y-scroll'>
      <CalculationContactTabNavigation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <div className='px-8 h-full pb-6'>
        <CalculationCustomerForm
          variant={currentTab}
        />
      </div>
    </UICard>
  );
};

export default CalculationContactTab;
