import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/quotes';
import {quotesArray} from '../../state/quotes/quotes';

const useFetchQuotes = () => {
  const setQuotes = useSetRecoilState(quotesArray);
  return useQuery('fetchQuotes', async () => {
    const quotes = await actions.fetchQuotes();
    setQuotes(quotes?.data);
    return quotes;
  });
};

export default useFetchQuotes;
