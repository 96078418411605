import {FC, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useTranslation} from 'react-i18next';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {useParams} from 'react-router-dom';

import {toCurrency} from '../utils/toCurrency';

import useMutateFetchQuote from '../hooks/quotes/useMutateFetchQuote';
import useFetchSingleCustomer from '../hooks/customers/useFetchSingleCustomer';
import useResetCalculation from '../hooks/quotes/useResetCalculation';
import useFetchQuoteSections from '../hooks/quotes/useFetchQuoteSections';

import {
  calculationQuoteState,
  currentCalculationTab, currentContactTab,
} from '../state/calculation/calculation';
import {singleCustomer} from '../state/customers/customers';

import CalculationProgressBar from '../components/CalculationPage/Layout/CalculationProgressBar';
import CalculationContactTab from '../components/CalculationPage/ContactTab/CalculationContactTab';
import CalculationSectionsTab from '../components/CalculationPage/SectionsTab/CalculationSectionsTab';
import CalculationMaterialTab from '../components/CalculationPage/MaterialTab/CalculationMaterialTab';
import CalculationMontageTab from '../components/CalculationPage/MontageTab/CalculationMontageTab';
import CalculationStatisticsTab from '../components/CalculationPage/StatisticsTab/CalculationStatisticsTab';
import CalculationOfferTab from '../components/CalculationPage/OfferTab/CalculationOfferTab';
import CalculationProductsTab from '../components/CalculationPage/ProductsTab/CalculationProductsTab';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';

const CalculationPage: FC = () => {
  const {id} = useParams();
  const {t} = useTranslation();

  const currentTab = useRecoilValue(currentCalculationTab);
  const setContactTab = useSetRecoilState(currentContactTab);
  const quote = useRecoilValue(calculationQuoteState);
  const customer = useRecoilValue(singleCustomer);

  const {
    isLoading: isResetLoading,
    mutate: mutateResetCalculation,
    isSuccess: isResetCalculationSuccess,
  } = useResetCalculation();

  const {
    isLoading: isQuoteLoading,
    isError: isQuoteError,
    mutate: mutateFetchQuote,
    isSuccess: isFetchQuoteSuccess,
  } = useMutateFetchQuote();

  const {
    isLoading: isCustomerLoading,
    isError: isCustomerError,
    mutate: mutateFetchCustomer,
  } = useFetchSingleCustomer();

  const {
    isLoading: isSectionsLoading,
    mutate: mutateFetchSections,
  } = useFetchQuoteSections();

  useEffect(() => {
    mutateResetCalculation();
  }, []);

  useEffect(() => {
    if (customer.id) {
      setContactTab('quote');
    }
  }, [isResetCalculationSuccess]);

  useEffect(() => {
    if (id) {
      mutateFetchQuote(id);
    }
  }, [id]);

  useEffect(() => {
    if (quote.id) {
      mutateFetchCustomer(`${quote.customer.id}`);
      mutateFetchSections(quote.id);
    }
  }, [isFetchQuoteSuccess]);

  const TabsComponents: any = {
    contact: CalculationContactTab,
    sections: CalculationSectionsTab,
    products: CalculationProductsTab,
    material: CalculationMaterialTab,
    montage: CalculationMontageTab,
    statistics: CalculationStatisticsTab,
    quote: CalculationOfferTab,
  };

  const Tabs: any = TabsComponents[currentTab];

  if (isResetLoading || isQuoteLoading || isCustomerLoading || isSectionsLoading) {
    return <UILoadingIndicator/>;
  }

  if (isQuoteError || isCustomerError) {
    return <UIErrorIndicator/>;
  }

  return (
    <div className='w-full h-full flex flex-col pb-6'>
      <div className='w-full flex justify-between mb-10'>
        {id ?
          <div className='text-xl flex flex-col'>
            <div className="w-full flex font-bold text-xl text-slate-500 items-center">
              <span className='capitalize'>
                {quote.name}
              </span>
              {quote.is_template &&
             <HiOutlineDocumentText
               className='ml-2'
               title={t('calculation.header.templateIconTooltip')!}
             />
              }
            </div>
            <div className="flex font-semibold">
              <div className='mr-2 font-bold'>
                {t('calculation.financial.sumExTAX')}
              </div>
              {toCurrency(quote.total_cost || 0, 0)}
            </div>
          </div> :
        <div>
          <div className="w-full flex font-bold text-2xl text-slate-600">
            <span className='capitalize ml-2'>
              {customer.id ? (t('calculation.pages.contact.title.createCalculation')
                  .replace('{fullName}', `${customer.first_name} ${customer.last_name}`)) :
               (t('calculation.pages.contact.title.createCustomer'))}
            </span>
          </div>
        </div>
        }
      </div>
      {quote.id &&
        <CalculationProgressBar
          quote={quote}
          customer={customer}
        />
      }
      <Tabs />
    </div>
  );
};

export default CalculationPage;
