import {FC} from 'react';
import SectionType from '../../../types/SectionType';

type CalculationSectionGridProps = {
  section: SectionType;
  index: number;
  sections: any;
  setSections: Function;
  isVerticalOrientation: boolean;
}

const CalculationSectionGrid: FC<CalculationSectionGridProps> = ({section,
  index,
  sections,
  setSections,
  isVerticalOrientation,
}) => {
  const selectActivePanel = (x: number, y: number) => {
    const tmpSections = [...sections];
    const tmpSection = {...section};
    const items = [...tmpSection.section_items];
    const item = [...items[x]];
    if (item[y] === 1) {
      item[y] = 0;
      tmpSection.solar_panel_amount--;
    } else {
      item[y] = 1;
      tmpSection.solar_panel_amount++;
    }
    items[x] = item;
    tmpSection.section_items = items;
    tmpSections[index] = tmpSection;
    setSections(tmpSections);
  };
  const getFontSize = () => {
    if (section.width_m <= 10) {
      return 'text-[26px]';
    }
    if (section.width_m <= 20) {
      return isVerticalOrientation ? 'text-[18px]' : 'text-[28px]';
    }
    if (section.width_m <= 30) {
      return isVerticalOrientation ? 'text-[12px]' : 'text-[18px]';
    }
    if (section.width_m <= 40) {
      return isVerticalOrientation ? 'text-[9px]' : 'text-[14px]';
    }
    if (section.width_m <= 50) {
      return isVerticalOrientation ? 'text-[7px]' : 'text-[12px]';
    } else {
      return isVerticalOrientation ? 'text-[6px]' : 'text-[10px]';
    }
  };

  return (
    <div className='w-2/3 justify-center flex overflow-scroll p-5 border-2'>
      {section.section_items.length > 0 && (
        <div className='flex flex-col h-fit space-y-1'>
          {section.section_items.map((row: any, x: number) => (
            <div key={x} className='flex space-x-1'>
              {row.map((col: number, y: number) => (
                <div onClick={() => selectActivePanel(x, y)} key={x + ':' + y}
                  className={`${col == 0 ? 'opacity-30' : ''} items-center bg-[#5A5A5A]
                  ${isVerticalOrientation ? 'h-[6em] w-[3em]' : 'h-[1.5em] w-[3em]'}
                    justify-center flex select-none text-white ${getFontSize()}`}>
                  {x + 1}:{y + 1}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CalculationSectionGrid;
