import _ from 'lodash';
import {atom, selector} from 'recoil';
import SortingType from '../../types/SortingType';
import {quotesArray} from './quotes';
import {quoteTemplatesPageState, quoteTemplatesRowsPerPage} from './quoteTemplatesPages';

export const quotesTemplatesSortState = atom<SortingType>({
  key: 'quotesTemplatesSortState',
  default: {
    field: '',
    type: '',
  },
});

export const quotesTemplatesSetSort = selector({
  key: 'quotesTemplatesSetSort',
  get: ({get}) => get(quotesTemplatesSortState),
  set: ({get, set, reset}, key: any) => {
    const sort: SortingType = get(quotesTemplatesSortState);
    if (sort.field === key) {
      if (sort.type === '') {
        set(quotesTemplatesSortState, {
          field: key,
          type: 'asc',
        });
      }

      if (sort.type === 'asc') {
        set(quotesTemplatesSortState, {
          field: key,
          type: 'desc',
        });
      }

      if (sort.type === 'desc') {
        reset(quotesTemplatesSortState);
      }
    } else {
      set(quotesTemplatesSortState, {
        field: key,
        type: 'asc',
      });
    }
  },
});

export const quotesTemplates = selector({
  key: 'quotesTemplates',
  get: ({get}) => {
    const quotes = get(quotesArray);
    return _.filter(quotes, ['is_template', true]);
  },
});

export const quotesTemplatesSorted = selector({
  key: 'quotesTemplatesSorted',
  get: ({get}) => {
    const quotes = get(quotesTemplates);
    const sort: any = get(quotesTemplatesSortState);
    if (quotes == null) {
      return [];
    }

    if (!sort) {
      return quotes;
    }

    return _.orderBy(quotes, [sort.field], [sort.type]);
  },
});

export const quotesTemplatesPaginated = selector({
  key: 'quotesTemplatesPaginated',
  get: ({get}) => {
    const quotes = get(quotesTemplatesSorted);
    const page = get(quoteTemplatesPageState);
    const rowsPerPage = get(quoteTemplatesRowsPerPage);
    if (quotes == null) {
      return [];
    }

    const lastRowIdx = page * rowsPerPage;
    const firstRowIdx = lastRowIdx - rowsPerPage;
    return quotes.slice(firstRowIdx, lastRowIdx);
  },
});
