export const isHavingNulls = (obj: any) => {
  for (const [key, value] of Object.entries(obj)) {
    if (key === 'quote' || key === 'id') {

    } else {
      if (value === null) {
        return true;
      }
    }
  }
  return false;
};
