import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {toCurrency} from '../../../utils/toCurrency';
import getSum from '../../../utils/getSum';

import UICard from '../../UICommon/UICard';
import CalculationOfferSummaryCard from './CalculationOfferSummaryCard';
import {UITableCustomCosts} from '../../UITable/UITableCustomCosts';


type CalculationOfferCustomCostProps = {
  otherCosts: any;
  isPrivatePerson: boolean;
};

const CalculationOfferCustomCost: FC<CalculationOfferCustomCostProps> = ({
  otherCosts,
  isPrivatePerson,
}) => {
  const {t} = useTranslation();
  const [toggled, setToggled] = useState(false);
  const sum = getSum(otherCosts.map((cost: any) => cost.cost));

  return (
    <div className="w-full">
      <UICard style='w-full'>
        <div
          className={`w-full flex justify-between items-center h-6 select-none p-7 ${toggled && 'mb-4'}`}
          onClick={() => setToggled(!toggled)}>
          <h2 className='font-semibold text-sm text-[#1E1E1E]'>
            {t('calculation.pages.quote.otherCosts.title')}
          </h2>
          <div className='flex items-center'>
            <span className='mr-4 text-sm font-semibold'>
              {`${t('calculation.financial.sumExTAX')} ${toCurrency(sum, 0)}`}
            </span>
            {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> :
             <FiChevronUp className='h-6 w-6 ml-2'/>}
          </div>
        </div>
        <div className={`w-full p-4 ${!toggled && 'hidden'}`}>
          <UITableCustomCosts
            costs={otherCosts}
          />
        </div>
      </UICard>
      {toggled && (
        <div
          className='mt-2'
        >
          <CalculationOfferSummaryCard
            price={sum}
            tax={sum * 0.25}
            isPrivatePerson={isPrivatePerson}
          />
        </div>
      )}
    </div>
  );
};

export default CalculationOfferCustomCost;
