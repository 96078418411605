import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import getQuoteProductsFiltered from '../../../utils/getQuoteProductsFiltered';

import useCalculateQuoteMounts from '../../../hooks/quotes/useCalculateQuoteMounts';
import useCalculateQuoteMaterialCost from '../../../hooks/quotes/useCalculateQuoteMaterialCost';

import {calculationQuoteState, currentCalculationTab} from '../../../state/calculation/calculation';
import {selectedProductsFilteredByType} from '../../../state/products/products';

import UICard from '../../UICommon/UICard';
import CalculationMaterialSummaryCard from './CalculationMaterialSummaryCard';
import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import {UITableMaterials} from '../../UITable/UITableMaterials';
import UIPaginationButtons from '../../UICommon/UIPaginationButtons';
import UISummaryTable from '../../UICommon/UISummaryTable';
import {toCurrency} from '../../../utils/toCurrency';

const CalculationMaterialTab: FC = () => {
  const {t} = useTranslation();
  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const setFilteredProducts = useSetRecoilState(selectedProductsFilteredByType);
  const products: any = useRecoilValue(selectedProductsFilteredByType);
  const quote = useRecoilValue(calculationQuoteState);
  const [firstRender, setFirstRender] = useState(true);
  const materialCosts = quote.costs.filter((cost: any) => cost.category === 'material');
  const materialCostsRows: any = {};


  materialCosts.map((cost: any) => {
    materialCostsRows[cost.description] = toCurrency(cost.cost);
  });

  const {
    isLoading: isQuoteCalculateLoading,
    isSuccess: isQuoteCalculateSuccess,
    mutate: mutateCalculateQuote,
  } = useCalculateQuoteMounts();
  const {
    isLoading: isQuoteCalculateMaterialCostLoading,
    mutate: mutateCalculateQuoteMaterialCosts,
  } = useCalculateQuoteMaterialCost();


  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      mutateCalculateQuote();
    }
  }, [firstRender]);

  useEffect(() => {
    if (isQuoteCalculateSuccess) {
      mutateCalculateQuoteMaterialCosts();
      setFilteredProducts(getQuoteProductsFiltered(quote));
    }
  }, [isQuoteCalculateSuccess]);

  const goToNextPage = () => {
    setCurrentTab('montage');
  };

  const goToPreviousPage = () => {
    setCurrentTab('products');
  };

  if (
    isQuoteCalculateLoading ||
    isQuoteCalculateMaterialCostLoading
  ) {
    return <UILoadingIndicator />;
  }

  return (
    <div className="w-full h-full">
      <h2 className="font-bold text-lg mb-4">
        {t('calculation.pages.material.title')}
      </h2>
      <div
        className='h-50vh overflow-y-scroll space-y-2'
      >
        <UICard style='max-h-[50vh] relative h-full overflow-y-scroll py-6'>
          <UITableMaterials
            products={products}
          />
        </UICard>
        {Object.keys(materialCostsRows).length > 0 &&
        <UISummaryTable
          rows={materialCostsRows}
        />}
        <CalculationMaterialSummaryCard
          cost={quote.material_cost ? quote.material_cost : 0}
          addition={quote.material_addition ? quote.material_addition : 0}
          price={quote.material_price ? quote.material_price : 0}
        />
      </div>
      <UIPaginationButtons
        nextPage={goToNextPage}
        prevPage={goToPreviousPage}
      />
    </div>
  );
};

export default CalculationMaterialTab;
