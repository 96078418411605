import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useRecoilState} from 'recoil';
import {homeQuotesTemplatesColumns} from '../config/constants';

import useFetchCustomers from '../hooks/customers/useFetchCustomers';

import {
  quotesTemplatesPaginated,
  quotesTemplatesSetSort,
} from '../state/quotes/quotesTemplates';
import {
  quoteTemplatesPagesLength,
  quoteTemplatesPageState,
  quoteTemplatesRowsPerPage,
} from '../state/quotes/quoteTemplatesPages';

import UICard from '../components/UICommon/UICard';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UINoDataIndicator from '../components/UICommon/UINoDataIndicator';
import UIDropdownItem from '../components/UICommon/UIDropdownItem';
import UITable from '../components/UITable/UITable';
import UITablePages from '../components/UITable/UITablePages';
import UITablePagination from '../components/UITable/UITablePagination';

const QuoteTemplatesPage: React.FC = () => {
  const {t} = useTranslation();
  const {isLoading: isCustomersLoading, isError: isCustomersError} = useFetchCustomers();
  const quotes: any = useRecoilValue(quotesTemplatesPaginated);
  const [quotesTemplatesSorting, setQuotesTemplatesSorting] = useRecoilState(
      quotesTemplatesSetSort,
  );
  const [page, setPage] = useRecoilState(quoteTemplatesPageState);
  const [numberPages, setNumberPages] = useRecoilState(
      quoteTemplatesRowsPerPage,
  );

  const totalPages = useRecoilValue(quoteTemplatesPagesLength);
  const navigate = useNavigate();

  const editQuote = (id: number) => {
    navigate(`/kalkyl/${id}`);
  };

  if (isCustomersLoading) {
    return <UILoadingIndicator />;
  }

  if (isCustomersError) {
    return <UIErrorIndicator />;
  }

  return (
    <div className='w-full h-full flex flex-col'>
      {!quotes ? (
        <UINoDataIndicator />
      ) : (
        <>
          <div className='w-full h-20 mb-6 flex items-end justify-between'>
            <div className='font-outfit text-3xl'>
              {t('templatePage.title')}
            </div>
          </div>
          <UICard style='overflow-y-scroll max-h-full relative px-4 h-full'>
            <div
              className='border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10 pt-8 pb-2'
            >
              <div className='flex items-start justify-between mb-4'>
                <div>
                  <h2 className='mb-3 font-semibold text-xl'>
                    {t('templatePage.table.title')}
                  </h2>
                  <p className='text-sm'>
                    {t('templatePage.table.description')}
                  </p>
                </div>
              </div>
              <div className='flex items-start justify-between'>
                <div className='flex'>
                  <UITablePages value={numberPages}>
                    <UIDropdownItem label={10} onClick={setNumberPages} />
                    <UIDropdownItem label={20} onClick={setNumberPages} />
                    <UIDropdownItem label={30} onClick={setNumberPages} />
                    <UIDropdownItem label={40} onClick={setNumberPages} />
                  </UITablePages>
                </div>
              </div>
            </div>
            <div className='w-full mt-6 pb-8 h-full'>
              <UITable
                data={quotes}
                columns={homeQuotesTemplatesColumns}
                sorting={quotesTemplatesSorting}
                onClick={editQuote}
                setSorting={setQuotesTemplatesSorting}
              />
            </div>
            <div className='sticky bottom-0 bg-white py-4 flex justify-center'>
              <UITablePagination
                totalPages={totalPages}
                page={page}
                setPage={setPage}
              />
            </div>
          </UICard>
        </>
      )}
    </div>
  );
};

export default QuoteTemplatesPage;
