export const translateProduct = (productName: string) => {
  switch (productName) {
    case 'inverter': return 'växelriktare';
    case 'solarpanel': return 'solpanel';
    case 'battery': return 'batteri';
    case 'chargingbox': return 'laddbox';
    case 'genericproduct': return 'övrigt';
    default: return productName;
  }
};
