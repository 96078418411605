import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import UIButton from '../UICommon/UIButton';
import UIInput from '../UICommon/UIInput';
import {useRecoilValue} from 'recoil';
import {calculationQuoteState} from '../../state/calculation/calculation';
import {getPositiveNumber} from '../../utils/getPositiveNumber';
import UIDeleteButton from '../UICommon/UIDeleteButton';
import UIDropdownSelect from '../UICommon/UIDropdownSelect';
import UIDropdownSelectItem from '../UICommon/UIDropdownSelectItem';

type AddCustomCostModalProps = {
  onClose: Function,
  onSubmit: Function,
  customCost: any,
};

const AddCustomCostModal: FC<AddCustomCostModalProps> = ({
  onClose,
  onSubmit,
  customCost,
}) => {
  const {t} = useTranslation();
  const isEdit = customCost.id;
  const quote = useRecoilValue(calculationQuoteState);
  const [data, setData] = useState(isEdit ? customCost : {
    quote: quote.id,
    description: '',
    cost: 0,
    editable: true,
    category: 'other',
  });

  const costCategories = {
    'electrician': 'Elektriker',
    'mounting': 'Montage',
    'material': 'Material',
    'other': 'Övrigt',
  };

  const onChange = (e: any) => {
    let {name, value} = e.target;

    if (name === 'cost' || name === 'quantity') {
      value = getPositiveNumber(value);
    }

    const tmpData: any = {...data};

    tmpData[name] = value;

    setData(tmpData);
  };

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-50 items-center justify-center h-full w-full'>
      <div className='flex bg-white w-[600px] h-fit rounded-2xl p-10 items-center flex-col justify-between'>
        <div className='text-xl font-semibold'>
          {t(`calculation.pages.montage.quoteCosts.modal.${isEdit ? 'editTitle' : 'createTitle'}`)}
        </div>
        <div className='flex justify-between space-x-4 my-8'>
          <UIInput
            type='text'
            label={t('calculation.pages.montage.quoteCosts.modal.form.description')!}
            name='description'
            value={data.description}
            disabled={!data.editable}
            onChange={onChange}
          />
          <UIInput
            type='text'
            label={t('calculation.pages.montage.quoteCosts.modal.form.cost')!}
            name='cost'
            value={data.cost}
            onChange={onChange}
          />
          <UIDropdownSelect
            label={t('calculation.pages.montage.quoteCosts.modal.form.category')!}
            value={costCategories[data.category as keyof typeof costCategories]}
            readonly={!data.editable}
          >
            {Object.keys(costCategories).map((cat: string, idx: number) => (
              <UIDropdownSelectItem
                key={idx}
                label={costCategories[cat as keyof typeof costCategories]}
                value={cat}
                onClick={() => setData({...data, category: cat})}
              />
            ))}
          </UIDropdownSelect>
        </div>
        <div className='flex justify-between space-x-2'>
          <UIButton
            label={t('calculation.pages.montage.quoteCosts.modal.close')!}
            onClick={onClose}
          />
          <UIButton
            label={t('calculation.pages.montage.quoteCosts.modal.submit')!}
            onClick={() => onSubmit(isEdit ? 'edit' : 'add', data)}
            disabled={!(data.description && data.cost)}
          />
          {isEdit &&
          <UIDeleteButton
            onClick={() => onSubmit('delete', data)}
          />}
        </div>
      </div>
    </div>
  );
};

export default AddCustomCostModal;
