import {FC} from 'react';

type UIDropdownItemProps = {
  label: string | number;
  onClick: Function;
};

const UIDropdownItem: FC<UIDropdownItemProps> = ({
  label,
  onClick,
}) => {
  return (
    <div
      className='w-full py-2 font-semibold cursor-pointer border-b border-[#E9ECEF] last:border-none hover:bg-slate-100'
      onClick={() => onClick(label)}
    >
      {label}
    </div>
  );
};

export default UIDropdownItem;
