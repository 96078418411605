import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {format} from 'date-fns';
import {sv} from 'date-fns/locale';
import {useRecoilValue, useRecoilState, useResetRecoilState} from 'recoil';

import {customerStatusSelect} from '../config/customerForm';

import useFetchCustomers from '../hooks/customers/useFetchCustomers';
import useUpdateCustomerStatus from '../hooks/customers/useUpdateCustomerStatus';
import useResetCalculation from '../hooks/quotes/useResetCalculation';

import {customersPaginated, customersSetSort, singleCustomer} from '../state/customers/customers';
import {customersPagesLength, customersPageState, customersRowsPerPage} from '../state/customers/customersPages';

import UITablePagination from '../components/UITable/UITablePagination';
import UICard from '../components/UICommon/UICard';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UINoDataIndicator from '../components/UICommon/UINoDataIndicator';
import UIDropdownItem from '../components/UICommon/UIDropdownItem';
import UITable from '../components/UITable/UITable';
import UITablePages from '../components/UITable/UITablePages';
import UILargeButton from '../components/UICommon/UILargeButton';

const CustomersPage: FC = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const customers: any = useRecoilValue(customersPaginated);
  const [customersSorting, setCustomersSorting] = useRecoilState(customersSetSort);
  const [page, setPage] = useRecoilState(customersPageState);
  const [numberPages, setNumberPages] = useRecoilState(customersRowsPerPage);
  const totalPages = useRecoilValue(customersPagesLength);

  const {
    isLoading: isCustomersLoading,
    isError: isCustomersError,
  } = useFetchCustomers();

  const {
    mutate: updateCustomerStatus,
  } = useUpdateCustomerStatus();

  const {
    mutate: mutateResetCalculation,
    isLoading: isCalculationResetLoading,
    isSuccess: isQuoteResetSuccess,
  } = useResetCalculation();

  const resetCustomer = useResetRecoilState(singleCustomer);

  const redirectToNewCalculation = () => {
    resetCustomer();
    mutateResetCalculation();
  };

  useEffect(() => {
    if (isQuoteResetSuccess) {
      navigate('/kalkyl/');
    }
  }, [isQuoteResetSuccess]);

  const customersColumns = [
    {
      id: 'first_name',
      accessor: 'first_name',
      Header: 'Namn',
      sticky: 'top',
      Cell: ({row}: any) => (row.original.first_name + ' ' + row.original.last_name),
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Skapad den',
      sticky: 'top',
      Cell: ({value}: any) => format(new Date(value.replace(/\s+/g, 'T')), 'yyyy/MM/dd', {locale: sv}),
    },
    {
      id: 'status',
      accessor: 'status',
      Header: 'Status',
      sticky: 'top',
      Cell: ({row, value}: any) => {
        return (
          <select
            value={value}
            onChange={(e) => updateCustomerStatus({
              customerId: row.original.id,
              status: e.target.value,
            })}
            className='p-2 rounded-md bg-white border border-gray-200'>
            {customerStatusSelect.map((el: any, key: number) => (
              <option key={key} value={el.value}>{el.label}</option>
            ))}
          </select>
        );
      }},
  ];

  if (isCustomersLoading || isCalculationResetLoading) {
    return <UILoadingIndicator/>;
  }

  if (isCustomersError) {
    return <UIErrorIndicator/>;
  }

  return (
    <div className='w-full h-full flex flex-col'>
      {!customers ? (
        <UINoDataIndicator />
      ) : (
        <>
          <div className="w-full h-20 mb-6 flex items-end justify-between">
            <div className='font-outfit text-3xl'>{t('customerPage.title')}</div>
            <div className="flex">
              <div className="text-center mr-4">
                <UILargeButton
                  label={t('customerPage.createButton')}
                  onClick={redirectToNewCalculation}
                />
              </div>
            </div>
          </div>
          <UICard style='overflow-y-scroll max-h-full relative px-4 h-full'>
            <div
              className='border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10 pt-8 pb-2'
            >
              <div className='flex items-start justify-between mb-4'>
                <div>
                  <h2 className='mb-3 font-semibold text-xl'>
                    {t('customerPage.list.title')}
                  </h2>
                  <p className='text-sm'>
                    {t('customerPage.list.subTitle')}
                  </p>
                </div>
              </div>
              <div className='flex items-start justify-between'>
                <div className='flex'>
                  <UITablePages
                    value={numberPages}
                  >
                    <UIDropdownItem label={10} onClick={setNumberPages} />
                    <UIDropdownItem label={20} onClick={setNumberPages} />
                    <UIDropdownItem label={30} onClick={setNumberPages} />
                    <UIDropdownItem label={40} onClick={setNumberPages} />
                  </UITablePages>
                </div>
              </div>
            </div>
            <div className="w-full mt-6 pb-8 h-full">
              <UITable
                data={customers}
                columns={customersColumns}
                sorting={customersSorting}
                link='kunder'
                setSorting={setCustomersSorting}
              />
            </div>
            <div className="sticky bottom-0 bg-white py-4 flex justify-center">
              <UITablePagination
                totalPages={totalPages}
                page={page}
                setPage={setPage}
              />
            </div>
          </UICard>
        </>
      )}
    </div>
  );
};

export default CustomersPage;
