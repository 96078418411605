import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useTable} from 'react-table';
import {useRecoilValue} from 'recoil';
import {selectedProducts} from '../../../state/products/products';
import UITableSortingArrows from '../../UITable/UITableSortingArrows';
import {calculationQuoteState} from '../../../state/calculation/calculation';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';

type ProductsTableProps = {
  data: any;
  columns: any;
  sorting: any;
  setSorting: Function;
  type: string;
  link?: string;
  onSelectProduct: Function;
  closeModal?: Function;
  isLoading?: boolean;
};

const ProductsTable: FC<ProductsTableProps> = ({
  data,
  columns,
  sorting,
  setSorting,
  type,
  onSelectProduct,
  isLoading,
}) => {
  const {t} = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({columns, data});

  const productsSelected = useRecoilValue(selectedProducts);
  const quote = useRecoilValue(calculationQuoteState);

  const ids = productsSelected.filter((p: any) => p.product_type === type).map((sel: any) => {
    return sel.object_id;
  });

  const hasProduct = (product: any) => {
    return ids.includes(product.id);
  };

  const addProduct = (type: string, product: any) => {
    const quoteProduct = {
      quote: quote.id,
      content_object: product.conten_object,
      object_id: product.id,
      name: product.name,
      product_type: type,
      quantity: 1,
      price: product.price.price,
    };

    onSelectProduct(quoteProduct);
  };

  return (
    <div className="flex-auto px-0 pt-0 pb-2 h-[60vh] overflow-y-scroll">
      <div className="p-0 overflow-x-auto">
        {isLoading && <UILoadingIndicatorSmall/>}
        <table
          className='items-center w-full mb-0 align-top border-gray-200 text-slate-500 table-scroll'
          {...getTableProps()}
        >
          <thead
            className="align-bottom"
          >
            {headerGroups.map((headerGroup, index) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`cell-${index}`}
              >
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={`heading-${i}-${index}`}
                    onClick={() => setSorting(column.id)}
                    className='px-3 py-3 text-left align-middle bg-transparent
                      border-b border-gray-200 shadow-none text-xs border-b-solid
                      tracking-none whitespace-nowrap text-slate-400 opacity-70
                      cursor-pointer uppercase select-none'
                  >
                    <div className='relative inline-block'>
                      {t(`calculation.pages.products.${type}.modal.headers.${column.id}`)}
                      <UITableSortingArrows
                        field={column.id}
                        activeField={sorting.field}
                        type={sorting.type}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
          >
            {rows.map((row: any, j: number) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`row-${j}`}
                  className={
                    `${hasProduct(row.original) ?
                        'bg-blue-primary hover:bg-blue-700 text-white' :
                        'bg-white hover:bg-slate-100 cursor-pointer'
                    }`
                  }
                >
                  {row.cells.map((cell: any, k: number) => (
                    <td
                      {...cell.getCellProps()}
                      key={`cell-${k}-${j}`}
                      className='align-middle bg-transparent border-b whitespace-nowrap shadow-transparent capitalize'
                    >
                      <p
                        className='mb-0 text-sm p-4'
                        onClick={() => addProduct(type, row.original)}
                      >
                        {cell.render('Cell')}
                      </p>

                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsTable;
