import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';
import {monthlyConsumptionInputs} from '../../../config/statisticsForm';
import {
  calculationMonthlyConsumptionFormState,
} from '../../../state/calculation/calculation';
import CalculationStatisticsFormInput from './CalculationStatisticsFormInput';


const CalculationStatisticsConsumptionForm: FC = () => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useRecoilState(
      calculationMonthlyConsumptionFormState,
  );

  const onChange = (event: any) => {
    const {name, value} = event.target;
    setFormValues({...formValues, [name]: parseInt(value)});
  };

  return (
    <div className='w-full mt-4'>
      <div className="w-full flex justify-between items-center select-none mb-4">
        <h2 className='font-semibold text-sm text-[#1E1E1E]'>
          {t('calculation.pages.statistics.form.monthlyConsumption')}
        </h2>
      </div>
      <div className='w-full px-4 pb-4'>
        {monthlyConsumptionInputs.map((el: any) => {
          const key = el.name as keyof typeof formValues;
          return (
            <CalculationStatisticsFormInput
              key={el.id}
              name={el.name}
              label={t(`calculation.pages.statistics.months.${el.name}`)}
              placeholder={el.placeholder}
              value={formValues[key] ? formValues[key] : 0}
              helper={el.helper}
              onChange={onChange}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CalculationStatisticsConsumptionForm;
