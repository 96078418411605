import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {toCurrency} from '../../utils/toCurrency';
import {sum} from 'lodash';


type UITableMaterialsProps = {
    products: any;
}


export const UITableMaterials: FC<UITableMaterialsProps> = ({
  products,
}) => {
  const {t} = useTranslation();
  return (
    <>
      {products && products.map((el: any, idx: number) => {
        if (el.data.length > 0) {
          return (
            <div key={`product-${idx}`} className="w-full mb-4 px-5">
              <div className="w-full font-semibold mb-2">
                {el.name}
              </div>
              <div className='mx-4'>
                <table className='border-collapse w-full pl-2 table-fixed'>
                  <thead>
                    <tr className='font-bold text-sm text-right'>
                      <td className='text-left'>
                        {t('calculation.pages.material.table.headers.article_number')}
                      </td>
                      <td>
                        {t('calculation.pages.material.table.headers.amount')}
                      </td>
                      <td>
                        {t('calculation.pages.material.table.headers.unit_price')}
                      </td>
                      <td>
                        {t('calculation.pages.material.table.headers.sum')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {el.name == 'Solpaneler' ? (
                        el.data.map((item: any) => (
                          item.solar_panel &&
                            <tr className='border-b border-gray-100 text-right hover:bg-gray-100'
                              key={item.id}>
                              <td className='text-blue-700 text-left'>{item.solar_panel[0].name}</td>
                              <td>{item.solar_panel_amount}</td>
                              <td>{toCurrency(item.solar_panel[0].price.cost)}</td>
                              <td>{toCurrency(item.solar_panel[0].price.cost * item.solar_panel_amount)}</td>
                            </tr>
                        ))
                    ) : (
                        el.data.map((item: any) => (
                          item.content_object &&
                            <tr className='border-b border-gray-100 text-right hover:bg-gray-100'
                              key={item.id}>
                              <td className='text-blue-700 text-left'>{item.content_object.name}</td>
                              <td>{item.quantity}</td>
                              <td>{toCurrency(item.content_object.price.cost)}</td>
                              <td>{toCurrency(item.content_object.price.cost * item.quantity)}</td>
                            </tr>
                        ))
                    )}
                  </tbody>
                </table>
                { el.data.length >= 3 && (
                  <div className='text-right mt-2 text-sm'>
                    <div className='font-bold'>
                      {t('calculation.pages.material.table.headers.sum_type')
                          .replace('{type}', el.name)}
                    </div>
                    {
                      toCurrency(sum(el.data.map((item: any) => {
                        return el.name == 'Solpaneler' && item.solar_panel ?
                            item.solar_panel[0].price.cost * item.solar_panel_amount :
                            item.content_object?.price.cost * item.quantity;
                      })))
                    }
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
