import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/quotes';
import {quoteAttachments} from '../../state/quotes/quotes';


const useFetchAttachments = (quoteId: number) => {
  const setAttachments = useSetRecoilState(quoteAttachments);
  return useQuery('fetchAttachments', async () => {
    const data = await actions.fetchAttachments(quoteId);
    setAttachments(data);
    return data;
  });
};

export default useFetchAttachments;
