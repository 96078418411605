import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/charts';
import {
  calculationQuoteState,
  chartDataState,
} from '../../state/calculation/calculation';

const useFetchQuoteCharts = () => {
  const currentQuote = useRecoilValue(calculationQuoteState);
  const setChartData = useSetRecoilState(chartDataState);
  return useMutation(async () => {
    if (currentQuote.id !== null) {
      const res = await actions.fetchCharts(currentQuote.id);
      setChartData(res.data);
      return res.data;
    }
  });
};

export default useFetchQuoteCharts;
