import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import useFetchQuoteInstallation from '../../../hooks/installation/useFetchQuoteInstallation';

import {
  calculationQuoteState,
  currentCalculationTab, quoteInstallationState,
} from '../../../state/calculation/calculation';

import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import CalculationMontageForm from './CalculationMontageForm';
import CalculationMontageTable from './CalculationMontageTable';
import UIPaginationButtons from '../../UICommon/UIPaginationButtons';
import useCreateQuoteInstallation from '../../../hooks/installation/useCreateQuoteInstallation';
import useMutateFetchQuote from '../../../hooks/quotes/useMutateFetchQuote';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';

const CalculationMontageTab: FC = () => {
  const {t} = useTranslation();
  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const quote = useRecoilValue(calculationQuoteState);
  const installation = useRecoilValue(quoteInstallationState);

  const {
    isLoading: isInstallationLoading,
    mutate: mutateFetchInstallation,
    isSuccess: isInstallationFetchSuccess,
  } = useFetchQuoteInstallation();

  const {
    isLoading: isCreateInstallationLoading,
    mutate: mutateCreateInstallation,
  } = useCreateQuoteInstallation();

  const {
    isLoading: isQuoteLoading,
    mutate: mutateFetchQuote,
  } = useMutateFetchQuote();

  useEffect(() => {
    if (quote.id) {
      mutateFetchInstallation(quote.id);
    }
  }, []);

  useEffect(() => {
    if (isInstallationFetchSuccess && !installation.id) {
      mutateCreateInstallation({quote: quote.id});
    }
  }, [isInstallationFetchSuccess]);


  const fetchQuote = () => {
    if (quote.id) {
      mutateFetchQuote(quote.id.toString());
    }
  };

  const goToNextPage = () => {
    setCurrentTab('statistics');
  };

  const goToPreviousPage = () => {
    setCurrentTab('material');
  };

  if (isCreateInstallationLoading || isInstallationLoading) {
    return <UILoadingIndicator/>;
  }

  const isSmallLoading = isQuoteLoading;

  return (
    <div className="w-full h-[75%] overflow-y-scroll">
      <h2 className="font-bold text-lg mb-4">
        {t('calculation.pages.montage.title')}
      </h2>
      {isSmallLoading && <UILoadingIndicatorSmall/>}
      <div className="w-full">
        {installation.id &&
            <CalculationMontageForm
              fetchQuote={fetchQuote}
            />
        }
      </div>
      <div className="w-full">
        {quote.costs?.length > 0 && (
          <CalculationMontageTable
            fetchQuote={fetchQuote}
          />
        )}
      </div>
      <UIPaginationButtons
        nextPage={goToNextPage}
        prevPage={goToPreviousPage}
      />
    </div>
  );
};

export default CalculationMontageTab;
