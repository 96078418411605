import {FC, useEffect, useMemo, useRef} from 'react';
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';

import {useRecoilValue} from 'recoil';
import {AG_GRID_LOCALE_SV} from '../../config/constants';

import {projectHistory} from '../../state/projectManagement/projectManagement';

import useMutateFetchProjectHistory from '../../hooks/projectManagement/useMutateFetchProjectHistory';

import UILoadingIndicator from '../UICommon/UILoadingIndicator';
import {useTranslation} from 'react-i18next';
import {FiX} from 'react-icons/fi';

type UIProjectHistoryModalProps = {
  projectId: number,
  onClose: Function,
}

const UIProjectHistoryModal: FC<UIProjectHistoryModalProps> = ({
  projectId,
  onClose,
}) => {
  const {t} = useTranslation();
  const history = useRecoilValue(projectHistory);
  const gridRef = useRef<AgGridReact>(null);
  const columns: any[] = [
    {
      field: 'timestamp',
      headerName: t('projectManagement.history.columns.timestamp'),
    },
    {
      field: 'user',
      headerName: t('projectManagement.history.columns.user'),
    },
    {
      field: 'column',
      headerName: t('projectManagement.history.columns.column'),
    },
    {
      field: 'old_value',
      headerName: t('projectManagement.history.columns.old_value'),
    },
    {
      field: 'new_value',
      headerName: t('projectManagement.history.columns.new_value'),
    },
  ];

  const {
    mutate,
    isLoading,
  } = useMutateFetchProjectHistory();

  const defaultColumnDefinition = useMemo( ()=> ({
    sortable: true,
  }), []);


  const gridOptions = {
    localeText: AG_GRID_LOCALE_SV,
    pagination: true,
    loadingOverlayComponent: UILoadingIndicator,
  };

  useEffect(() => {
    mutate(projectId);
  }, []);

  if (isLoading) {
    return <UILoadingIndicator/>;
  }

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto
     flex z-30 items-center justify-center h-full w-full'>
      <div className='p-8 shadow-medium-card relative'>
        <div
          className='w-[1000px] h-[80vh]'
        >
          <div
            className='flex justify-between py-2 px-2 bg-white items-center'
          >
            <div
              className='text-2xl font-semibold'
            >
              {t('projectManagement.history.title')}
            </div>
            <FiX
              onClick={() => onClose()}
              className='text-2xl text-red-500 z-50 opacity-70 hover:opacity-100'
            />
          </div>
          <div
            className='w-full h-full'
          >
            <AgGridReact
              ref={gridRef}
              gridOptions={gridOptions}
              columnDefs={columns}
              defaultColDef={defaultColumnDefinition}
              rowSelection='multiple'
              animateRows={true}
              rowData={history}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIProjectHistoryModal;

