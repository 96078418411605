import QuoteType from '../types/QuoteType';


const getQuoteProducts = (quote: QuoteType) => {
  // all products except mounts
  const products = quote?.products?.filter((product: any) => product.product_type !== 'mount');

  if (!products) {
    return [];
  }

  const productsSelected = products.map((el: any) => {
    if (el.product_type === 'inverter') {
      return {
        id: el.id,
        quote: quote.id,
        product_type: el.product_type,
        content_object: el.content_object,
        object_id: el.object_id,
        name: el?.content_object?.name,
        quantity: el.quantity,
        price: el?.content_object?.price?.price,
      };
    } else {
      return {
        id: el.id,
        quote: quote.id,
        product_type: el.product_type,
        content_object: el.content_object,
        object_id: el.object_id,
        name: el?.content_object?.name,
        quantity: el.quantity,
        price: el?.content_object?.price?.price,
      };
    }
  }) as [];

  return productsSelected;
};

export default getQuoteProducts;
