import {useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {HiDotsHorizontal} from 'react-icons/hi';
import Tippy from '@tippyjs/react';
import useMutateArchiveProject from '../../hooks/projectManagement/useArchiveProject';
import {currentProjectHistory} from '../../state/projectManagement/projectManagement';
import {useSetRecoilState} from 'recoil';


const ProjectContextMenu = (props: any) => {
  const {t} = useTranslation();
  const tippyRef = useRef(null);
  const [open, setOpen] = useState(false);
  const setHistoryId = useSetRecoilState(currentProjectHistory);
  const navigate = useNavigate();
  const {
    mutate: mutateArchiveProject,
  } = useMutateArchiveProject();

  const dropDownContent = (
    <div
      className="bg-white py-3 rounded border flex flex-col shadow-lg
       justify-left absolute w-32"
    >
      <div
        onClick={() => onClickHandler('calculation')}
        className="opacity-60 py-1 hover:bg-gray-200 w-full px-4 cursor-pointer"
      >
        {t('projectManagement.contextMenu.options.goToCalculation')}
      </div>
      <div
        className="opacity-60 py-1 hover:bg-gray-200 w-full px-4 cursor-pointer"
        onClick={ () => onClickHandler('history')}
      >
        {t('projectManagement.contextMenu.options.viewHistory')}
      </div>
      <div
        className="opacity-60 py-1 hover:bg-gray-200 w-full px-4 cursor-pointer"
        onClick={() => onClickHandler('archive')}
      >
        {t('projectManagement.contextMenu.options.archive')}
      </div>
    </div>
  );
  const onClickHandler = (option: string) => {
    setOpen(false);

    const quoteId = props.data.quote;
    const projectId = props.data.id;

    if (option === 'calculation') {
      navigate('/kalkyl/' + quoteId);
    }
    if (option === 'history') {
      setHistoryId(projectId);
    }
    if (option === 'archive') {
      props.api.applyTransaction({remove: [props.data]});
      mutateArchiveProject(props.data.id);
    }
  };
  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={open}
      onClickOutside={() => setOpen(false)}
      allowHTML={true}
      arrow={false}
      appendTo={document.getElementById('fullscreen-wrapper')!}
      interactive={true}
      placement="right"
    >
      <button
        className="bg-white rounded-lg text-xs px-1 opacity-70
         hover:opacity-100 border shadow justify-center bg-gray-100"
        onClick={() => setOpen(!open)}
      >
        <HiDotsHorizontal/>
      </button>
    </Tippy>
  );
};

export default ProjectContextMenu;

