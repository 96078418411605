import ProjectManagementType from '../types/ProejctManagementType';
import client from './client';

const BASE_URL = 'project-management/';

const fetchProjects = async () => {
  return await client.get(BASE_URL);
};

const fetchSingleProject = async (id: number) => {
  return await client.get(`${BASE_URL}${id}/`);
};

const updateProject = async (id: number, data: ProjectManagementType) => {
  return await client.put(`${BASE_URL}${id}/`, data);
};

const createProject = async (data: any) => {
  return await client.post(BASE_URL, data);
};

const deleteProject = async (id: number) => {
  return await client.delete(`${BASE_URL}${id}/`);
};

const fetchColumnDefinitions = async () => {
  return await client.get(`${BASE_URL}column-definitions/`);
};

const fetchProjectHistory = async (projectId: number) => {
  return await client.get(`${BASE_URL}history/${projectId}/`);
};

const archiveProject = async (projectId: number) => {
  return await client.post(`${BASE_URL}archive/${projectId}/`);
};

const fetchNewColumnData = async (datetime: string) => {
  return await client.get(`${BASE_URL}history/compare/${datetime}/`);
};

const actions = {
  fetchProjects,
  fetchSingleProject,
  fetchColumnDefinitions,
  updateProject,
  createProject,
  deleteProject,
  fetchProjectHistory,
  archiveProject,
  fetchNewColumnData,
};

export default actions;
