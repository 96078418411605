import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Charts
 */

const URL = 'charts/';

const calculateCharts = async (id: number) => {
  return client.post(URL + id + '/');
};

const fetchCharts = async (id: number) => {
  return client.get(URL + id + '/');
};

const actions = {
  calculateCharts,
  fetchCharts,
};

export default actions;
