import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {useRecoilState, useRecoilValue} from 'recoil';
import {isFloatInput} from '../../../utils/isFloatInput';
import isEligableForSupportService from '../../../utils/isEligableForSupportService';
import {statisticsQuoteInputs} from '../../../config/statisticsForm';
import useAddMonthlyConsumption from '../../../hooks/quotes/useAddMonthlyConsumption';
import useCalculateQuoteCharts from '../../../hooks/quotes/useCalculateQuoteCharts';
import useUpdateMonthlyConsumption from '../../../hooks/quotes/useUpdateMonthlyConsumption';
import useUpdateQuote from '../../../hooks/quotes/useUpdateQuote';
import {
  calculationMonthlyConsumptionFormState,
  calculationQuoteState,
} from '../../../state/calculation/calculation';
import UICard from '../../UICommon/UICard';
import UICheckbox from '../../UICommon/UICheckbox';
import UILargeButton from '../../UICommon/UILargeButton';
import UILoadingIndicatorSmall from '../../UICommon/UILoadingIndicatorSmall';
import CalculationStatisticsConsumptionForm from './CalculationStatisticsConsumptionForm';
import CalculationStatisticsFormInput from './CalculationStatisticsFormInput';
import {singleCustomer} from '../../../state/customers/customers';

type CalculationStatisticsFormProps = {
  setCharts: Function;
  setChartsLoading: Function;
  isChartsLoading: boolean;
}

const CalculationStatisticsForm: FC<CalculationStatisticsFormProps> = ({
  setCharts,
  setChartsLoading,
  isChartsLoading,
}) => {
  const {t} = useTranslation();
  const [quote, setQuote] = useRecoilState(calculationQuoteState);
  const customer = useRecoilValue(singleCustomer);
  const formValues = useRecoilValue(calculationMonthlyConsumptionFormState);
  const {isLoading: isQuoteLoading, isSuccess: isQuoteSuccess, mutate: mutateQuote} = useUpdateQuote(quote);
  const {mutate: mutateCalculateCharts, isSuccess: chartsSuccess} = useCalculateQuoteCharts();
  const {mutate: mutateAddMonthlyConsumption} = useAddMonthlyConsumption();
  const {mutate: mutateUpdateMonthlyConsumption} = useUpdateMonthlyConsumption();
  const [toggled, setToggled] = useState(true);
  const supportServiceEnabled = isEligableForSupportService(quote, customer);

  const onChange = (event: any) => {
    const {name, value, type} = event.target;

    if (type === 'checkbox') {
      const tmpQuote: any = {...quote};
      tmpQuote[name] = !tmpQuote[name];
      setQuote(tmpQuote);
    } else if (isFloatInput(value)) {
      setQuote({...quote, [name]: value});
    }
  };

  useEffect(() => {
    if (isQuoteSuccess) {
      if (quote.use_monthly_consumption) {
        formValues.id ? mutateUpdateMonthlyConsumption() : mutateAddMonthlyConsumption();
      }
      mutateCalculateCharts();
    }
  }, [isQuoteSuccess]);

  useEffect(() => {
    if (chartsSuccess) {
      setCharts(true);
      setChartsLoading(false);
    }
  }, [chartsSuccess]);

  const calculate = () => {
    setCharts(false);
    setChartsLoading(true);
    mutateQuote();
  };

  return (
    <UICard style='w-full h-full mb-6'>
      <div className="w-full flex justify-between items-center select-none p-4"
        onClick={() => setToggled(!toggled)}>
        <h2 className={`font-semibold text-sm text-[#1E1E1E] ${toggled && 'mb-4'}`}>
          {t('calculation.pages.statistics.form.title')}
        </h2>
        {toggled ? <FiChevronDown className='h-6 w-6 ml-2'/> : <FiChevronUp className='h-6 w-6 ml-2'/>}
      </div>
      <div className={`w-full px-4 ${!toggled && 'hidden'}`}>
        {statisticsQuoteInputs.map((el: any) => {
          const key = el.name as keyof typeof quote;
          if (key !== 'yearly_consumption_kw' || (key == 'yearly_consumption_kw' && !quote.use_monthly_consumption)) {
            return (
              <div className='w-full' key={el.id}>
                <CalculationStatisticsFormInput
                  name={el.name}
                  label={t(`calculation.pages.statistics.form.${el.name}`)}
                  placeholder={el.placeholder}
                  value={quote[key]}
                  helper={el.helper}
                  onChange={onChange}
                />
              </div>
            );
          }
        })}
        <div
          className='mb-5'
          title={!supportServiceEnabled ? t('calculation.pages.statistics.cantUseSupportService')! : ''}
        >
          <UICheckbox
            label={t('calculation.pages.statistics.form.use_support_service')}
            name='use_support_service'
            onChange={onChange}
            checked={quote.use_support_service}
            disabled={!supportServiceEnabled}
          />
        </div>
        <div className='mb-5'>
          <UICheckbox
            label={t('calculation.pages.statistics.form.use_monthly_consumption')}
            name='use_monthly_consumption'
            onChange={onChange}
            checked={quote.use_monthly_consumption}
          />
        </div>
        {quote.use_monthly_consumption && (
          <CalculationStatisticsConsumptionForm/>
        )}
        <div
          className='flex pb-4'
        >
          <UILargeButton
            label={t('calculation.pages.statistics.calculateButton')}
            width='w-64'
            onClick={calculate}
            disabled={isChartsLoading}
          />
        </div>
        {isQuoteLoading && <UILoadingIndicatorSmall />}
      </div>
    </UICard>
  );
};

export default CalculationStatisticsForm;
