import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {isValidEmail} from '../../../utils/isValidEmail';
import {calculationContactTabs} from '../../../config/constants';
import {customerAddressInputs, customerBaseInputs, customerCompanyInputs} from '../../../config/customerForm';

import {
  calculationQuoteState,
  currentCalculationTab,
  currentContactTab,
} from '../../../state/calculation/calculation';
import {
  quotesTemplatesSortState,
} from '../../../state/quotes/quotesTemplates';
import {singleCustomer} from '../../../state/customers/customers';

import CustomerContactForm from './CustomerForm/CustomerContactForm';
import CustomerLocationForm from './CustomerForm/CustomerLocationForm';
import CustomerQuoteForm from './CustomerForm/CustomerQuoteForm';
import CustomerAttachmentsForm from './CustomerForm/CustomerAttachmentsForm';
import CustomerPropertyForm from './CustomerForm/CustomerPropertyForm';
import CustomerCreateForm from './CustomerForm/CustomerCreateForm';

type CalculationCustomerFormProps = {
  variant: string;
};

const CalculationCustomerForm: FC<CalculationCustomerFormProps> = ({
  variant,
}) => {
  const {t} = useTranslation();
  const [quoteValues, setQuoteValues] = useRecoilState(calculationQuoteState);
  const [customer, setCustomer] = useRecoilState(singleCustomer);
  const [formErrors, setFormErrors] = useState({});

  const isExisting = quoteValues.id !== null;

  const [currentTab, setCurrentTab] = useRecoilState(currentContactTab);
  const setCurrentPage = useSetRecoilState(currentCalculationTab);
  const resetSorting = useSetRecoilState(quotesTemplatesSortState);

  useEffect(() => {
    resetSorting({
      field: '',
      type: '',
    });
  }, []);

  const isAddressField = (name: string) => {
    return customerAddressInputs.find((el) => el.name == name);
  };

  const onCustomerFormChange = (name: string, value: any) => {
    setCustomer({...customer, [name]: value});
  };

  const onQuoteFormChange = (name: string, value: string) => {
    setQuoteValues({...quoteValues, [name]: value});
  };

  const getErrorMessage = (inputName: string) => {
    const name = inputName as keyof typeof customer;
    const value = customer[name] as string;

    if (isAddressField(name)) {
      return t('generic.form.useAllFields');
    }

    if (!value) {
      return t('generic.form.requiredField');
    }

    if (inputName === 'email') {
      if (!isValidEmail(value)) {
        return t('generic.form.email.invalid');
      } else if (value !== customer.email_repeat && !isExisting) {
        return t('generic.form.email.different');
      }
    }

    return null;
  };

  const getFormErrors = () => {
    const errors: any = {};

    // contact
    for (const contactInput of customerBaseInputs) {
      if (contactInput.name === 'email_repeat' && isExisting) {
        continue;
      }
      if (contactInput.required) {
        const error = getErrorMessage(contactInput.name);
        if (error) {
          errors[contactInput.name] = error;
        }
      }
    };

    // address
    if (customer.address) {
      const usedAddressFields = Object.values(customer.address).filter((el) => {
        return el.length > 0;
      }).length;
      if (usedAddressFields > 0 && usedAddressFields < customerAddressInputs.length) {
        for (const addressInput of customerAddressInputs) {
          errors[addressInput.name] = getErrorMessage(addressInput.name);
        }
      } else if (usedAddressFields === 0) {
        setCustomer({...customer, address: undefined});
      }
    }

    // company
    if (customer.type !== 'private person') {
      for (const companyInput of customerCompanyInputs) {
        if (companyInput.required) {
          const error = getErrorMessage(companyInput.name);
          if (error) {
            errors[companyInput.name] = error;
          }
        }
      };
    }

    return errors;
  };

  const validateForm = () => {
    const errors = getFormErrors();
    const valid = Object.keys(errors).length == 0;
    setFormErrors(errors);
    return valid;
  };

  const validate = (tab: string) => {
    if (tab === 'contact' || tab === 'location') {
      if (!validateForm()) {
        return false;
      }
    }

    return true;
  };

  function goToTab(forward: boolean = true) {
    const currentTabElement = calculationContactTabs.find((el) => el.accessor === currentTab);

    if (forward) {
      if (!validate(currentTabElement?.accessor || '')) {
        return;
      }
    }

    const nextIndex = Math.max(0, (currentTabElement?.id || 0) + (forward ? 0 : -2));

    if (nextIndex == calculationContactTabs.length) {
      setCurrentPage('sections');
    } else {
      const nextTab = calculationContactTabs[nextIndex];
      setCurrentTab(nextTab.accessor);
    }
  }

  return (
    <div className='w-full h-full flex flex-col'>
      {variant === 'contact' ? (
          <CustomerContactForm
            formValues={customer}
            onChange={onCustomerFormChange}
            formErrors={formErrors}
            nextTab={goToTab}
            isExisting={isExisting}
          />
      ) : variant === 'location' ? (
          <CustomerLocationForm
            customer={customer}
            onChange={onCustomerFormChange}
            nextTab={goToTab}
            formErrors={formErrors}
          />
      ) : variant === 'property' ? (
        <CustomerPropertyForm
          formValues={customer}
          formErrors={formErrors}
          onChange={onCustomerFormChange}
          nextTab={goToTab}
        />
      ) : variant === 'documents' ? (
          <CustomerAttachmentsForm
            nextTab={goToTab}
          />
      ) : variant === 'customerOverview' ? (
          <CustomerCreateForm
            customer={customer}
            nextTab={goToTab}
            validate={validateForm}
            isInvalidForm={Object.keys(formErrors).length > 0}
          />
      ) : (
        <CustomerQuoteForm
          onChange={onQuoteFormChange}
        />
      )}
    </div>
  );
};

export default CalculationCustomerForm;
