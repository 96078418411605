import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Inverters
 */

const URL = 'inverters/';

const fetchInverters = async (quoteId: string) => {
  return client.get(`${URL}${quoteId}/`);
};

const actions = {
  fetchInverters,
};

export default actions;
