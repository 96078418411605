import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/roofTypes';
import {roofTypesArray} from '../../state/products/roofTypes';

const useFetchRoofTypes = () => {
  const setRoofTypes = useSetRecoilState(roofTypesArray);
  return useQuery('fetchRoofTypes', async () => {
    const roofTypes = await actions.fetchRoofTypes();
    setRoofTypes(roofTypes?.data);
    return roofTypes;
  }, {refetchOnWindowFocus: false});
};

export default useFetchRoofTypes;
