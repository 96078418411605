import {FC} from 'react';

type UIButtonProps = {
  label: string;
  onClick: Function;
  disabled?: boolean;
};

const UIButton: FC<UIButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  return (
    <button
      className={
        `bg-blue-primary text-white active:bg-blue-800 text-sm text-center
          font-bold py-3 px-6 rounded shadow hover:shadow-lg outline-none
          focus:outline-none ease-linear transition-all duration-150 whitespace-nowrap
          ${disabled ? 'opacity-50' : ''}`
      }
      type='button'
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default UIButton;
