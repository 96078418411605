import {FC} from 'react';

type UIInputProps = {
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  onChange: Function;
  formErrorMessage?: string;
  value?: any;
  variant?: string;
  step?: string;
  min?: number;
  max?: number;
  required?: boolean;
  suffix?: string;
  disabled?: boolean;
  padding?: string;
};

const UIInput: FC<UIInputProps> = ({
  type = 'text',
  label,
  name,
  placeholder,
  onChange,
  value,
  formErrorMessage,
  variant,
  step = '1',
  min,
  max,
  required,
  suffix,
  disabled,
  padding='p-3',
}) => {
  return (
    <div className='relative w-full'>
      {label && <label
        className={`block text-xs font-bold mb-2 ` +
          `${variant === 'white' ? 'text-slate-600' : 'uppercase text-[#696969]'}`}
        htmlFor={name}
      >
        {label}
        {required && !value &&
         <span
           className='text-red-500 ml-1'
         >
          *
         </span>
        }
      </label>}
      <div className='flex items-center'>
        <input
          type={type}
          name={name}
          step={step}
          min={min}
          max={max}
          className={
            `${suffix ? 'rounded-l-lg' : 'rounded-lg'} 
           ${variant === 'white' ? 'text-slate-600 border-0 bg-white placeholder-slate-300 rounded' :
          `text-[#6B6B6B] border border-[#F0F1F7] bg-[#FCFDFE] placeholder-slate-300 text-sm`}
           ${padding} focus:outline-none focus:ring w-full ease-linear transition-all duration-150`
          }
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          disabled={disabled}
        />
        {suffix &&
            <div
              className={`h-full px-2 ${padding} w-12 bg-[#E8E9F0] flex items-center
           justify-center text-sm border border-[#F0F1F7] rounded-r-lg font-semibold`}
            >
              {suffix}
            </div>}
      </div>
      {formErrorMessage && (
        <small className='text-red-600 font-semibold text-xs'>
          {formErrorMessage}
        </small>
      )}
    </div>
  );
};

export default UIInput;
