import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/quotes';
import {calculationQuoteState} from '../../state/calculation/calculation';

const useCreateQuote = () => {
  const setQuote = useSetRecoilState(calculationQuoteState);
  return useMutation(async (data: any) => {
    const createdQuote = await actions.addQuote(data);
    setQuote(createdQuote);
    return createdQuote;
  }, {retry: false});
};

export default useCreateQuote;
