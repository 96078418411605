import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import SelectQuoteModal from '../components/ProjectManagement/SelectQuoteModal';
import ProjectTable from '../components/ProjectManagement/ProjectTable';


const ProjectManagementPage = () => {
  const {t} = useTranslation();
  const [openModal, setOpen] = useState(false);

  const create = (quotId: number) => {
    setOpen(false);
  };

  return (
    <div
      className='flex flex-col w-full h-full'
    >
      <div
        className='w-full flex justify-between'
      >
        <div
          className='text-2xl font-semibold p-4'
        >
          {t('projectManagement.title')}
        </div>
      </div>
      {openModal &&
      <SelectQuoteModal
        onClose={() => setOpen(false)}
        onSubmit={(quoteId: number) => create(quoteId)}
      />
      }
      <div
        className='flex-1 bg-white shadow-md rounded relative overflow-scroll'
      >
        <ProjectTable/>
      </div>
    </div>
  );
};

export default ProjectManagementPage;
