import React, {FC} from 'react';
import UICheckbox from '../UICommon/UICheckbox';
import UIInput from '../UICommon/UIInput';
import UISubmit from '../UICommon/UISubmit';
import {Link} from 'react-router-dom';

type LoginPageFormProps = {
  onChange: Function;
  onKeyPress: Function;
  formErrorMessages: {
    email: string;
    password: string;
  };
  submit: Function;
};

const LoginPageForm: FC<LoginPageFormProps> = ({
  onChange,
  onKeyPress,
  formErrorMessages,
  submit,
}) => {
  return (
    <form onKeyPress={(e) => onKeyPress(e)}>
      <UIInput
        type="email"
        label="Email"
        name="email"
        placeholder="Email"
        onChange={onChange}
        formErrorMessage={formErrorMessages.email}
        variant='white'
      />
      <UIInput
        type="password"
        label="Lösenord"
        name="password"
        placeholder="Lösenord"
        onChange={onChange}
        formErrorMessage={formErrorMessages.password}
        variant='white'
      />
      <div className='flex justify-between mb-10'>
        <UICheckbox
          label="Kom ihåg mig"
          name="rememberMe"
          onChange={onChange}
        />
        <Link to="/forgot-password" className="text-sm text-blue-500 hover:underline">
              Glömt lösenord?
        </Link>
      </div>
      <UISubmit
        label="Logga in"
        onClick={submit}
      />
    </form>
  );
};

export default LoginPageForm;
