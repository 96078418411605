import {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {homeQuotesTemplatesColumns} from '../../../../config/constants';
import {quoteBaseInputs} from '../../../../config/quoteForm';

import useCreateQuoteFromTemplate from '../../../../hooks/quotes/useCreateQuoteFromTemplate';
import useCreateQuote from '../../../../hooks/quotes/useCreateQuote';
import useUpdateQuote from '../../../../hooks/quotes/useUpdateQuote';

import {
  calculationQuoteState,
  currentContactTab,
} from '../../../../state/calculation/calculation';
import {
  quotesTemplatesSetSort,
  quotesTemplatesSorted,
} from '../../../../state/quotes/quotesTemplates';

import UIInput from '../../../UICommon/UIInput';
import UIDropdownSelect from '../../../UICommon/UIDropdownSelect';
import UIDropdownSelectItem from '../../../UICommon/UIDropdownSelectItem';
import UILargeButton from '../../../UICommon/UILargeButton';
import UITable from '../../../UITable/UITable';
import UILoadingIndicator from '../../../UICommon/UILoadingIndicator';
import UIErrorIndicator from '../../../UICommon/UIErrorIndicator';
import {singleCustomer} from '../../../../state/customers/customers';

type CustomerQuoteFormProps = {
    onChange: Function;
};

const CustomerQuoteForm: FC<CustomerQuoteFormProps> = (
    {
      onChange,
    }) => {
  const {t} = useTranslation();
  const quote = useRecoilValue(calculationQuoteState);
  const [quoteErrors, setQuoteErrors] = useState({name: ''});
  const templates: any = useRecoilValue(quotesTemplatesSorted);
  const [quotesTemplatesSorting, setQuotesTemplatesSorting] = useRecoilState(quotesTemplatesSetSort);
  const setCurrentTab = useSetRecoilState(currentContactTab);
  const navigate = useNavigate();

  const {
    isLoading: isCreateFromTemplateLoading,
    isError: isCreateFromTemplateError,
    isSuccess: isCreateFromTemplateSuccess,
    mutate: mutateCreateFromTemplate,
  } = useCreateQuoteFromTemplate();
  const {
    isLoading: isQuoteCreateLoading,
    isError: isQuoteCreateError,
    isSuccess: isQuoteCreateSuccess,
    mutate: mutateCreateQuote,
  } = useCreateQuote();
  const {
    isLoading: isQuoteUpdateLoading,
    isError: isQuoteUpdateError,
    isSuccess: isQuoteUpdateSuccess,
    mutate: mutateUpdateQuote,
  } = useUpdateQuote(quote);
  const customer = useRecoilValue(singleCustomer);

  const createFromTemplate = (id: string) => {
    mutateCreateFromTemplate({
      customer: customer.id,
      template: id,
      name: quote.name,
    });
  };


  const validateQuoteInputs = () => {
    const missingError = t('generic.form.requiredField');
    const errors = {
      name: '',
    };
    if (!quote.name) {
      errors.name = missingError;
    }
    setQuoteErrors(errors);
    return errors.name.length === 0;
  };

  const createOrUpdateQuote = () => {
    if (!validateQuoteInputs()) {
      return;
    }

    if (quote.id) {
      mutateUpdateQuote();
    } else {
      mutateCreateQuote({...quote, customer: customer.id});
    }
  };

  useEffect(() => {
    if (isQuoteCreateSuccess || isCreateFromTemplateSuccess) {
      navigate(`/kalkyl/${quote.id}`);
    }
  }, [isQuoteCreateSuccess, isCreateFromTemplateSuccess]);

  useEffect(() => {
    if (isQuoteUpdateSuccess) {
      setCurrentTab('documents');
    }
  }, [isQuoteUpdateSuccess]);

  if (
    isQuoteCreateLoading ||
        isQuoteUpdateLoading ||
        isCreateFromTemplateLoading
  ) {
    return <UILoadingIndicator />;
  }

  if (
    isQuoteCreateError ||
        isQuoteUpdateError ||
        isCreateFromTemplateError
  ) {
    return <UIErrorIndicator />;
  }

  return (
    <>
      <div className='w-full flex flex-col h-full'>
        <div className='w-full mb-6'>
          {quoteBaseInputs.map((el: any) => {
            if (el.type === 'text') {
              return (
                <div key={el.id} className={`w-${el.width} px-2 mb-4`}>
                  <UIInput
                    type='text'
                    label={t(`calculation.pages.contact.tabs.quote.form.${el.name}`)!}
                    name={el.name}
                    placeholder={el.placeholder ? el.placeholder : ''}
                    value={quote.name && quote.name}
                    formErrorMessage={quoteErrors.name && quoteErrors.name}
                    onChange={(event: any) => onChange(event.target.name, event.target.value)}
                  />
                </div>
              );
            } else if (el.type === 'select') {
              return (
                <div key={el.id} className={`w-${el.width} px-2`}>
                  <UIDropdownSelect
                    label={t(`calculation.pages.contact.tabs.quote.form.environmentSelect.title`)}
                    value={
                      (quote.environment ?
                      t(`calculation.pages.contact.tabs.quote.form.environmentSelect.options.${quote.environment}`) :
                      t(`calculation.pages.contact.tabs.quote.form.environmentSelect.title`))!
                    }
                  >
                    {el.items.map((item: any) => (
                      <UIDropdownSelectItem
                        key={`${item.label}-${item.value}`}
                        label={t(`calculation.pages.contact.tabs.quote.form.environmentSelect.options.${item.value}`)!}
                        value={item.value}
                        onClick={() => onChange(el.name, item.value)}
                      />
                    ))}
                  </UIDropdownSelect>
                </div>
              );
            }
          })}
        </div>
        {!quote.id && templates.length > 0 && <div className='w-full mb-6'>
          <h2 className='w-full text-lg font-semibold mb-4'>
            {t('calculation.pages.contact.tabs.quote.buttons.createFromTemplate')}
          </h2>
          <div className='w-full mt-6 pb-8'>
            <UITable
              data={templates}
              columns={homeQuotesTemplatesColumns}
              sorting={quotesTemplatesSorting}
              onClick={createFromTemplate}
              setSorting={setQuotesTemplatesSorting}
            />
          </div>
        </div>
        }
        <div className='w-full flex justify-start ml-2 pb-6'>
          <UILargeButton
            label={
              quote.id ?
              t('calculation.pages.contact.tabs.quote.buttons.save') :
              t('calculation.pages.contact.tabs.quote.buttons.create')
            }
            onClick={createOrUpdateQuote}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerQuoteForm;

