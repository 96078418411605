import {useRecoilState, useRecoilValue} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/sections';
import {calculationQuoteState, calculationSectionState} from '../../state/calculation/calculation';

const useCreateSection = () => {
  const [sections, setSections] = useRecoilState(calculationSectionState);
  const quoteValues = useRecoilValue(calculationQuoteState);
  const data = {
    quote: quoteValues.id,
    width_m: 10,
    height_m: 5,
    roof_foot: 3.5,
    angle: 35,
    rotation: 0,
    roof_thickness_mm: 5,
    panel_orientation: 'vertical',
    scene_orientation: 'horizontal',
    margin_panels_mm: 10,
    margin_up_mm: 200,
    margin_down_mm: 200,
    margin_left_mm: 200,
    margin_right_mm: 200,
  };

  return useMutation(async () => {
    const createdSection = await actions.addNewSection(data);
    const tmpSections = [...sections];
    tmpSections.push(createdSection);
    setSections(tmpSections);
  }, {retry: false});
};

export default useCreateSection;
