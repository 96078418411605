import {FC} from 'react';

type CalculationProductBoxProps = {
  label: string;
  accessor: string;
  handleModal: Function;
  icon: JSX.Element;
};

const CalculationProductBox: FC<CalculationProductBoxProps> = ({
  label,
  accessor,
  handleModal,
  icon,
}) => {
  return (
    <div className='px-4 cursor-pointer' onClick={() => handleModal(accessor)}>
      <div className='relative flex flex-col min-w-0 break-words bg-slate-200 border-0
      border-transparent border-solid transition duration-100 hover:shadow rounded-xl bg-clip-border'>
        <div className='p-4 mx-6 mb-0 flex justify-center border-b-0 border-b-solid
        rounded-t-2xl border-b-transparent text-ce'>
          <div className='w-10 h-10 flex items-center justify-center bg-center shadow-sm icon bg-gradient-to-tl
          from-blue-500 to-violet-500 rounded-xl'>
            {icon}
          </div>
        </div>
        <div className='flex-auto p-4 pt-0 text-center'>
          <h6 className='mb-0 text-center font-semibold'>{label}</h6>
        </div>
      </div>
    </div>
  );
};

export default CalculationProductBox;
