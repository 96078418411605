import CustomerTypeFieldType from '../types/CustomerTypeFieldType';

export const quoteBaseInputs = [
  {
    id: 1,
    type: 'text',
    label: 'Rubrik (Endast synlig för projektör)',
    name: 'name',
    placeholder: 'Namn',
  },
  {
    id: 2,
    type: 'select',
    label: 'Omgivande miljö',
    name: 'environment',
    items: [
      {
        label: 'Kust',
        value: 'coast',
      },
      {
        label: 'Plan yta',
        value: 'flatland',
      },
      {
        label: 'Skog',
        value: 'forest',
      },
      {
        label: 'By',
        value: 'town',
      },
      {
        label: 'Stad',
        value: 'city',
      },
    ],
  },
];

export const quoteStatusSelect: CustomerTypeFieldType[] = [
  {
    id: 1,
    label: 'Pågående',
    value: 'ongoing',
  },
  {
    id: 2,
    label: 'Skickad',
    value: 'sent',
  },
  {
    id: 3,
    label: 'Avslutad',
    value: 'completed',
  },
  {
    id: 4,
    label: 'Accepterad',
    value: 'accepted',
  },
];
