import {FC} from 'react';
import {FaTimes} from 'react-icons/fa';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';

type OffertPdfModalProps = {
  task: any;
  closeModal: Function;
  isProposal: boolean;
  sendAsEmail: boolean;
}

const QuotePDFModal: FC<OffertPdfModalProps> = ({task, closeModal, isProposal, sendAsEmail}) => {
  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-30 items-center justify-center h-full max-h-screen w-full'>
      <div className='py-5 shadow rounded-2xl bg-white relative w-1/2 px-8 max-h-[80%] overflow-y-scroll'>
        <FaTimes className='w-5 h-5 absolute top-5 right-5 cursor-pointer' onClick={() => closeModal(false)}/>
        {task.isLoading ?
          <div>
            <div className='text-lg font-semibold mb-2 px-4 text-center'>
              {isProposal ? 'Affärsförslag' : 'Offert'} genereras
            </div>
            <div className='font-semibold my-4 text-center'>
              <UILoadingIndicator isAbsolute={false}/>
            Genererar PDF...
            </div>
          </div> : task.isSuccess ?
          <div
            className='text-center'
          >
            {sendAsEmail ?
              <div
                className='text-2xl font-bold text-blue-primary'
              >
                {`${isProposal ? 'Affärsförslaget' : 'Offerten'} har skickats.`}
              </div> :
               <a
                 className={
                   `bg-blue-primary text-white active:bg-blue-800 text-sm font-bold rounded ` +
                `shadow hover:shadow-lg text-center py-3 px-16 outline-none focus:outline-none ` +
                `ease-linear transition-all duration-150 w-auto mr-4`}
                 target="_blank"
                 href={task.data.url}
                 rel='noreferrer'
               >
              Ladda ner PDF
               </a>
            }
          </div> :
          <div className='text-2xl text-red-500 text-center'>
            Det uppstod ett fel vid genereringen.
          </div>
        }
      </div>
    </div>
  );
};

export default QuotePDFModal;
