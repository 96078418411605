import {useMutation} from 'react-query';
import actions from '../../api/projectManagement';

const useMutateArchiveProject = () => {
  return useMutation('useMutateArchiveProject', async (projectId: number) => {
    actions.archiveProject(projectId);
  });
};

export default useMutateArchiveProject;
