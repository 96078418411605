import QuoteType from '../types/QuoteType';

const getQuoteProductsFiltered = (quote: QuoteType) => {
  const products = quote.products;

  const productsFiltered: any = [
    {
      name: 'Växelriktare',
      data: products?.filter(
          (el: any) => el.product_type === 'inverter',
      ),
    },
    {
      name: 'Batterier',
      data: products?.filter(
          (el: any) => el.product_type === 'battery',
      ),
    },
    {
      name: 'Laddboxar',
      data: products?.filter(
          (el: any) => el.product_type === 'chargingbox',
      ),
    },
    {
      name: 'Övrigt',
      data: products?.filter(
          (el: any) => el.product_type === 'genericproduct',
      ),
    },
    {
      name: 'Infästning',
      data: products?.filter(
          (el: any) => el.product_type === 'mount',
      ),
    },
    {
      name: 'Solpaneler',
      data: quote.solar_panels,
    },
  ];
  return productsFiltered;
};

export default getQuoteProductsFiltered;
