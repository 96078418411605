import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Tasks
 */

const BASE_URL = 'task';


const generatePDF = async (quoteId: number, params: any) => {
  const res = await client.post(`${BASE_URL}/generate-pdf/${quoteId}/`, params=params);
  return res.data;
};

const checkStatus = async (id: string) => {
  const res = await client.get(`${BASE_URL}/status/${id}/`);
  return res.data;
};

const actions = {
  checkStatus,
  generatePDF,
};

export default actions;
