import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {authService} from './api/authService';
import {currentUser} from './state/user/currentUser';

import useFetchCurrentUser from './hooks/auth/useFetchCurrentUser';

import CalculationPage from './pages/CalculationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SetNewPasswordPage from './pages/SetNewPasswordPage';
import CustomersPage from './pages/CustomersPage';
import SingleCustomerPage from './pages/SingleCustomerPage';
import QuoteTemplatesPage from './pages/QuoteTemplatesPage';
import ProjectManagementPage from './pages/ProjectManagementPage';
import UIImage from './components/UICommon/UIImage';
import AppLayoutMenu from './components/AppLayout/AppLayoutMenu';
import UILoadingIndicator from './components/UICommon/UILoadingIndicator';

/* Theme variables */
import './theme/index.css';

import imgTop from './assets/login-bg-top.svg';
import imgBottom from './assets/login-bg-bottom.svg';
import hasRole from './utils/hasRole';


const App: React.FC = () => {
  const user = useRecoilValue(currentUser);
  const {
    refetch: refetchUser,
    isLoading: isUserLoading,
  } = useFetchCurrentUser();
  const [tokenState, setTokenState] = useState(authService.tokenValue);

  useEffect(() => {
    authService.setAccessTokenFromLocalStorage();
    authService.setRefreshTokenFromLocalStorage();
    authService.currentToken.subscribe(setTokenState);
    if (tokenState === '' || tokenState === null) {
      authService.logout();
    } else {
      refetchUser();
    }
  }, []);

  const isLoggedIn = () => {
    return tokenState !== '' && tokenState != null;
  };

  if (isLoggedIn() && !user.id) {
    refetchUser();
  }

  const isProjectManager = () => {
    return hasRole(['project_manager'], user);
  };

  const hasProjectAccess = () => {
    return hasRole(['admin', 'project_manager'], user);
  };

  if (isUserLoading) {
    return <UILoadingIndicator/>;
  }

  return (
    <Router>
      {isLoggedIn() ? (
        <div
          className='w-screen h-screen flex overflow-hidde'
        >
          <AppLayoutMenu
          />
          <div
            className="bg-slate-100 w-full h-full p-8"
          >
            <Routes>
              {isProjectManager() ?
                <Route path="*" element={<Navigate to="/projektledning"/>}/> :
                <Route path="*" element={<Navigate to="/hem"/>}/>
              }
              <Route path="/hem" element={<HomePage/>}/>
              {hasProjectAccess() &&
               <Route path="/projektledning" element={<ProjectManagementPage/>}/>
              }
              <Route path="/kunder">
                <Route path="" element={<CustomersPage/>}/>
                <Route path=":id" element={<SingleCustomerPage/>}/>
              </Route>
              <Route path="/kalkyl">
                <Route path="" element={<CalculationPage/>}/>
                <Route path=":id" element={<CalculationPage/>}/>
                <Route path="mallar">
                  <Route path="" element={<QuoteTemplatesPage/>}/>
                </Route>
              </Route>
            </Routes>
          </div>
        </div>
      ) : (
        <main>
          <section className="relative w-full h-full py-40 min-h-screen flex items-center">
            <div className="absolute top-0 w-full h-full bg-[#35384D] bg-no-repeat"></div>
            <UIImage url={imgTop} style='w-auto object-center h-auto absolute top-0 left-0'/>
            <UIImage url={imgBottom} style='w-auto object-center h-auto absolute bottom-0 right-0'/>
            <Routes>
              <Route path='*' element={<Navigate replace to="/" />} />
              <Route path='/' element={<LoginPage/>} />
              <Route path='/forgot-password' element={<ForgotPasswordPage/>} />
              <Route path='/reset-password'>
                <Route path=':token' element={<SetNewPasswordPage/>} />
              </Route>
            </Routes>
          </section>
        </main>
      )}
    </Router>
  );
};

export default App;
