import {useMutation} from 'react-query';
import actions from '../../api/quotes';

const useCreateAttachment = () => {
  return useMutation('createAttachment', async (data) => {
    const res = actions.addAttachment(data);
    return res;
  });
};

export default useCreateAttachment;
