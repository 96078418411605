import {useMutation} from 'react-query';
import actions from '../../api/chargingBoxes';
import {useSetRecoilState} from 'recoil';
import {chargingBoxesArray} from '../../state/products/chargingBoxes';

const useFetchChargingBoxes = () => {
  const setChargingBoxes = useSetRecoilState(chargingBoxesArray);
  return useMutation('fetchChargingBoxes', async () => {
    const res = await actions.fetchChargingBoxes();
    setChargingBoxes(res.data);
    return res.data;
  });
};

export default useFetchChargingBoxes;
