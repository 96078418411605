import {atom, selector} from 'recoil';
import {customersSorted} from './customers';

export const customersPageState = atom({
  key: 'customersPageState',
  default: 1,
});

export const customersRowsPerPage = atom({
  key: 'customersRowsPerPage',
  default: 10,
});

export const customersPagesLength = selector({
  key: 'customersPagesLength',
  get: ({get}) => {
    const customers = get(customersSorted);
    const rowsPerPage = get(customersRowsPerPage);
    if (customers == null) {
      return 0;
    }

    return Math.ceil(customers.length / rowsPerPage);
  },
});
