import {FC} from 'react';
import {useTranslation} from 'react-i18next';

type UIDeleteButtonProps = {
  label?: string;
  onClick: Function;
  disabled?: boolean;
};

const UIDeleteButton: FC<UIDeleteButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  const {t} = useTranslation();
  if (!label) {
    label = t('generic.buttons.delete')!;
  }
  return (
    <button
      className={
        `bg-red-500 text-white active:bg-blue-800 text-sm text-center
          font-bold py-3 px-6 rounded shadow hover:shadow-lg outline-none
          focus:outline-none ease-linear transition-all duration-150 whitespace-nowrap
          ${disabled ? 'opacity-50' : ''}`
      }
      type='button'
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default UIDeleteButton;
