import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import UIButton from '../UICommon/UIButton';
import UIInput from '../UICommon/UIInput';
import UIFileInput from '../UICommon/UIFileInput';
import {useRecoilValue} from 'recoil';
import {calculationQuoteState} from '../../state/calculation/calculation';

type AddAttachmentModalProps = {
  onClose: Function,
  onSubmit: Function,
};

const AddAttachmentModal: FC<AddAttachmentModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation();
  const [title, setTitle] = useState('');
  const [file, setFile] = useState();
  const quote = useRecoilValue(calculationQuoteState);

  const submit = () => {
    const data = {
      'title': title,
      'file': file,
      'quote': quote.id,
    };
    onSubmit(data);
    onClose();
  };

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-50 items-center justify-center h-full w-full'>
      <div className='flex bg-white w-fit rounded-2xl h-1/3 max-h-1/3
      p-10 items-center flex-col justify-between'>
        <div className='text-xl font-semibold'>
          {t('calculation.pages.contact.tabs.documents.modal.title')}
        </div>
        <div className='flex flex-col justify-between space-y-4'>
          <UIInput
            type='text'
            label={t('calculation.pages.contact.tabs.documents.modal.form.name')!}
            name='fileName'
            onChange={(e: any) => setTitle(e.target.value)}
          />
          <UIFileInput
            label={t('calculation.pages.contact.tabs.documents.modal.form.file')}
            name='name!'
            onChange={(e: any) => setFile(e.target.files[0])}
          />
        </div>
        <div className='flex w-full justify-between space-x-2'>
          <UIButton
            label={t('calculation.pages.contact.tabs.documents.modal.form.buttons.cancel')}
            onClick={onClose}
          />
          <UIButton
            label={t('calculation.pages.contact.tabs.documents.modal.form.buttons.submit')}
            onClick={submit}
            disabled={!title || !file}
          />
        </div>
      </div>
    </div>
  );
};

export default AddAttachmentModal;
