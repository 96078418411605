export const mapCustomerType = (name: string) => {
  if (name === 'private person') {
    return 'Privatperson';
  }
  if (name === 'company') {
    return 'Företag';
  }
  if (name === 'housing cooperative') {
    return 'Bostadsrättsförening';
  }
};
