import {useMutation} from 'react-query';
import actions from '../../api/projectManagement';


const useMutateFetchNewColumnData = () => {
  return useMutation('useMutateFetchNewColumnData', async (datetime: string) => {
    const res = await actions.fetchNewColumnData(datetime);
    return res.data;
  });
};

export default useMutateFetchNewColumnData;
