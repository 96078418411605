import {FC} from 'react';

type UISubmitProps = {
  label: string;
  onClick: Function;
};

const UISubmit: FC<UISubmitProps> = ({
  label,
  onClick,
}) => {
  return (
    <div className='text-center mt-6'>
      <button
        className={
          `bg-slate-800 text-white active:bg-slate-600 text-sm ` +
            `font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none ` +
            `focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`
        }
        type='button'
        onClick={(e) => onClick(e)}
      >
        {label}
      </button>
    </div>
  );
};

export default UISubmit;
