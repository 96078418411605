import {FC} from 'react';
import {ImWarning} from 'react-icons/im';

type UIWarningIconProps = {
  text: string;
  isError?: boolean;
}

const UIWarningIcon: FC<UIWarningIconProps> = ({
  text,
  isError,
}) => {
  return (
    <div className='h-full text-center flex justify-center'>
      <ImWarning className={`${isError ? 'text-red-400' : 'text-yellow-300'} text-lg mt-3 absolute`}/>
      <div className='opacity-0 hover:opacity-100 w-full z-10 text-sm px-3'>
        <div className={`rounded-lg p-1 absolute
            -bottom-12 left-0 text-center text-xs font-semibold w-full
            ${isError ? 'bg-red-500' : 'bg-yellow-400'}`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default UIWarningIcon;

