import {useSetRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import actions from '../../api/solarPanels';
import {solarPanelsArray} from '../../state/products/solarPanels';

const useFetchSolarPanels = () => {
  const setSolarPanels = useSetRecoilState(solarPanelsArray);
  return useQuery('fetchSolarPanels', async () => {
    const solarPanels = await actions.fetchSolarPanels();
    setSolarPanels(solarPanels?.data);
    return solarPanels;
  }, {
    enabled: false,
  });
};

export default useFetchSolarPanels;
