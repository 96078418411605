import {FC, useEffect} from 'react';
import {FaTimes} from 'react-icons/fa';
import {useNavigate} from 'react-router';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {homeQuotesTemplatesColumns} from '../../config/constants';
import useCreateQuoteFromTemplate from '../../hooks/quotes/useCreateQuoteFromTemplate';
import useFetchQuotes from '../../hooks/quotes/useFetchQuotes';
import {calculationQuoteState} from '../../state/calculation/calculation';
import {singleCustomer} from '../../state/customers/customers';
import {
  quotesTemplatesSetSort,
  quotesTemplatesSorted,
  quotesTemplatesSortState,
} from '../../state/quotes/quotesTemplates';
import UIErrorIndicator from '../UICommon/UIErrorIndicator';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';
import UITable from '../UITable/UITable';

type QuoteTemplateModalProps = {
  closeModal: Function;
};

const QuoteTemplateModal: FC<QuoteTemplateModalProps> = ({
  closeModal,
}) => {
  const templates: any = useRecoilValue(quotesTemplatesSorted);
  const quote = useRecoilValue(calculationQuoteState);
  const singleCustomerData: any = useRecoilValue(singleCustomer);

  const {isLoading, isError} = useFetchQuotes();
  const [quotesTemplatesSorting, setQuotesTemplatesSorting] = useRecoilState(quotesTemplatesSetSort);

  const resetSorting = useSetRecoilState(quotesTemplatesSortState);

  const {
    isLoading: isCreateFromTemplateLoading,
    isError: isCreateFromTemplateError,
    isSuccess: isCreateFromTemplateSuccess,
    mutate: mutateCreateFromTemplate,
  } = useCreateQuoteFromTemplate();

  const navigate = useNavigate();

  const onCreate = (id: string) => {
    mutateCreateFromTemplate({
      customer: singleCustomerData.id,
      template: id,
    });
  };

  useEffect(() => {
    if (isCreateFromTemplateSuccess) {
      navigate(`/kalkyl/${quote.id}`);
    }
  }, [isCreateFromTemplateSuccess]);

  useEffect(() => {
    resetSorting({
      field: '',
      type: '',
    });
  }, []);

  if (isLoading || isCreateFromTemplateLoading) {
    return <UILoadingIndicator />;
  }

  if (isError || isCreateFromTemplateError) {
    return <UIErrorIndicator />;
  }

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-30 items-center justify-center h-full max-h-screen w-full"
    >
      <div className="py-5 shadow rounded-2xl bg-white relative w-2/3 px-8 max-h-[80%] overflow-y-scroll">
        <FaTimes
          className="w-5 h-5 absolute top-5 right-5 cursor-pointer"
          onClick={() => closeModal(false)}
        />
        <div className="w-full mt-4 pb-8">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid sticky top-0 bg-white z-10">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h2 className="mb-3 font-semibold text-xl">Mallar</h2>
                <p className="text-sm">Här hittar du dina mallar.</p>
              </div>
            </div>
          </div>
          <div className="w-full mt-6 pb-8">
            <UITable
              data={templates}
              columns={homeQuotesTemplatesColumns}
              sorting={quotesTemplatesSorting}
              onClick={onCreate}
              setSorting={setQuotesTemplatesSorting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteTemplateModal;
