import {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import useFetchColors from '../../../hooks/products/useFetchColors';
import useFetchMountProducers from '../../../hooks/products/useFetchMountsProducers';
import useFetchRoofTypes from '../../../hooks/products/useFetchRoofTypes';
import useCreateSection from '../../../hooks/sections/useCreateSection';
import useFetchQuoteSections from '../../../hooks/quotes/useFetchQuoteSections';

import {
  calculationQuoteState,
  calculationSectionState,
  currentCalculationTab,
} from '../../../state/calculation/calculation';

import UICard from '../../UICommon/UICard';
import UIErrorIndicator from '../../UICommon/UIErrorIndicator';
import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import CalculationSingleSection from './CalculationSingleSection';
import UIPaginationButtons from '../../UICommon/UIPaginationButtons';
import UIAddButton from '../../UICommon/UIAddButton';

const CalculationSectionsTab: FC = () => {
  const {t} = useTranslation();

  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const [sections, setSections] = useRecoilState(calculationSectionState);
  const quote = useRecoilValue(calculationQuoteState);

  const {
    isLoading: isMountProducersLoading,
    isError: isMountProducerError,
  } = useFetchMountProducers();

  const {
    isLoading: isRoofTypesLoading,
    isError: isRoofTypesError,
  } = useFetchRoofTypes();

  const {
    isLoading: isColorsLoading,
    isError: isColorsError,
  } = useFetchColors();

  const {
    isLoading: isSectionAddLoading,
    isError: isSectionAddError,
    mutate: mutateAddSection,
  } = useCreateSection();

  const {
    isLoading: isSectionsLoading,
    isError: isSectionsError,
    mutate: mutateFetchSections,
  } = useFetchQuoteSections();

  useEffect(() => {
    if (quote.id) {
      mutateFetchSections(quote.id);
    }
  }, []);

  const goToNextPage = () => {
    setCurrentTab('products');
  };

  const goToPreviousPage = () => {
    setCurrentTab('contact');
  };

  if (
    isSectionsLoading ||
    isMountProducersLoading ||
    isRoofTypesLoading ||
    isColorsLoading ||
    isSectionAddLoading
  ) {
    return <UILoadingIndicator />;
  }

  if (
    isSectionsError ||
    isMountProducerError ||
    isRoofTypesError ||
    isColorsError ||
    isSectionAddError
  ) {
    return <UIErrorIndicator />;
  }

  return (
    <div className='w-full overflow-y-scroll'>
      <h2 className='font-bold text-lg mb-4'>
        {t('calculation.pages.sections.title')}
      </h2>
      {sections && sections.map((el: any, index: number) => (
        <UICard key={`section-${index}`} style='relative h-auto mb-6 shadow'>
          <div className='h-full'>
            <CalculationSingleSection
              section={el}
              index={index}
              sections={sections}
              setSections={setSections}
            />
          </div>
        </UICard>
      ))
      }
      <div className='w-full overflow-y-hidden'>
        <UIAddButton
          onClick={() => mutateAddSection()}
          label={t('calculation.pages.sections.addButton')}
        />
      </div>
      <UIPaginationButtons
        nextPage={goToNextPage}
        prevPage={goToPreviousPage}
      />
    </div>
  );
};

export default CalculationSectionsTab;
