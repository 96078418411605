import {useSetRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/customers';
import {singleCustomer} from '../../state/customers/customers';

const useFetchSingleCustomer = () => {
  const setSingleCustomer = useSetRecoilState(singleCustomer);

  return useMutation(async (id: string) => {
    const res = await actions.fetchSingleCustomer(id);

    setSingleCustomer(res?.data);

    return res.data;
  });
};

export default useFetchSingleCustomer;
