import {useMutation} from 'react-query';
import actions from '../../api/projectManagement';

const useMutateUpdateProject = () => {
  return useMutation('useMutateUpdateProject', async (data: any) => {
    const res = await actions.updateProject(data.id, data.data);
    return res.data;
  });
};

export default useMutateUpdateProject;
