import {useMutation} from 'react-query';
import actions from '../../api/quotes';
import {useSetRecoilState} from 'recoil';
import {calculationQuoteState} from '../../state/calculation/calculation';


const useMutateFetchQuote = () => {
  const setQuote = useSetRecoilState(calculationQuoteState);
  return useMutation('mutateFetchQuote', async (id: string) => {
    const res = await actions.fetchSingleQuote(parseInt(id));
    setQuote(res.data);
    return res.data;
  });
};

export default useMutateFetchQuote;
