import {useRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/sections';
import {calculationQuoteState, calculationSectionState} from '../../state/calculation/calculation';
import QuoteType from '../../types/QuoteType';

const useUpdateSection = (data: any, index: number, id: number) => {
  const [quote, setQuote] = useRecoilState<QuoteType>(calculationQuoteState);
  const [sections, setSections] = useRecoilState(calculationSectionState);
  return useMutation(async () => {
    const res = await actions.updateSection(data, id);

    // No changes made.
    if (res.status == 208) {
      return;
    }

    const tmpQuote = {...quote};
    tmpQuote.is_materials_updated = false;
    tmpQuote.is_installation_updated = false;
    tmpQuote.is_statistics_updated = false;
    setQuote(tmpQuote);

    const updatedSection = res.data;
    const tmpSections = [...sections];
    const tmpSection = {...sections[index]};
    tmpSection.power_kW = updatedSection.power_kW;
    tmpSection.section_items = updatedSection.section_items;
    tmpSection.solar_panel_amount = updatedSection.solar_panel_amount;
    tmpSections[index] = tmpSection;
    setSections(tmpSections);
  }, {retry: false});
};

export default useUpdateSection;
