import {useRecoilState} from 'recoil';
import {useMutation} from 'react-query';
import actions from '../../api/tasks';
import {currentTask} from '../../state/calculation/calculation';

const useCheckTaskStatus = () => {
  const [task, setTask] = useRecoilState(currentTask);
  return useMutation(async () => {
    const data = await actions.checkStatus(task.id!);
    setTask(data);
    return data;
  }, {
    retry: true,
    retryDelay: 500,
  });
};

export default useCheckTaskStatus;
