import client from './client';

/**
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

/**
 * @typedef {Object} Products
 */

const URL = 'quote_products/';

const addNewProduct = async (data: any) => {
  const res = await client.post(URL, data);
  return res.data;
};

const updateProduct = async (id: number, data: any) => {
  const res = await client.put(URL + id + '/', data);
  return res.data;
};

const deleteProduct = async (id: number) => {
  const res = await client.delete(URL + id + '/');
  return res.data;
};

const actions = {
  addNewProduct,
  updateProduct,
  deleteProduct,
};

export default actions;
